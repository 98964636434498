@charset "UTF-8";
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  font-size: 100%;
  vertical-align: baseline; }

html {
  line-height: 1; }

ol, ul {
  list-style: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

caption, th, td {
  text-align: left;
  font-weight: normal;
  vertical-align: middle; }

q, blockquote {
  quotes: none; }
  q:before, q:after, blockquote:before, blockquote:after {
    content: "";
    content: none; }

a img {
  border: none; }

article, aside, details, figcaption, figure, footer, header, hgroup, main, menu, nav, section, summary {
  display: block; }

@keyframes hero_in {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0; }
  10% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: scale(1.05);
    -moz-transform: scale(1.05);
    -ms-transform: scale(1.05);
    -o-transform: scale(1.05);
    transform: scale(1.05); }
  20% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1; }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes hero_out {
  0% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  50% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); }
  100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
    opacity: 0;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1); } }

@keyframes hover {
  0%, 100% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=30);
    opacity: 0.3; }
  50% {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
    opacity: 0.8; } }

@-webkit-keyframes $key {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@-moz-keyframes $key {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@-ms-keyframes $key {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@-o-keyframes $key {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

@keyframes $key {
  0% {
    width: 0%; }
  100% {
    width: 100%; } }

:root {
  --base: .875rem;
  --column: calc(var(--max_screen_inner) / 12);
  --row: calc(var(--max_screen_inner) / 6);
  --gap: calc(var(--base) * 2);
  --screen_inset: var(--space_me);
  --max_screen_inner: calc(105rem + 5vw);
  --max_content_inner: calc(75rem + 5vw);
  --fs: var(--base);
  --fw_l: 300;
  --fw_r: 400;
  --fw_m: 600;
  --fw_b: 800;
  --lh: 1.8;
  --ff: "Montserrat", "Noto Sans TC", sans-serif;
  --clr_off: transparent;
  --clr_white: white;
  --clr_white_opacity: rgba(255, 255, 255, 0.3);
  --clr_gray_lighten: #dfdfdf;
  --clr_gray: #9c9c9c;
  --clr_gray_darken: #505050;
  --clr_black: #1d1d1d;
  --clr_black_opacity: rgba(20, 20, 20, 0.5);
  --clr_pri: #19F822;
  --clr_pri_darken: #19F822;
  --clr_sec: #EA1A78;
  --clr_sec_darken: #A40850;
  --clr_ter: #FFA500;
  --clr_ter_darken: #E98A1D;
  --clr_facebook: #1778f2;
  --clr_google: #db4437;
  --trt_timing: cubic-bezier(.5, 0, 0, 1);
  --space_0: 0;
  --space_auto: auto;
  --space_half: 50%;
  --space_quarter: 25%;
  --space_ss: calc(var(--base) * .35);
  --space_sm: calc(var(--base) * .7);
  --space_base: var(--base);
  --space_me: calc(var(--base) * 1.5);
  --space_la: calc(var(--base) * 2);
  --space_2la: calc(var(--base) * 3);
  --space_xl: calc(var(--base) * 4);
  --space_2xl: calc(var(--base) * 6);
  --space_ma: calc(var(--base) * 12);
  --space_2ma: calc(var(--base) * 16);
  --space_ss_indent: calc(var(--base) * -.1);
  --space_sm_indent: calc(var(--base) * -.5);
  --space_base_indent: calc(var(--base) * -1);
  --space_me_indent: calc(var(--base) * -1.5);
  --space_la_indent: calc(var(--base) * -2);
  --space_2la_indent: calc(var(--base) * -3);
  --space_xl_indent: calc(var(--base) * -4);
  --space_2xl_indent: calc(var(--base) * -6);
  --space_ma_indent: calc(var(--base) * -8);
  --logo_light: url("../src/images/logo_light.svg");
  --logo_dark: url("../src/images/logo_dark.svg"); }

@media screen and (orientation: portrait) {
  :root {
    --orientation_display: none;
    --orientation_base: 7vw; } }

@media screen and (orientation: landscape) {
  :root {
    --orientation_display: inline-block;
    --orientation_base: 3.9vh; } }

@media screen and (orientation: landscape) and (min-width: 1680px) {
  :root {
    --orientation_base: 3.9vh;
    --orientation_la: 10vw; } }

@media all and (min-width: 960px) {
  :root {
    --screen_inset: var(--space_xl); } }

@media all and (min-width: 1680px) {
  :root {
    --base: calc(1rem + .01vw);
    --screen_inset: var(--space_2xl); } }

@media all and (min-width: 1920px) {
  :root {
    --base: calc(1rem + .02vw); } }

body {
  font-family: var(--ff);
  font-weight: var(--fw_r);
  -webkit-text-size-adjust: none;
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  color: var(--clr_black);
  background-color: #5ccbec;
  background: linear-gradient(0deg, #c5e7ed 0%, #56d9ff 100%);
  background-attachment: fixed; }
  body::after {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: url("../src/images/noise.png");
    opacity: 1;
    animation: noise_move .1s linear infinite forwards; }
  @media all and (min-width: 768px) {
    body::after {
      animation: noise_move .5s linear infinite forwards; } }

@keyframes noise_move {
  0% {
    background-position-y: 0%; }
  100% {
    background-position-y: -200%; } }

::selection {
  background-color: var(--clr_white);
  color: var(--clr_black); }

*::-webkit-media-controls-panel {
  display: none !important;
  -webkit-appearance: none; }

*::--webkit-media-controls-play-button {
  display: none !important;
  -webkit-appearance: none; }

*::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none; }

headings() {
  margin: 0;
  padding: 0;
  border: 0;
  display: inline-block;
  word-wrap: break-word;
  font-weight: var(--f_w_b); }

a {
  display: inline-block;
  cursor: pointer;
  line-height: 1;
  text-decoration: none;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  a:hover {
    text-decoration: none; }
  a > * {
    -webkit-transition: 0.2s;
    -moz-transition: 0.2s;
    -o-transition: 0.2s;
    transition: 0.2s; }
    a > *:hover {
      opacity: .6; }
      a > *:hover[class*=overflow_] {
        opacity: 1; }
  a[href]:hover .thumbnail {
    opacity: 1;
    transform: scale(1.05); }
  a img {
    width: 100%;
    line-height: 0; }

.decoration_slice {
  box-decoration-break: slice; }

.decoration_clone {
  box-decoration-break: clone; }

.box_border {
  box-sizing: border-box; }

.box_content {
  box-sizing: content-box; }

.hidden {
  display: none; }

.hidden_s {
  display: none !important; }

.block {
  display: block; }

.inline_block {
  display: inline-block; }

.inline {
  display: inline; }

.flex {
  display: flex; }

.inline_flex {
  display: inline-flex; }

.grid {
  display: grid; }

.inline_grid {
  display: inline-grid; }

.table {
  display: table; }

@media all and (min-width: 640px) {
  .s\:hidden {
    display: none; }
  .s\:hidden_s {
    display: none !important; }
  .s\:block {
    display: block; }
  .s\:block_s {
    display: block !important; }
  .s\:inline_block {
    display: inline-block; }
  .s\:inline {
    display: inline; }
  .s\:flex {
    display: flex; }
  .s\:inline_flex {
    display: inline-flex; }
  .s\:grid {
    display: grid; }
  .s\:inline_grid {
    display: inline-grid; }
  .s\:table {
    display: table; } }

@media all and (min-width: 768px) {
  .ms\:hidden {
    display: none !important; }
  .ms\:block {
    display: block; }
  .ms\:inline_block {
    display: inline-block; }
  .ms\:inline {
    display: inline; }
  .ms\:flex {
    display: flex; }
  .ms\:inline_flex {
    display: inline-flex; }
  .ms\:grid {
    display: grid; }
  .ms\:inline_grid {
    display: inline-grid; }
  .ms\:table {
    display: table; } }

@media all and (min-width: 960px) {
  .m\:hidden {
    display: none; }
  .m\:hidden_s {
    display: none !important; }
  .m\:block {
    display: block !important; }
  .m\:inline_block {
    display: inline-block; }
  .m\:inline {
    display: inline; }
  .m\:flex {
    display: flex; }
  .m\:inline_flex {
    display: inline-flex; }
  .m\:grid {
    display: grid; }
  .m\:inline_grid {
    display: inline-grid; }
  .m\:table {
    display: table; } }

.float_right {
  float: right; }

.float_left {
  float: left; }

.float_none {
  float: none; }

.overflow_auto {
  overflow: auto; }

.overflow_hidden {
  overflow: hidden; }

.overflow_visible {
  overflow: visible; }

.overflow_scroll {
  overflow: scroll; }

.overflow_x_auto {
  overflow-x: auto; }

.overflow_y_auto {
  overflow-y: auto; }

.overflow_x_hidden {
  overflow-x: hidden; }

.overflow_y_hidden {
  overflow-y: hidden; }

.overflow_x_visible {
  overflow-x: visible; }

.overflow_y_visible {
  overflow-y: visible; }

.overflow_x_scroll {
  overflow-x: scroll; }

.overflow_y_scroll {
  overflow-y: scroll; }

@media all and (min-width: 768px) {
  .ms\:overflow_auto {
    overflow: auto; }
  .ms\:overflow_hidden {
    overflow: hidden; }
  .ms\:overflow_visible {
    overflow: visible; }
  .ms\:overflow_scroll {
    overflow: scroll; }
  .ms\:overflow_x_auto {
    overflow-x: auto; }
  .ms\:overflow_y_auto {
    overflow-y: auto; }
  .ms\:overflow_x_hidden {
    overflow-x: hidden; }
  .ms\:overflow_y_hidden {
    overflow-y: hidden; }
  .ms\:overflow_x_visible {
    overflow-x: visible; }
  .ms\:overflow_y_visible {
    overflow-y: visible; }
  .ms\:overflow_x_scroll {
    overflow-x: scroll; }
  .ms\:overflow_y_scroll {
    overflow-y: scroll; } }

@media all and (min-width: 960px) {
  .m\:overflow_auto {
    overflow: auto; }
  .m\:overflow_hidden {
    overflow: hidden; }
  .m\:overflow_visible {
    overflow: visible; }
  .m\:overflow_scroll {
    overflow: scroll; }
  .m\:overflow_x_auto {
    overflow-x: auto; }
  .m\:overflow_y_auto {
    overflow-y: auto; }
  .m\:overflow_x_hidden {
    overflow-x: hidden; }
  .m\:overflow_y_hidden {
    overflow-y: hidden; }
  .m\:overflow_x_visible {
    overflow-x: visible; }
  .m\:overflow_y_visible {
    overflow-y: visible; }
  .m\:overflow_x_scroll {
    overflow-x: scroll; }
  .m\:overflow_y_scroll {
    overflow-y: scroll; } }

.static {
  position: static; }

.fixed {
  position: fixed; }

.absolute {
  position: absolute; }

.relative {
  position: relative; }

.sticky {
  position: sticky; }

@media all and (min-width: 768px) {
  .ms\:static {
    position: static; }
  .ms\:fixed {
    position: fixed; }
  .ms\:absolute {
    position: absolute; }
  .ms\:relative {
    position: relative; }
  .ms\:sticky {
    position: sticky; } }

@media all and (min-width: 960px) {
  .m\:static {
    position: static; }
  .m\:fixed {
    position: fixed; }
  .m\:absolute {
    position: absolute; }
  .m\:relative {
    position: relative; }
  .m\:sticky {
    position: sticky; } }

.z_0 {
  z-index: 0; }

.z_1 {
  z-index: 10; }

.z_2 {
  z-index: 20; }

.z_3 {
  z-index: 30; }

.z_4 {
  z-index: 40; }

.z_5 {
  z-index: 50; }

.z_6 {
  z-index: 60; }

.z_7 {
  z-index: 70; }

.z_8 {
  z-index: 80; }

.z_9 {
  z-index: 90; }

.z_10 {
  z-index: 100; }

.z_11 {
  z-index: 110; }

.z_12 {
  z-index: 120; }

.z_13 {
  z-index: 130; }

.z_14 {
  z-index: 140; }

.z_15 {
  z-index: 150; }

.z_16 {
  z-index: 160; }

.z_17 {
  z-index: 170; }

.z_18 {
  z-index: 180; }

.z_19 {
  z-index: 190; }

@media all and (min-width: 768px) {
  .ms\:z_0 {
    z-index: 0; }
  .ms\:z_1 {
    z-index: 10; }
  .ms\:z_2 {
    z-index: 20; }
  .ms\:z_3 {
    z-index: 30; }
  .ms\:z_4 {
    z-index: 40; }
  .ms\:z_5 {
    z-index: 50; }
  .ms\:z_6 {
    z-index: 60; }
  .ms\:z_7 {
    z-index: 70; }
  .ms\:z_8 {
    z-index: 80; }
  .ms\:z_9 {
    z-index: 90; }
  .ms\:z_10 {
    z-index: 100; }
  .ms\:z_11 {
    z-index: 110; }
  .ms\:z_12 {
    z-index: 120; }
  .ms\:z_13 {
    z-index: 130; }
  .ms\:z_14 {
    z-index: 140; }
  .ms\:z_15 {
    z-index: 150; }
  .ms\:z_16 {
    z-index: 160; }
  .ms\:z_17 {
    z-index: 170; }
  .ms\:z_18 {
    z-index: 180; }
  .ms\:z_19 {
    z-index: 190; } }

@media all and (min-width: 960px) {
  .m\:z_0 {
    z-index: 0; }
  .m\:z_1 {
    z-index: 10; }
  .m\:z_2 {
    z-index: 20; }
  .m\:z_3 {
    z-index: 30; }
  .m\:z_4 {
    z-index: 40; }
  .m\:z_5 {
    z-index: 50; }
  .m\:z_6 {
    z-index: 60; }
  .m\:z_7 {
    z-index: 70; }
  .m\:z_8 {
    z-index: 80; }
  .m\:z_9 {
    z-index: 90; }
  .m\:z_10 {
    z-index: 100; }
  .m\:z_11 {
    z-index: 110; }
  .m\:z_12 {
    z-index: 120; }
  .m\:z_13 {
    z-index: 130; }
  .m\:z_14 {
    z-index: 140; }
  .m\:z_15 {
    z-index: 150; }
  .m\:z_16 {
    z-index: 160; }
  .m\:z_17 {
    z-index: 170; }
  .m\:z_18 {
    z-index: 180; }
  .m\:z_19 {
    z-index: 190; } }

.w_ss {
  width: var(--space_ss); }

.h_ss {
  height: var(--space_ss); }

.w_sm {
  width: var(--space_sm); }

.h_sm {
  height: var(--space_sm); }

.w_base {
  width: var(--space_base); }

.h_base {
  height: var(--space_base); }

.w_me {
  width: var(--space_me); }

.h_me {
  height: var(--space_me); }

.w_la {
  width: var(--space_la); }

.h_la {
  height: var(--space_la); }

.w_2la {
  width: var(--space_2la); }

.h_2la {
  height: var(--space_2la); }

.w_xl {
  width: var(--space_xl); }

.h_xl {
  height: var(--space_xl); }

.w_2xl {
  width: var(--space_2xl); }

.h_2xl {
  height: var(--space_2xl); }

.w_ma {
  width: var(--space_ma); }

.h_ma {
  height: var(--space_ma); }

.w_2ma {
  width: var(--space_2ma); }

.h_2ma {
  height: var(--space_2ma); }

.w_ss_indent {
  width: var(--space_ss_indent); }

.h_ss_indent {
  height: var(--space_ss_indent); }

.w_sm_indent {
  width: var(--space_sm_indent); }

.h_sm_indent {
  height: var(--space_sm_indent); }

.w_base_indent {
  width: var(--space_base_indent); }

.h_base_indent {
  height: var(--space_base_indent); }

.w_me_indent {
  width: var(--space_me_indent); }

.h_me_indent {
  height: var(--space_me_indent); }

.w_la_indent {
  width: var(--space_la_indent); }

.h_la_indent {
  height: var(--space_la_indent); }

.w_2la_indent {
  width: var(--space_2la_indent); }

.h_2la_indent {
  height: var(--space_2la_indent); }

.w_xl_indent {
  width: var(--space_xl_indent); }

.h_xl_indent {
  height: var(--space_xl_indent); }

.w_2xl_indent {
  width: var(--space_2xl_indent); }

.h_2xl_indent {
  height: var(--space_2xl_indent); }

.w_ma_indent {
  width: var(--space_ma_indent); }

.h_ma_indent {
  height: var(--space_ma_indent); }

.w_0 {
  width: var(--space_0); }

.h_0 {
  height: var(--space_0); }

.w_auto {
  width: var(--space_auto); }

.h_auto {
  height: var(--space_auto); }

.w_1px {
  width: 1px; }

.h_1px {
  height: 1px; }

.w_4rem {
  width: 4rem; }

.h_4rem {
  height: 4rem; }

.w_10 {
  width: 10%; }

.h_10 {
  height: 10%; }

.vw_10 {
  width: 10vw; }

.vh_10 {
  height: 10vh; }

.max_w_10 {
  max-width: 10%; }

.max_h_10 {
  max-height: 10%; }

.max_vw_10 {
  max-width: 10vw; }

.max_vh_10 {
  max-height: 10vh; }

.w_2px {
  width: 2px; }

.h_2px {
  height: 2px; }

.w_8rem {
  width: 8rem; }

.h_8rem {
  height: 8rem; }

.w_20 {
  width: 20%; }

.h_20 {
  height: 20%; }

.vw_20 {
  width: 20vw; }

.vh_20 {
  height: 20vh; }

.max_w_20 {
  max-width: 20%; }

.max_h_20 {
  max-height: 20%; }

.max_vw_20 {
  max-width: 20vw; }

.max_vh_20 {
  max-height: 20vh; }

.w_3px {
  width: 3px; }

.h_3px {
  height: 3px; }

.w_12rem {
  width: 12rem; }

.h_12rem {
  height: 12rem; }

.w_30 {
  width: 30%; }

.h_30 {
  height: 30%; }

.vw_30 {
  width: 30vw; }

.vh_30 {
  height: 30vh; }

.max_w_30 {
  max-width: 30%; }

.max_h_30 {
  max-height: 30%; }

.max_vw_30 {
  max-width: 30vw; }

.max_vh_30 {
  max-height: 30vh; }

.w_4px {
  width: 4px; }

.h_4px {
  height: 4px; }

.w_16rem {
  width: 16rem; }

.h_16rem {
  height: 16rem; }

.w_40 {
  width: 40%; }

.h_40 {
  height: 40%; }

.vw_40 {
  width: 40vw; }

.vh_40 {
  height: 40vh; }

.max_w_40 {
  max-width: 40%; }

.max_h_40 {
  max-height: 40%; }

.max_vw_40 {
  max-width: 40vw; }

.max_vh_40 {
  max-height: 40vh; }

.w_5px {
  width: 5px; }

.h_5px {
  height: 5px; }

.w_20rem {
  width: 20rem; }

.h_20rem {
  height: 20rem; }

.w_50 {
  width: 50%; }

.h_50 {
  height: 50%; }

.vw_50 {
  width: 50vw; }

.vh_50 {
  height: 50vh; }

.max_w_50 {
  max-width: 50%; }

.max_h_50 {
  max-height: 50%; }

.max_vw_50 {
  max-width: 50vw; }

.max_vh_50 {
  max-height: 50vh; }

.w_6px {
  width: 6px; }

.h_6px {
  height: 6px; }

.w_24rem {
  width: 24rem; }

.h_24rem {
  height: 24rem; }

.w_60 {
  width: 60%; }

.h_60 {
  height: 60%; }

.vw_60 {
  width: 60vw; }

.vh_60 {
  height: 60vh; }

.max_w_60 {
  max-width: 60%; }

.max_h_60 {
  max-height: 60%; }

.max_vw_60 {
  max-width: 60vw; }

.max_vh_60 {
  max-height: 60vh; }

.w_7px {
  width: 7px; }

.h_7px {
  height: 7px; }

.w_28rem {
  width: 28rem; }

.h_28rem {
  height: 28rem; }

.w_70 {
  width: 70%; }

.h_70 {
  height: 70%; }

.vw_70 {
  width: 70vw; }

.vh_70 {
  height: 70vh; }

.max_w_70 {
  max-width: 70%; }

.max_h_70 {
  max-height: 70%; }

.max_vw_70 {
  max-width: 70vw; }

.max_vh_70 {
  max-height: 70vh; }

.w_8px {
  width: 8px; }

.h_8px {
  height: 8px; }

.w_32rem {
  width: 32rem; }

.h_32rem {
  height: 32rem; }

.w_80 {
  width: 80%; }

.h_80 {
  height: 80%; }

.vw_80 {
  width: 80vw; }

.vh_80 {
  height: 80vh; }

.max_w_80 {
  max-width: 80%; }

.max_h_80 {
  max-height: 80%; }

.max_vw_80 {
  max-width: 80vw; }

.max_vh_80 {
  max-height: 80vh; }

.w_9px {
  width: 9px; }

.h_9px {
  height: 9px; }

.w_36rem {
  width: 36rem; }

.h_36rem {
  height: 36rem; }

.w_90 {
  width: 90%; }

.h_90 {
  height: 90%; }

.vw_90 {
  width: 90vw; }

.vh_90 {
  height: 90vh; }

.max_w_90 {
  max-width: 90%; }

.max_h_90 {
  max-height: 90%; }

.max_vw_90 {
  max-width: 90vw; }

.max_vh_90 {
  max-height: 90vh; }

.w_10px {
  width: 10px; }

.h_10px {
  height: 10px; }

.w_40rem {
  width: 40rem; }

.h_40rem {
  height: 40rem; }

.w_100 {
  width: 100%; }

.h_100 {
  height: 100%; }

.vw_100 {
  width: 100vw; }

.vh_100 {
  height: 100vh; }

.max_w_100 {
  max-width: 100%; }

.max_h_100 {
  max-height: 100%; }

.max_vw_100 {
  max-width: 100vw; }

.max_vh_100 {
  max-height: 100vh; }

@media all and (min-width: 768px) {
  .ms\:w_ss {
    width: var(--space_ss); }
  .ms\:h_ss {
    height: var(--space_ss); }
  .ms\:w_sm {
    width: var(--space_sm); }
  .ms\:h_sm {
    height: var(--space_sm); }
  .ms\:w_base {
    width: var(--space_base); }
  .ms\:h_base {
    height: var(--space_base); }
  .ms\:w_me {
    width: var(--space_me); }
  .ms\:h_me {
    height: var(--space_me); }
  .ms\:w_la {
    width: var(--space_la); }
  .ms\:h_la {
    height: var(--space_la); }
  .ms\:w_2la {
    width: var(--space_2la); }
  .ms\:h_2la {
    height: var(--space_2la); }
  .ms\:w_xl {
    width: var(--space_xl); }
  .ms\:h_xl {
    height: var(--space_xl); }
  .ms\:w_2xl {
    width: var(--space_2xl); }
  .ms\:h_2xl {
    height: var(--space_2xl); }
  .ms\:w_ma {
    width: var(--space_ma); }
  .ms\:h_ma {
    height: var(--space_ma); }
  .ms\:w_2ma {
    width: var(--space_2ma); }
  .ms\:h_2ma {
    height: var(--space_2ma); }
  .ms\:w_ss_indent {
    width: var(--space_ss_indent); }
  .ms\:h_ss_indent {
    height: var(--space_ss_indent); }
  .ms\:w_sm_indent {
    width: var(--space_sm_indent); }
  .ms\:h_sm_indent {
    height: var(--space_sm_indent); }
  .ms\:w_base_indent {
    width: var(--space_base_indent); }
  .ms\:h_base_indent {
    height: var(--space_base_indent); }
  .ms\:w_me_indent {
    width: var(--space_me_indent); }
  .ms\:h_me_indent {
    height: var(--space_me_indent); }
  .ms\:w_la_indent {
    width: var(--space_la_indent); }
  .ms\:h_la_indent {
    height: var(--space_la_indent); }
  .ms\:w_2la_indent {
    width: var(--space_2la_indent); }
  .ms\:h_2la_indent {
    height: var(--space_2la_indent); }
  .ms\:w_xl_indent {
    width: var(--space_xl_indent); }
  .ms\:h_xl_indent {
    height: var(--space_xl_indent); }
  .ms\:w_2xl_indent {
    width: var(--space_2xl_indent); }
  .ms\:h_2xl_indent {
    height: var(--space_2xl_indent); }
  .ms\:w_ma_indent {
    width: var(--space_ma_indent); }
  .ms\:h_ma_indent {
    height: var(--space_ma_indent); }
  .ms\:w_0 {
    width: var(--space_0); }
  .ms\:h_0 {
    height: var(--space_0); }
  .ms\:w_auto {
    width: var(--space_auto); }
  .ms\:h_auto {
    height: var(--space_auto); }
  .ms\:max_w_0 {
    max-width: 0; }
  .ms\:max_h_0 {
    max-height: 0; }
  .ms\:w_10 {
    width: 10%; }
  .ms\:h_10 {
    height: 10%; }
  .ms\:vw_10 {
    width: 10vw; }
  .ms\:vh_10 {
    height: 10vh; }
  .ms\:max_w_10 {
    max-width: 10%; }
  .ms\:max_h_10 {
    max-height: 10%; }
  .ms\:max_vw_10 {
    max-width: 10vw; }
  .ms\:max_vh_10 {
    max-height: 10vh; }
  .ms\:w_20 {
    width: 20%; }
  .ms\:h_20 {
    height: 20%; }
  .ms\:vw_20 {
    width: 20vw; }
  .ms\:vh_20 {
    height: 20vh; }
  .ms\:max_w_20 {
    max-width: 20%; }
  .ms\:max_h_20 {
    max-height: 20%; }
  .ms\:max_vw_20 {
    max-width: 20vw; }
  .ms\:max_vh_20 {
    max-height: 20vh; }
  .ms\:w_30 {
    width: 30%; }
  .ms\:h_30 {
    height: 30%; }
  .ms\:vw_30 {
    width: 30vw; }
  .ms\:vh_30 {
    height: 30vh; }
  .ms\:max_w_30 {
    max-width: 30%; }
  .ms\:max_h_30 {
    max-height: 30%; }
  .ms\:max_vw_30 {
    max-width: 30vw; }
  .ms\:max_vh_30 {
    max-height: 30vh; }
  .ms\:w_40 {
    width: 40%; }
  .ms\:h_40 {
    height: 40%; }
  .ms\:vw_40 {
    width: 40vw; }
  .ms\:vh_40 {
    height: 40vh; }
  .ms\:max_w_40 {
    max-width: 40%; }
  .ms\:max_h_40 {
    max-height: 40%; }
  .ms\:max_vw_40 {
    max-width: 40vw; }
  .ms\:max_vh_40 {
    max-height: 40vh; }
  .ms\:w_50 {
    width: 50%; }
  .ms\:h_50 {
    height: 50%; }
  .ms\:vw_50 {
    width: 50vw; }
  .ms\:vh_50 {
    height: 50vh; }
  .ms\:max_w_50 {
    max-width: 50%; }
  .ms\:max_h_50 {
    max-height: 50%; }
  .ms\:max_vw_50 {
    max-width: 50vw; }
  .ms\:max_vh_50 {
    max-height: 50vh; }
  .ms\:w_60 {
    width: 60%; }
  .ms\:h_60 {
    height: 60%; }
  .ms\:vw_60 {
    width: 60vw; }
  .ms\:vh_60 {
    height: 60vh; }
  .ms\:max_w_60 {
    max-width: 60%; }
  .ms\:max_h_60 {
    max-height: 60%; }
  .ms\:max_vw_60 {
    max-width: 60vw; }
  .ms\:max_vh_60 {
    max-height: 60vh; }
  .ms\:w_70 {
    width: 70%; }
  .ms\:h_70 {
    height: 70%; }
  .ms\:vw_70 {
    width: 70vw; }
  .ms\:vh_70 {
    height: 70vh; }
  .ms\:max_w_70 {
    max-width: 70%; }
  .ms\:max_h_70 {
    max-height: 70%; }
  .ms\:max_vw_70 {
    max-width: 70vw; }
  .ms\:max_vh_70 {
    max-height: 70vh; }
  .ms\:w_80 {
    width: 80%; }
  .ms\:h_80 {
    height: 80%; }
  .ms\:vw_80 {
    width: 80vw; }
  .ms\:vh_80 {
    height: 80vh; }
  .ms\:max_w_80 {
    max-width: 80%; }
  .ms\:max_h_80 {
    max-height: 80%; }
  .ms\:max_vw_80 {
    max-width: 80vw; }
  .ms\:max_vh_80 {
    max-height: 80vh; }
  .ms\:w_90 {
    width: 90%; }
  .ms\:h_90 {
    height: 90%; }
  .ms\:vw_90 {
    width: 90vw; }
  .ms\:vh_90 {
    height: 90vh; }
  .ms\:max_w_90 {
    max-width: 90%; }
  .ms\:max_h_90 {
    max-height: 90%; }
  .ms\:max_vw_90 {
    max-width: 90vw; }
  .ms\:max_vh_90 {
    max-height: 90vh; }
  .ms\:w_100 {
    width: 100%; }
  .ms\:h_100 {
    height: 100%; }
  .ms\:vw_100 {
    width: 100vw; }
  .ms\:vh_100 {
    height: 100vh; }
  .ms\:max_w_100 {
    max-width: 100%; }
  .ms\:max_h_100 {
    max-height: 100%; }
  .ms\:max_vw_100 {
    max-width: 100vw; }
  .ms\:max_vh_100 {
    max-height: 100vh; } }

@media all and (min-width: 960px) {
  .m\:w_ss {
    width: var(--space_ss); }
  .m\:h_ss {
    height: var(--space_ss); }
  .m\:w_sm {
    width: var(--space_sm); }
  .m\:h_sm {
    height: var(--space_sm); }
  .m\:w_base {
    width: var(--space_base); }
  .m\:h_base {
    height: var(--space_base); }
  .m\:w_me {
    width: var(--space_me); }
  .m\:h_me {
    height: var(--space_me); }
  .m\:w_la {
    width: var(--space_la); }
  .m\:h_la {
    height: var(--space_la); }
  .m\:w_2la {
    width: var(--space_2la); }
  .m\:h_2la {
    height: var(--space_2la); }
  .m\:w_xl {
    width: var(--space_xl); }
  .m\:h_xl {
    height: var(--space_xl); }
  .m\:w_2xl {
    width: var(--space_2xl); }
  .m\:h_2xl {
    height: var(--space_2xl); }
  .m\:w_ma {
    width: var(--space_ma); }
  .m\:h_ma {
    height: var(--space_ma); }
  .m\:w_2ma {
    width: var(--space_2ma); }
  .m\:h_2ma {
    height: var(--space_2ma); }
  .m\:w_ss_indent {
    width: var(--space_ss_indent); }
  .m\:h_ss_indent {
    height: var(--space_ss_indent); }
  .m\:w_sm_indent {
    width: var(--space_sm_indent); }
  .m\:h_sm_indent {
    height: var(--space_sm_indent); }
  .m\:w_base_indent {
    width: var(--space_base_indent); }
  .m\:h_base_indent {
    height: var(--space_base_indent); }
  .m\:w_me_indent {
    width: var(--space_me_indent); }
  .m\:h_me_indent {
    height: var(--space_me_indent); }
  .m\:w_la_indent {
    width: var(--space_la_indent); }
  .m\:h_la_indent {
    height: var(--space_la_indent); }
  .m\:w_2la_indent {
    width: var(--space_2la_indent); }
  .m\:h_2la_indent {
    height: var(--space_2la_indent); }
  .m\:w_xl_indent {
    width: var(--space_xl_indent); }
  .m\:h_xl_indent {
    height: var(--space_xl_indent); }
  .m\:w_2xl_indent {
    width: var(--space_2xl_indent); }
  .m\:h_2xl_indent {
    height: var(--space_2xl_indent); }
  .m\:w_ma_indent {
    width: var(--space_ma_indent); }
  .m\:h_ma_indent {
    height: var(--space_ma_indent); }
  .m\:w_0 {
    width: var(--space_0); }
  .m\:h_0 {
    height: var(--space_0); }
  .m\:w_auto {
    width: var(--space_auto); }
  .m\:h_auto {
    height: var(--space_auto); }
  .m\:w_4rem {
    width: 4rem; }
  .m\:h_4rem {
    height: 4rem; }
  .m\:w_10 {
    width: 10%; }
  .m\:h_10 {
    height: 10%; }
  .m\:vw_10 {
    width: 10vw; }
  .m\:vh_10 {
    height: 10vh; }
  .m\:max_w_10 {
    max-width: 10%; }
  .m\:max_h_10 {
    max-height: 10%; }
  .m\:max_vw_10 {
    max-width: 10vw; }
  .m\:max_vh_10 {
    max-height: 10vh; }
  .m\:w_8rem {
    width: 8rem; }
  .m\:h_8rem {
    height: 8rem; }
  .m\:w_20 {
    width: 20%; }
  .m\:h_20 {
    height: 20%; }
  .m\:vw_20 {
    width: 20vw; }
  .m\:vh_20 {
    height: 20vh; }
  .m\:max_w_20 {
    max-width: 20%; }
  .m\:max_h_20 {
    max-height: 20%; }
  .m\:max_vw_20 {
    max-width: 20vw; }
  .m\:max_vh_20 {
    max-height: 20vh; }
  .m\:w_12rem {
    width: 12rem; }
  .m\:h_12rem {
    height: 12rem; }
  .m\:w_30 {
    width: 30%; }
  .m\:h_30 {
    height: 30%; }
  .m\:vw_30 {
    width: 30vw; }
  .m\:vh_30 {
    height: 30vh; }
  .m\:max_w_30 {
    max-width: 30%; }
  .m\:max_h_30 {
    max-height: 30%; }
  .m\:max_vw_30 {
    max-width: 30vw; }
  .m\:max_vh_30 {
    max-height: 30vh; }
  .m\:w_16rem {
    width: 16rem; }
  .m\:h_16rem {
    height: 16rem; }
  .m\:w_40 {
    width: 40%; }
  .m\:h_40 {
    height: 40%; }
  .m\:vw_40 {
    width: 40vw; }
  .m\:vh_40 {
    height: 40vh; }
  .m\:max_w_40 {
    max-width: 40%; }
  .m\:max_h_40 {
    max-height: 40%; }
  .m\:max_vw_40 {
    max-width: 40vw; }
  .m\:max_vh_40 {
    max-height: 40vh; }
  .m\:w_20rem {
    width: 20rem; }
  .m\:h_20rem {
    height: 20rem; }
  .m\:w_50 {
    width: 50%; }
  .m\:h_50 {
    height: 50%; }
  .m\:vw_50 {
    width: 50vw; }
  .m\:vh_50 {
    height: 50vh; }
  .m\:max_w_50 {
    max-width: 50%; }
  .m\:max_h_50 {
    max-height: 50%; }
  .m\:max_vw_50 {
    max-width: 50vw; }
  .m\:max_vh_50 {
    max-height: 50vh; }
  .m\:w_24rem {
    width: 24rem; }
  .m\:h_24rem {
    height: 24rem; }
  .m\:w_60 {
    width: 60%; }
  .m\:h_60 {
    height: 60%; }
  .m\:vw_60 {
    width: 60vw; }
  .m\:vh_60 {
    height: 60vh; }
  .m\:max_w_60 {
    max-width: 60%; }
  .m\:max_h_60 {
    max-height: 60%; }
  .m\:max_vw_60 {
    max-width: 60vw; }
  .m\:max_vh_60 {
    max-height: 60vh; }
  .m\:w_28rem {
    width: 28rem; }
  .m\:h_28rem {
    height: 28rem; }
  .m\:w_70 {
    width: 70%; }
  .m\:h_70 {
    height: 70%; }
  .m\:vw_70 {
    width: 70vw; }
  .m\:vh_70 {
    height: 70vh; }
  .m\:max_w_70 {
    max-width: 70%; }
  .m\:max_h_70 {
    max-height: 70%; }
  .m\:max_vw_70 {
    max-width: 70vw; }
  .m\:max_vh_70 {
    max-height: 70vh; }
  .m\:w_32rem {
    width: 32rem; }
  .m\:h_32rem {
    height: 32rem; }
  .m\:w_80 {
    width: 80%; }
  .m\:h_80 {
    height: 80%; }
  .m\:vw_80 {
    width: 80vw; }
  .m\:vh_80 {
    height: 80vh; }
  .m\:max_w_80 {
    max-width: 80%; }
  .m\:max_h_80 {
    max-height: 80%; }
  .m\:max_vw_80 {
    max-width: 80vw; }
  .m\:max_vh_80 {
    max-height: 80vh; }
  .m\:w_36rem {
    width: 36rem; }
  .m\:h_36rem {
    height: 36rem; }
  .m\:w_90 {
    width: 90%; }
  .m\:h_90 {
    height: 90%; }
  .m\:vw_90 {
    width: 90vw; }
  .m\:vh_90 {
    height: 90vh; }
  .m\:max_w_90 {
    max-width: 90%; }
  .m\:max_h_90 {
    max-height: 90%; }
  .m\:max_vw_90 {
    max-width: 90vw; }
  .m\:max_vh_90 {
    max-height: 90vh; }
  .m\:w_40rem {
    width: 40rem; }
  .m\:h_40rem {
    height: 40rem; }
  .m\:w_100 {
    width: 100%; }
  .m\:h_100 {
    height: 100%; }
  .m\:vw_100 {
    width: 100vw; }
  .m\:vh_100 {
    height: 100vh; }
  .m\:max_w_100 {
    max-width: 100%; }
  .m\:max_h_100 {
    max-height: 100%; }
  .m\:max_vw_100 {
    max-width: 100vw; }
  .m\:max_vh_100 {
    max-height: 100vh; } }

.inner_w_2 {
  width: 100%; }

.inner_w_3 {
  width: 100%; }

@media all and (min-width: 768px) {
  .inner_w_2 {
    width: calc(100% / 2); }
  .inner_w_3 {
    width: calc(100% / 2); } }

@media all and (min-width: 960px) {
  .inner_w_3 {
    width: calc(100% / 3); } }

.m_top_indent_half {
  margin-top: -50%; }

.top_initial {
  top: initial; }

.right_initial {
  right: initial; }

.bottom_initial {
  bottom: initial; }

.left_initial {
  left: initial; }

.top_10 {
  top: 10%; }

.right_10 {
  right: 10%; }

.bottom_10 {
  bottom: 10%; }

.left_10 {
  left: 10%; }

.top_20 {
  top: 20%; }

.right_20 {
  right: 20%; }

.bottom_20 {
  bottom: 20%; }

.left_20 {
  left: 20%; }

.top_30 {
  top: 30%; }

.right_30 {
  right: 30%; }

.bottom_30 {
  bottom: 30%; }

.left_30 {
  left: 30%; }

.top_40 {
  top: 40%; }

.right_40 {
  right: 40%; }

.bottom_40 {
  bottom: 40%; }

.left_40 {
  left: 40%; }

.top_50 {
  top: 50%; }

.right_50 {
  right: 50%; }

.bottom_50 {
  bottom: 50%; }

.left_50 {
  left: 50%; }

.top_60 {
  top: 60%; }

.right_60 {
  right: 60%; }

.bottom_60 {
  bottom: 60%; }

.left_60 {
  left: 60%; }

.top_70 {
  top: 70%; }

.right_70 {
  right: 70%; }

.bottom_70 {
  bottom: 70%; }

.left_70 {
  left: 70%; }

.top_80 {
  top: 80%; }

.right_80 {
  right: 80%; }

.bottom_80 {
  bottom: 80%; }

.left_80 {
  left: 80%; }

.top_90 {
  top: 90%; }

.right_90 {
  right: 90%; }

.bottom_90 {
  bottom: 90%; }

.left_90 {
  left: 90%; }

.top_100 {
  top: 100%; }

.right_100 {
  right: 100%; }

.bottom_100 {
  bottom: 100%; }

.left_100 {
  left: 100%; }

.p_ss {
  padding: var(--space_ss); }

.p_x_ss {
  padding-right: var(--space_ss);
  padding-left: var(--space_ss); }

.p_y_ss {
  padding-top: var(--space_ss);
  padding-bottom: var(--space_ss); }

.m_ss {
  margin: var(--space_ss); }

.m_x_ss {
  margin-right: var(--space_ss);
  margin-left: var(--space_ss); }

.m_y_ss {
  margin-top: var(--space_ss);
  margin-bottom: var(--space_ss); }

.g_ss {
  grid-gap: var(--space_ss); }

.g_x_ss {
  column-gap: var(--space_ss); }

.g_y_ss {
  row-gap: var(--space_ss); }

.top_ss {
  top: var(--space_ss); }

.p_top_ss {
  padding-top: var(--space_ss); }

.m_top_ss {
  margin-top: var(--space_ss); }

.right_ss {
  right: var(--space_ss); }

.p_right_ss {
  padding-right: var(--space_ss); }

.m_right_ss {
  margin-right: var(--space_ss); }

.bottom_ss {
  bottom: var(--space_ss); }

.p_bottom_ss {
  padding-bottom: var(--space_ss); }

.m_bottom_ss {
  margin-bottom: var(--space_ss); }

.left_ss {
  left: var(--space_ss); }

.p_left_ss {
  padding-left: var(--space_ss); }

.m_left_ss {
  margin-left: var(--space_ss); }

.p_sm {
  padding: var(--space_sm); }

.p_x_sm {
  padding-right: var(--space_sm);
  padding-left: var(--space_sm); }

.p_y_sm {
  padding-top: var(--space_sm);
  padding-bottom: var(--space_sm); }

.m_sm {
  margin: var(--space_sm); }

.m_x_sm {
  margin-right: var(--space_sm);
  margin-left: var(--space_sm); }

.m_y_sm {
  margin-top: var(--space_sm);
  margin-bottom: var(--space_sm); }

.g_sm {
  grid-gap: var(--space_sm); }

.g_x_sm {
  column-gap: var(--space_sm); }

.g_y_sm {
  row-gap: var(--space_sm); }

.top_sm {
  top: var(--space_sm); }

.p_top_sm {
  padding-top: var(--space_sm); }

.m_top_sm {
  margin-top: var(--space_sm); }

.right_sm {
  right: var(--space_sm); }

.p_right_sm {
  padding-right: var(--space_sm); }

.m_right_sm {
  margin-right: var(--space_sm); }

.bottom_sm {
  bottom: var(--space_sm); }

.p_bottom_sm {
  padding-bottom: var(--space_sm); }

.m_bottom_sm {
  margin-bottom: var(--space_sm); }

.left_sm {
  left: var(--space_sm); }

.p_left_sm {
  padding-left: var(--space_sm); }

.m_left_sm {
  margin-left: var(--space_sm); }

.p_base {
  padding: var(--space_base); }

.p_x_base {
  padding-right: var(--space_base);
  padding-left: var(--space_base); }

.p_y_base {
  padding-top: var(--space_base);
  padding-bottom: var(--space_base); }

.m_base {
  margin: var(--space_base); }

.m_x_base {
  margin-right: var(--space_base);
  margin-left: var(--space_base); }

.m_y_base {
  margin-top: var(--space_base);
  margin-bottom: var(--space_base); }

.g_base {
  grid-gap: var(--space_base); }

.g_x_base {
  column-gap: var(--space_base); }

.g_y_base {
  row-gap: var(--space_base); }

.top_base {
  top: var(--space_base); }

.p_top_base {
  padding-top: var(--space_base); }

.m_top_base {
  margin-top: var(--space_base); }

.right_base {
  right: var(--space_base); }

.p_right_base {
  padding-right: var(--space_base); }

.m_right_base {
  margin-right: var(--space_base); }

.bottom_base {
  bottom: var(--space_base); }

.p_bottom_base {
  padding-bottom: var(--space_base); }

.m_bottom_base {
  margin-bottom: var(--space_base); }

.left_base {
  left: var(--space_base); }

.p_left_base {
  padding-left: var(--space_base); }

.m_left_base {
  margin-left: var(--space_base); }

.p_me {
  padding: var(--space_me); }

.p_x_me {
  padding-right: var(--space_me);
  padding-left: var(--space_me); }

.p_y_me {
  padding-top: var(--space_me);
  padding-bottom: var(--space_me); }

.m_me {
  margin: var(--space_me); }

.m_x_me {
  margin-right: var(--space_me);
  margin-left: var(--space_me); }

.m_y_me {
  margin-top: var(--space_me);
  margin-bottom: var(--space_me); }

.g_me {
  grid-gap: var(--space_me); }

.g_x_me {
  column-gap: var(--space_me); }

.g_y_me {
  row-gap: var(--space_me); }

.top_me {
  top: var(--space_me); }

.p_top_me {
  padding-top: var(--space_me); }

.m_top_me {
  margin-top: var(--space_me); }

.right_me {
  right: var(--space_me); }

.p_right_me {
  padding-right: var(--space_me); }

.m_right_me {
  margin-right: var(--space_me); }

.bottom_me {
  bottom: var(--space_me); }

.p_bottom_me {
  padding-bottom: var(--space_me); }

.m_bottom_me {
  margin-bottom: var(--space_me); }

.left_me {
  left: var(--space_me); }

.p_left_me {
  padding-left: var(--space_me); }

.m_left_me {
  margin-left: var(--space_me); }

.p_la {
  padding: var(--space_la); }

.p_x_la {
  padding-right: var(--space_la);
  padding-left: var(--space_la); }

.p_y_la {
  padding-top: var(--space_la);
  padding-bottom: var(--space_la); }

.m_la {
  margin: var(--space_la); }

.m_x_la {
  margin-right: var(--space_la);
  margin-left: var(--space_la); }

.m_y_la {
  margin-top: var(--space_la);
  margin-bottom: var(--space_la); }

.g_la {
  grid-gap: var(--space_la); }

.g_x_la {
  column-gap: var(--space_la); }

.g_y_la {
  row-gap: var(--space_la); }

.top_la {
  top: var(--space_la); }

.p_top_la {
  padding-top: var(--space_la); }

.m_top_la {
  margin-top: var(--space_la); }

.right_la {
  right: var(--space_la); }

.p_right_la {
  padding-right: var(--space_la); }

.m_right_la {
  margin-right: var(--space_la); }

.bottom_la {
  bottom: var(--space_la); }

.p_bottom_la {
  padding-bottom: var(--space_la); }

.m_bottom_la {
  margin-bottom: var(--space_la); }

.left_la {
  left: var(--space_la); }

.p_left_la {
  padding-left: var(--space_la); }

.m_left_la {
  margin-left: var(--space_la); }

.p_2la {
  padding: var(--space_2la); }

.p_x_2la {
  padding-right: var(--space_2la);
  padding-left: var(--space_2la); }

.p_y_2la {
  padding-top: var(--space_2la);
  padding-bottom: var(--space_2la); }

.m_2la {
  margin: var(--space_2la); }

.m_x_2la {
  margin-right: var(--space_2la);
  margin-left: var(--space_2la); }

.m_y_2la {
  margin-top: var(--space_2la);
  margin-bottom: var(--space_2la); }

.g_2la {
  grid-gap: var(--space_2la); }

.g_x_2la {
  column-gap: var(--space_2la); }

.g_y_2la {
  row-gap: var(--space_2la); }

.top_2la {
  top: var(--space_2la); }

.p_top_2la {
  padding-top: var(--space_2la); }

.m_top_2la {
  margin-top: var(--space_2la); }

.right_2la {
  right: var(--space_2la); }

.p_right_2la {
  padding-right: var(--space_2la); }

.m_right_2la {
  margin-right: var(--space_2la); }

.bottom_2la {
  bottom: var(--space_2la); }

.p_bottom_2la {
  padding-bottom: var(--space_2la); }

.m_bottom_2la {
  margin-bottom: var(--space_2la); }

.left_2la {
  left: var(--space_2la); }

.p_left_2la {
  padding-left: var(--space_2la); }

.m_left_2la {
  margin-left: var(--space_2la); }

.p_xl {
  padding: var(--space_xl); }

.p_x_xl {
  padding-right: var(--space_xl);
  padding-left: var(--space_xl); }

.p_y_xl {
  padding-top: var(--space_xl);
  padding-bottom: var(--space_xl); }

.m_xl {
  margin: var(--space_xl); }

.m_x_xl {
  margin-right: var(--space_xl);
  margin-left: var(--space_xl); }

.m_y_xl {
  margin-top: var(--space_xl);
  margin-bottom: var(--space_xl); }

.g_xl {
  grid-gap: var(--space_xl); }

.g_x_xl {
  column-gap: var(--space_xl); }

.g_y_xl {
  row-gap: var(--space_xl); }

.top_xl {
  top: var(--space_xl); }

.p_top_xl {
  padding-top: var(--space_xl); }

.m_top_xl {
  margin-top: var(--space_xl); }

.right_xl {
  right: var(--space_xl); }

.p_right_xl {
  padding-right: var(--space_xl); }

.m_right_xl {
  margin-right: var(--space_xl); }

.bottom_xl {
  bottom: var(--space_xl); }

.p_bottom_xl {
  padding-bottom: var(--space_xl); }

.m_bottom_xl {
  margin-bottom: var(--space_xl); }

.left_xl {
  left: var(--space_xl); }

.p_left_xl {
  padding-left: var(--space_xl); }

.m_left_xl {
  margin-left: var(--space_xl); }

.p_2xl {
  padding: var(--space_2xl); }

.p_x_2xl {
  padding-right: var(--space_2xl);
  padding-left: var(--space_2xl); }

.p_y_2xl {
  padding-top: var(--space_2xl);
  padding-bottom: var(--space_2xl); }

.m_2xl {
  margin: var(--space_2xl); }

.m_x_2xl {
  margin-right: var(--space_2xl);
  margin-left: var(--space_2xl); }

.m_y_2xl {
  margin-top: var(--space_2xl);
  margin-bottom: var(--space_2xl); }

.g_2xl {
  grid-gap: var(--space_2xl); }

.g_x_2xl {
  column-gap: var(--space_2xl); }

.g_y_2xl {
  row-gap: var(--space_2xl); }

.top_2xl {
  top: var(--space_2xl); }

.p_top_2xl {
  padding-top: var(--space_2xl); }

.m_top_2xl {
  margin-top: var(--space_2xl); }

.right_2xl {
  right: var(--space_2xl); }

.p_right_2xl {
  padding-right: var(--space_2xl); }

.m_right_2xl {
  margin-right: var(--space_2xl); }

.bottom_2xl {
  bottom: var(--space_2xl); }

.p_bottom_2xl {
  padding-bottom: var(--space_2xl); }

.m_bottom_2xl {
  margin-bottom: var(--space_2xl); }

.left_2xl {
  left: var(--space_2xl); }

.p_left_2xl {
  padding-left: var(--space_2xl); }

.m_left_2xl {
  margin-left: var(--space_2xl); }

.p_ma {
  padding: var(--space_ma); }

.p_x_ma {
  padding-right: var(--space_ma);
  padding-left: var(--space_ma); }

.p_y_ma {
  padding-top: var(--space_ma);
  padding-bottom: var(--space_ma); }

.m_ma {
  margin: var(--space_ma); }

.m_x_ma {
  margin-right: var(--space_ma);
  margin-left: var(--space_ma); }

.m_y_ma {
  margin-top: var(--space_ma);
  margin-bottom: var(--space_ma); }

.g_ma {
  grid-gap: var(--space_ma); }

.g_x_ma {
  column-gap: var(--space_ma); }

.g_y_ma {
  row-gap: var(--space_ma); }

.top_ma {
  top: var(--space_ma); }

.p_top_ma {
  padding-top: var(--space_ma); }

.m_top_ma {
  margin-top: var(--space_ma); }

.right_ma {
  right: var(--space_ma); }

.p_right_ma {
  padding-right: var(--space_ma); }

.m_right_ma {
  margin-right: var(--space_ma); }

.bottom_ma {
  bottom: var(--space_ma); }

.p_bottom_ma {
  padding-bottom: var(--space_ma); }

.m_bottom_ma {
  margin-bottom: var(--space_ma); }

.left_ma {
  left: var(--space_ma); }

.p_left_ma {
  padding-left: var(--space_ma); }

.m_left_ma {
  margin-left: var(--space_ma); }

.p_2ma {
  padding: var(--space_2ma); }

.p_x_2ma {
  padding-right: var(--space_2ma);
  padding-left: var(--space_2ma); }

.p_y_2ma {
  padding-top: var(--space_2ma);
  padding-bottom: var(--space_2ma); }

.m_2ma {
  margin: var(--space_2ma); }

.m_x_2ma {
  margin-right: var(--space_2ma);
  margin-left: var(--space_2ma); }

.m_y_2ma {
  margin-top: var(--space_2ma);
  margin-bottom: var(--space_2ma); }

.g_2ma {
  grid-gap: var(--space_2ma); }

.g_x_2ma {
  column-gap: var(--space_2ma); }

.g_y_2ma {
  row-gap: var(--space_2ma); }

.top_2ma {
  top: var(--space_2ma); }

.p_top_2ma {
  padding-top: var(--space_2ma); }

.m_top_2ma {
  margin-top: var(--space_2ma); }

.right_2ma {
  right: var(--space_2ma); }

.p_right_2ma {
  padding-right: var(--space_2ma); }

.m_right_2ma {
  margin-right: var(--space_2ma); }

.bottom_2ma {
  bottom: var(--space_2ma); }

.p_bottom_2ma {
  padding-bottom: var(--space_2ma); }

.m_bottom_2ma {
  margin-bottom: var(--space_2ma); }

.left_2ma {
  left: var(--space_2ma); }

.p_left_2ma {
  padding-left: var(--space_2ma); }

.m_left_2ma {
  margin-left: var(--space_2ma); }

.p_ss_indent {
  padding: var(--space_ss_indent); }

.p_x_ss_indent {
  padding-right: var(--space_ss_indent);
  padding-left: var(--space_ss_indent); }

.p_y_ss_indent {
  padding-top: var(--space_ss_indent);
  padding-bottom: var(--space_ss_indent); }

.m_ss_indent {
  margin: var(--space_ss_indent); }

.m_x_ss_indent {
  margin-right: var(--space_ss_indent);
  margin-left: var(--space_ss_indent); }

.m_y_ss_indent {
  margin-top: var(--space_ss_indent);
  margin-bottom: var(--space_ss_indent); }

.g_ss_indent {
  grid-gap: var(--space_ss_indent); }

.g_x_ss_indent {
  column-gap: var(--space_ss_indent); }

.g_y_ss_indent {
  row-gap: var(--space_ss_indent); }

.top_ss_indent {
  top: var(--space_ss_indent); }

.p_top_ss_indent {
  padding-top: var(--space_ss_indent); }

.m_top_ss_indent {
  margin-top: var(--space_ss_indent); }

.right_ss_indent {
  right: var(--space_ss_indent); }

.p_right_ss_indent {
  padding-right: var(--space_ss_indent); }

.m_right_ss_indent {
  margin-right: var(--space_ss_indent); }

.bottom_ss_indent {
  bottom: var(--space_ss_indent); }

.p_bottom_ss_indent {
  padding-bottom: var(--space_ss_indent); }

.m_bottom_ss_indent {
  margin-bottom: var(--space_ss_indent); }

.left_ss_indent {
  left: var(--space_ss_indent); }

.p_left_ss_indent {
  padding-left: var(--space_ss_indent); }

.m_left_ss_indent {
  margin-left: var(--space_ss_indent); }

.p_sm_indent {
  padding: var(--space_sm_indent); }

.p_x_sm_indent {
  padding-right: var(--space_sm_indent);
  padding-left: var(--space_sm_indent); }

.p_y_sm_indent {
  padding-top: var(--space_sm_indent);
  padding-bottom: var(--space_sm_indent); }

.m_sm_indent {
  margin: var(--space_sm_indent); }

.m_x_sm_indent {
  margin-right: var(--space_sm_indent);
  margin-left: var(--space_sm_indent); }

.m_y_sm_indent {
  margin-top: var(--space_sm_indent);
  margin-bottom: var(--space_sm_indent); }

.g_sm_indent {
  grid-gap: var(--space_sm_indent); }

.g_x_sm_indent {
  column-gap: var(--space_sm_indent); }

.g_y_sm_indent {
  row-gap: var(--space_sm_indent); }

.top_sm_indent {
  top: var(--space_sm_indent); }

.p_top_sm_indent {
  padding-top: var(--space_sm_indent); }

.m_top_sm_indent {
  margin-top: var(--space_sm_indent); }

.right_sm_indent {
  right: var(--space_sm_indent); }

.p_right_sm_indent {
  padding-right: var(--space_sm_indent); }

.m_right_sm_indent {
  margin-right: var(--space_sm_indent); }

.bottom_sm_indent {
  bottom: var(--space_sm_indent); }

.p_bottom_sm_indent {
  padding-bottom: var(--space_sm_indent); }

.m_bottom_sm_indent {
  margin-bottom: var(--space_sm_indent); }

.left_sm_indent {
  left: var(--space_sm_indent); }

.p_left_sm_indent {
  padding-left: var(--space_sm_indent); }

.m_left_sm_indent {
  margin-left: var(--space_sm_indent); }

.p_base_indent {
  padding: var(--space_base_indent); }

.p_x_base_indent {
  padding-right: var(--space_base_indent);
  padding-left: var(--space_base_indent); }

.p_y_base_indent {
  padding-top: var(--space_base_indent);
  padding-bottom: var(--space_base_indent); }

.m_base_indent {
  margin: var(--space_base_indent); }

.m_x_base_indent {
  margin-right: var(--space_base_indent);
  margin-left: var(--space_base_indent); }

.m_y_base_indent {
  margin-top: var(--space_base_indent);
  margin-bottom: var(--space_base_indent); }

.g_base_indent {
  grid-gap: var(--space_base_indent); }

.g_x_base_indent {
  column-gap: var(--space_base_indent); }

.g_y_base_indent {
  row-gap: var(--space_base_indent); }

.top_base_indent {
  top: var(--space_base_indent); }

.p_top_base_indent {
  padding-top: var(--space_base_indent); }

.m_top_base_indent {
  margin-top: var(--space_base_indent); }

.right_base_indent {
  right: var(--space_base_indent); }

.p_right_base_indent {
  padding-right: var(--space_base_indent); }

.m_right_base_indent {
  margin-right: var(--space_base_indent); }

.bottom_base_indent {
  bottom: var(--space_base_indent); }

.p_bottom_base_indent {
  padding-bottom: var(--space_base_indent); }

.m_bottom_base_indent {
  margin-bottom: var(--space_base_indent); }

.left_base_indent {
  left: var(--space_base_indent); }

.p_left_base_indent {
  padding-left: var(--space_base_indent); }

.m_left_base_indent {
  margin-left: var(--space_base_indent); }

.p_me_indent {
  padding: var(--space_me_indent); }

.p_x_me_indent {
  padding-right: var(--space_me_indent);
  padding-left: var(--space_me_indent); }

.p_y_me_indent {
  padding-top: var(--space_me_indent);
  padding-bottom: var(--space_me_indent); }

.m_me_indent {
  margin: var(--space_me_indent); }

.m_x_me_indent {
  margin-right: var(--space_me_indent);
  margin-left: var(--space_me_indent); }

.m_y_me_indent {
  margin-top: var(--space_me_indent);
  margin-bottom: var(--space_me_indent); }

.g_me_indent {
  grid-gap: var(--space_me_indent); }

.g_x_me_indent {
  column-gap: var(--space_me_indent); }

.g_y_me_indent {
  row-gap: var(--space_me_indent); }

.top_me_indent {
  top: var(--space_me_indent); }

.p_top_me_indent {
  padding-top: var(--space_me_indent); }

.m_top_me_indent {
  margin-top: var(--space_me_indent); }

.right_me_indent {
  right: var(--space_me_indent); }

.p_right_me_indent {
  padding-right: var(--space_me_indent); }

.m_right_me_indent {
  margin-right: var(--space_me_indent); }

.bottom_me_indent {
  bottom: var(--space_me_indent); }

.p_bottom_me_indent {
  padding-bottom: var(--space_me_indent); }

.m_bottom_me_indent {
  margin-bottom: var(--space_me_indent); }

.left_me_indent {
  left: var(--space_me_indent); }

.p_left_me_indent {
  padding-left: var(--space_me_indent); }

.m_left_me_indent {
  margin-left: var(--space_me_indent); }

.p_la_indent {
  padding: var(--space_la_indent); }

.p_x_la_indent {
  padding-right: var(--space_la_indent);
  padding-left: var(--space_la_indent); }

.p_y_la_indent {
  padding-top: var(--space_la_indent);
  padding-bottom: var(--space_la_indent); }

.m_la_indent {
  margin: var(--space_la_indent); }

.m_x_la_indent {
  margin-right: var(--space_la_indent);
  margin-left: var(--space_la_indent); }

.m_y_la_indent {
  margin-top: var(--space_la_indent);
  margin-bottom: var(--space_la_indent); }

.g_la_indent {
  grid-gap: var(--space_la_indent); }

.g_x_la_indent {
  column-gap: var(--space_la_indent); }

.g_y_la_indent {
  row-gap: var(--space_la_indent); }

.top_la_indent {
  top: var(--space_la_indent); }

.p_top_la_indent {
  padding-top: var(--space_la_indent); }

.m_top_la_indent {
  margin-top: var(--space_la_indent); }

.right_la_indent {
  right: var(--space_la_indent); }

.p_right_la_indent {
  padding-right: var(--space_la_indent); }

.m_right_la_indent {
  margin-right: var(--space_la_indent); }

.bottom_la_indent {
  bottom: var(--space_la_indent); }

.p_bottom_la_indent {
  padding-bottom: var(--space_la_indent); }

.m_bottom_la_indent {
  margin-bottom: var(--space_la_indent); }

.left_la_indent {
  left: var(--space_la_indent); }

.p_left_la_indent {
  padding-left: var(--space_la_indent); }

.m_left_la_indent {
  margin-left: var(--space_la_indent); }

.p_2la_indent {
  padding: var(--space_2la_indent); }

.p_x_2la_indent {
  padding-right: var(--space_2la_indent);
  padding-left: var(--space_2la_indent); }

.p_y_2la_indent {
  padding-top: var(--space_2la_indent);
  padding-bottom: var(--space_2la_indent); }

.m_2la_indent {
  margin: var(--space_2la_indent); }

.m_x_2la_indent {
  margin-right: var(--space_2la_indent);
  margin-left: var(--space_2la_indent); }

.m_y_2la_indent {
  margin-top: var(--space_2la_indent);
  margin-bottom: var(--space_2la_indent); }

.g_2la_indent {
  grid-gap: var(--space_2la_indent); }

.g_x_2la_indent {
  column-gap: var(--space_2la_indent); }

.g_y_2la_indent {
  row-gap: var(--space_2la_indent); }

.top_2la_indent {
  top: var(--space_2la_indent); }

.p_top_2la_indent {
  padding-top: var(--space_2la_indent); }

.m_top_2la_indent {
  margin-top: var(--space_2la_indent); }

.right_2la_indent {
  right: var(--space_2la_indent); }

.p_right_2la_indent {
  padding-right: var(--space_2la_indent); }

.m_right_2la_indent {
  margin-right: var(--space_2la_indent); }

.bottom_2la_indent {
  bottom: var(--space_2la_indent); }

.p_bottom_2la_indent {
  padding-bottom: var(--space_2la_indent); }

.m_bottom_2la_indent {
  margin-bottom: var(--space_2la_indent); }

.left_2la_indent {
  left: var(--space_2la_indent); }

.p_left_2la_indent {
  padding-left: var(--space_2la_indent); }

.m_left_2la_indent {
  margin-left: var(--space_2la_indent); }

.p_xl_indent {
  padding: var(--space_xl_indent); }

.p_x_xl_indent {
  padding-right: var(--space_xl_indent);
  padding-left: var(--space_xl_indent); }

.p_y_xl_indent {
  padding-top: var(--space_xl_indent);
  padding-bottom: var(--space_xl_indent); }

.m_xl_indent {
  margin: var(--space_xl_indent); }

.m_x_xl_indent {
  margin-right: var(--space_xl_indent);
  margin-left: var(--space_xl_indent); }

.m_y_xl_indent {
  margin-top: var(--space_xl_indent);
  margin-bottom: var(--space_xl_indent); }

.g_xl_indent {
  grid-gap: var(--space_xl_indent); }

.g_x_xl_indent {
  column-gap: var(--space_xl_indent); }

.g_y_xl_indent {
  row-gap: var(--space_xl_indent); }

.top_xl_indent {
  top: var(--space_xl_indent); }

.p_top_xl_indent {
  padding-top: var(--space_xl_indent); }

.m_top_xl_indent {
  margin-top: var(--space_xl_indent); }

.right_xl_indent {
  right: var(--space_xl_indent); }

.p_right_xl_indent {
  padding-right: var(--space_xl_indent); }

.m_right_xl_indent {
  margin-right: var(--space_xl_indent); }

.bottom_xl_indent {
  bottom: var(--space_xl_indent); }

.p_bottom_xl_indent {
  padding-bottom: var(--space_xl_indent); }

.m_bottom_xl_indent {
  margin-bottom: var(--space_xl_indent); }

.left_xl_indent {
  left: var(--space_xl_indent); }

.p_left_xl_indent {
  padding-left: var(--space_xl_indent); }

.m_left_xl_indent {
  margin-left: var(--space_xl_indent); }

.p_2xl_indent {
  padding: var(--space_2xl_indent); }

.p_x_2xl_indent {
  padding-right: var(--space_2xl_indent);
  padding-left: var(--space_2xl_indent); }

.p_y_2xl_indent {
  padding-top: var(--space_2xl_indent);
  padding-bottom: var(--space_2xl_indent); }

.m_2xl_indent {
  margin: var(--space_2xl_indent); }

.m_x_2xl_indent {
  margin-right: var(--space_2xl_indent);
  margin-left: var(--space_2xl_indent); }

.m_y_2xl_indent {
  margin-top: var(--space_2xl_indent);
  margin-bottom: var(--space_2xl_indent); }

.g_2xl_indent {
  grid-gap: var(--space_2xl_indent); }

.g_x_2xl_indent {
  column-gap: var(--space_2xl_indent); }

.g_y_2xl_indent {
  row-gap: var(--space_2xl_indent); }

.top_2xl_indent {
  top: var(--space_2xl_indent); }

.p_top_2xl_indent {
  padding-top: var(--space_2xl_indent); }

.m_top_2xl_indent {
  margin-top: var(--space_2xl_indent); }

.right_2xl_indent {
  right: var(--space_2xl_indent); }

.p_right_2xl_indent {
  padding-right: var(--space_2xl_indent); }

.m_right_2xl_indent {
  margin-right: var(--space_2xl_indent); }

.bottom_2xl_indent {
  bottom: var(--space_2xl_indent); }

.p_bottom_2xl_indent {
  padding-bottom: var(--space_2xl_indent); }

.m_bottom_2xl_indent {
  margin-bottom: var(--space_2xl_indent); }

.left_2xl_indent {
  left: var(--space_2xl_indent); }

.p_left_2xl_indent {
  padding-left: var(--space_2xl_indent); }

.m_left_2xl_indent {
  margin-left: var(--space_2xl_indent); }

.p_ma_indent {
  padding: var(--space_ma_indent); }

.p_x_ma_indent {
  padding-right: var(--space_ma_indent);
  padding-left: var(--space_ma_indent); }

.p_y_ma_indent {
  padding-top: var(--space_ma_indent);
  padding-bottom: var(--space_ma_indent); }

.m_ma_indent {
  margin: var(--space_ma_indent); }

.m_x_ma_indent {
  margin-right: var(--space_ma_indent);
  margin-left: var(--space_ma_indent); }

.m_y_ma_indent {
  margin-top: var(--space_ma_indent);
  margin-bottom: var(--space_ma_indent); }

.g_ma_indent {
  grid-gap: var(--space_ma_indent); }

.g_x_ma_indent {
  column-gap: var(--space_ma_indent); }

.g_y_ma_indent {
  row-gap: var(--space_ma_indent); }

.top_ma_indent {
  top: var(--space_ma_indent); }

.p_top_ma_indent {
  padding-top: var(--space_ma_indent); }

.m_top_ma_indent {
  margin-top: var(--space_ma_indent); }

.right_ma_indent {
  right: var(--space_ma_indent); }

.p_right_ma_indent {
  padding-right: var(--space_ma_indent); }

.m_right_ma_indent {
  margin-right: var(--space_ma_indent); }

.bottom_ma_indent {
  bottom: var(--space_ma_indent); }

.p_bottom_ma_indent {
  padding-bottom: var(--space_ma_indent); }

.m_bottom_ma_indent {
  margin-bottom: var(--space_ma_indent); }

.left_ma_indent {
  left: var(--space_ma_indent); }

.p_left_ma_indent {
  padding-left: var(--space_ma_indent); }

.m_left_ma_indent {
  margin-left: var(--space_ma_indent); }

.p_0 {
  padding: var(--space_0); }

.p_x_0 {
  padding-right: var(--space_0);
  padding-left: var(--space_0); }

.p_y_0 {
  padding-top: var(--space_0);
  padding-bottom: var(--space_0); }

.m_0 {
  margin: var(--space_0); }

.m_x_0 {
  margin-right: var(--space_0);
  margin-left: var(--space_0); }

.m_y_0 {
  margin-top: var(--space_0);
  margin-bottom: var(--space_0); }

.g_0 {
  grid-gap: var(--space_0); }

.g_x_0 {
  column-gap: var(--space_0); }

.g_y_0 {
  row-gap: var(--space_0); }

.top_0 {
  top: var(--space_0); }

.p_top_0 {
  padding-top: var(--space_0); }

.m_top_0 {
  margin-top: var(--space_0); }

.right_0 {
  right: var(--space_0); }

.p_right_0 {
  padding-right: var(--space_0); }

.m_right_0 {
  margin-right: var(--space_0); }

.bottom_0 {
  bottom: var(--space_0); }

.p_bottom_0 {
  padding-bottom: var(--space_0); }

.m_bottom_0 {
  margin-bottom: var(--space_0); }

.left_0 {
  left: var(--space_0); }

.p_left_0 {
  padding-left: var(--space_0); }

.m_left_0 {
  margin-left: var(--space_0); }

.p_auto {
  padding: var(--space_auto); }

.p_x_auto {
  padding-right: var(--space_auto);
  padding-left: var(--space_auto); }

.p_y_auto {
  padding-top: var(--space_auto);
  padding-bottom: var(--space_auto); }

.m_auto {
  margin: var(--space_auto); }

.m_x_auto {
  margin-right: var(--space_auto);
  margin-left: var(--space_auto); }

.m_y_auto {
  margin-top: var(--space_auto);
  margin-bottom: var(--space_auto); }

.g_auto {
  grid-gap: var(--space_auto); }

.g_x_auto {
  column-gap: var(--space_auto); }

.g_y_auto {
  row-gap: var(--space_auto); }

.top_auto {
  top: var(--space_auto); }

.p_top_auto {
  padding-top: var(--space_auto); }

.m_top_auto {
  margin-top: var(--space_auto); }

.right_auto {
  right: var(--space_auto); }

.p_right_auto {
  padding-right: var(--space_auto); }

.m_right_auto {
  margin-right: var(--space_auto); }

.bottom_auto {
  bottom: var(--space_auto); }

.p_bottom_auto {
  padding-bottom: var(--space_auto); }

.m_bottom_auto {
  margin-bottom: var(--space_auto); }

.left_auto {
  left: var(--space_auto); }

.p_left_auto {
  padding-left: var(--space_auto); }

.m_left_auto {
  margin-left: var(--space_auto); }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_ss {
    top: var(--space_ss); }
  .ms\:p_top_ss {
    padding-top: var(--space_ss); }
  .ms\:m_top_ss {
    margin-top: var(--space_ss); }
  .ms\:right_ss {
    right: var(--space_ss); }
  .ms\:p_right_ss {
    padding-right: var(--space_ss); }
  .ms\:m_right_ss {
    margin-right: var(--space_ss); }
  .ms\:bottom_ss {
    bottom: var(--space_ss); }
  .ms\:p_bottom_ss {
    padding-bottom: var(--space_ss); }
  .ms\:m_bottom_ss {
    margin-bottom: var(--space_ss); }
  .ms\:left_ss {
    left: var(--space_ss); }
  .ms\:p_left_ss {
    padding-left: var(--space_ss); }
  .ms\:m_left_ss {
    margin-left: var(--space_ss); }
  .ms\:p_ss {
    padding: var(--space_ss); }
  .ms\:p_x_ss {
    padding-right: var(--space_ss);
    padding-left: var(--space_ss); }
  .ms\:p_y_ss {
    padding-top: var(--space_ss);
    padding-bottom: var(--space_ss); }
  .ms\:m_ss {
    margin: var(--space_ss); }
  .ms\:m_x_ss {
    margin-right: var(--space_ss);
    margin-left: var(--space_ss); }
  .ms\:m_y_ss {
    margin-top: var(--space_ss);
    margin-bottom: var(--space_ss); }
  .ms\:g_ss {
    grid-gap: var(--space_ss); }
  .ms\:g_x_ss {
    column-gap: var(--space_ss); }
  .ms\:g_y_ss {
    row-gap: var(--space_ss); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_sm {
    top: var(--space_sm); }
  .ms\:p_top_sm {
    padding-top: var(--space_sm); }
  .ms\:m_top_sm {
    margin-top: var(--space_sm); }
  .ms\:right_sm {
    right: var(--space_sm); }
  .ms\:p_right_sm {
    padding-right: var(--space_sm); }
  .ms\:m_right_sm {
    margin-right: var(--space_sm); }
  .ms\:bottom_sm {
    bottom: var(--space_sm); }
  .ms\:p_bottom_sm {
    padding-bottom: var(--space_sm); }
  .ms\:m_bottom_sm {
    margin-bottom: var(--space_sm); }
  .ms\:left_sm {
    left: var(--space_sm); }
  .ms\:p_left_sm {
    padding-left: var(--space_sm); }
  .ms\:m_left_sm {
    margin-left: var(--space_sm); }
  .ms\:p_sm {
    padding: var(--space_sm); }
  .ms\:p_x_sm {
    padding-right: var(--space_sm);
    padding-left: var(--space_sm); }
  .ms\:p_y_sm {
    padding-top: var(--space_sm);
    padding-bottom: var(--space_sm); }
  .ms\:m_sm {
    margin: var(--space_sm); }
  .ms\:m_x_sm {
    margin-right: var(--space_sm);
    margin-left: var(--space_sm); }
  .ms\:m_y_sm {
    margin-top: var(--space_sm);
    margin-bottom: var(--space_sm); }
  .ms\:g_sm {
    grid-gap: var(--space_sm); }
  .ms\:g_x_sm {
    column-gap: var(--space_sm); }
  .ms\:g_y_sm {
    row-gap: var(--space_sm); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_base {
    top: var(--space_base); }
  .ms\:p_top_base {
    padding-top: var(--space_base); }
  .ms\:m_top_base {
    margin-top: var(--space_base); }
  .ms\:right_base {
    right: var(--space_base); }
  .ms\:p_right_base {
    padding-right: var(--space_base); }
  .ms\:m_right_base {
    margin-right: var(--space_base); }
  .ms\:bottom_base {
    bottom: var(--space_base); }
  .ms\:p_bottom_base {
    padding-bottom: var(--space_base); }
  .ms\:m_bottom_base {
    margin-bottom: var(--space_base); }
  .ms\:left_base {
    left: var(--space_base); }
  .ms\:p_left_base {
    padding-left: var(--space_base); }
  .ms\:m_left_base {
    margin-left: var(--space_base); }
  .ms\:p_base {
    padding: var(--space_base); }
  .ms\:p_x_base {
    padding-right: var(--space_base);
    padding-left: var(--space_base); }
  .ms\:p_y_base {
    padding-top: var(--space_base);
    padding-bottom: var(--space_base); }
  .ms\:m_base {
    margin: var(--space_base); }
  .ms\:m_x_base {
    margin-right: var(--space_base);
    margin-left: var(--space_base); }
  .ms\:m_y_base {
    margin-top: var(--space_base);
    margin-bottom: var(--space_base); }
  .ms\:g_base {
    grid-gap: var(--space_base); }
  .ms\:g_x_base {
    column-gap: var(--space_base); }
  .ms\:g_y_base {
    row-gap: var(--space_base); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_me {
    top: var(--space_me); }
  .ms\:p_top_me {
    padding-top: var(--space_me); }
  .ms\:m_top_me {
    margin-top: var(--space_me); }
  .ms\:right_me {
    right: var(--space_me); }
  .ms\:p_right_me {
    padding-right: var(--space_me); }
  .ms\:m_right_me {
    margin-right: var(--space_me); }
  .ms\:bottom_me {
    bottom: var(--space_me); }
  .ms\:p_bottom_me {
    padding-bottom: var(--space_me); }
  .ms\:m_bottom_me {
    margin-bottom: var(--space_me); }
  .ms\:left_me {
    left: var(--space_me); }
  .ms\:p_left_me {
    padding-left: var(--space_me); }
  .ms\:m_left_me {
    margin-left: var(--space_me); }
  .ms\:p_me {
    padding: var(--space_me); }
  .ms\:p_x_me {
    padding-right: var(--space_me);
    padding-left: var(--space_me); }
  .ms\:p_y_me {
    padding-top: var(--space_me);
    padding-bottom: var(--space_me); }
  .ms\:m_me {
    margin: var(--space_me); }
  .ms\:m_x_me {
    margin-right: var(--space_me);
    margin-left: var(--space_me); }
  .ms\:m_y_me {
    margin-top: var(--space_me);
    margin-bottom: var(--space_me); }
  .ms\:g_me {
    grid-gap: var(--space_me); }
  .ms\:g_x_me {
    column-gap: var(--space_me); }
  .ms\:g_y_me {
    row-gap: var(--space_me); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_la {
    top: var(--space_la); }
  .ms\:p_top_la {
    padding-top: var(--space_la); }
  .ms\:m_top_la {
    margin-top: var(--space_la); }
  .ms\:right_la {
    right: var(--space_la); }
  .ms\:p_right_la {
    padding-right: var(--space_la); }
  .ms\:m_right_la {
    margin-right: var(--space_la); }
  .ms\:bottom_la {
    bottom: var(--space_la); }
  .ms\:p_bottom_la {
    padding-bottom: var(--space_la); }
  .ms\:m_bottom_la {
    margin-bottom: var(--space_la); }
  .ms\:left_la {
    left: var(--space_la); }
  .ms\:p_left_la {
    padding-left: var(--space_la); }
  .ms\:m_left_la {
    margin-left: var(--space_la); }
  .ms\:p_la {
    padding: var(--space_la); }
  .ms\:p_x_la {
    padding-right: var(--space_la);
    padding-left: var(--space_la); }
  .ms\:p_y_la {
    padding-top: var(--space_la);
    padding-bottom: var(--space_la); }
  .ms\:m_la {
    margin: var(--space_la); }
  .ms\:m_x_la {
    margin-right: var(--space_la);
    margin-left: var(--space_la); }
  .ms\:m_y_la {
    margin-top: var(--space_la);
    margin-bottom: var(--space_la); }
  .ms\:g_la {
    grid-gap: var(--space_la); }
  .ms\:g_x_la {
    column-gap: var(--space_la); }
  .ms\:g_y_la {
    row-gap: var(--space_la); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_2la {
    top: var(--space_2la); }
  .ms\:p_top_2la {
    padding-top: var(--space_2la); }
  .ms\:m_top_2la {
    margin-top: var(--space_2la); }
  .ms\:right_2la {
    right: var(--space_2la); }
  .ms\:p_right_2la {
    padding-right: var(--space_2la); }
  .ms\:m_right_2la {
    margin-right: var(--space_2la); }
  .ms\:bottom_2la {
    bottom: var(--space_2la); }
  .ms\:p_bottom_2la {
    padding-bottom: var(--space_2la); }
  .ms\:m_bottom_2la {
    margin-bottom: var(--space_2la); }
  .ms\:left_2la {
    left: var(--space_2la); }
  .ms\:p_left_2la {
    padding-left: var(--space_2la); }
  .ms\:m_left_2la {
    margin-left: var(--space_2la); }
  .ms\:p_2la {
    padding: var(--space_2la); }
  .ms\:p_x_2la {
    padding-right: var(--space_2la);
    padding-left: var(--space_2la); }
  .ms\:p_y_2la {
    padding-top: var(--space_2la);
    padding-bottom: var(--space_2la); }
  .ms\:m_2la {
    margin: var(--space_2la); }
  .ms\:m_x_2la {
    margin-right: var(--space_2la);
    margin-left: var(--space_2la); }
  .ms\:m_y_2la {
    margin-top: var(--space_2la);
    margin-bottom: var(--space_2la); }
  .ms\:g_2la {
    grid-gap: var(--space_2la); }
  .ms\:g_x_2la {
    column-gap: var(--space_2la); }
  .ms\:g_y_2la {
    row-gap: var(--space_2la); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_xl {
    top: var(--space_xl); }
  .ms\:p_top_xl {
    padding-top: var(--space_xl); }
  .ms\:m_top_xl {
    margin-top: var(--space_xl); }
  .ms\:right_xl {
    right: var(--space_xl); }
  .ms\:p_right_xl {
    padding-right: var(--space_xl); }
  .ms\:m_right_xl {
    margin-right: var(--space_xl); }
  .ms\:bottom_xl {
    bottom: var(--space_xl); }
  .ms\:p_bottom_xl {
    padding-bottom: var(--space_xl); }
  .ms\:m_bottom_xl {
    margin-bottom: var(--space_xl); }
  .ms\:left_xl {
    left: var(--space_xl); }
  .ms\:p_left_xl {
    padding-left: var(--space_xl); }
  .ms\:m_left_xl {
    margin-left: var(--space_xl); }
  .ms\:p_xl {
    padding: var(--space_xl); }
  .ms\:p_x_xl {
    padding-right: var(--space_xl);
    padding-left: var(--space_xl); }
  .ms\:p_y_xl {
    padding-top: var(--space_xl);
    padding-bottom: var(--space_xl); }
  .ms\:m_xl {
    margin: var(--space_xl); }
  .ms\:m_x_xl {
    margin-right: var(--space_xl);
    margin-left: var(--space_xl); }
  .ms\:m_y_xl {
    margin-top: var(--space_xl);
    margin-bottom: var(--space_xl); }
  .ms\:g_xl {
    grid-gap: var(--space_xl); }
  .ms\:g_x_xl {
    column-gap: var(--space_xl); }
  .ms\:g_y_xl {
    row-gap: var(--space_xl); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_2xl {
    top: var(--space_2xl); }
  .ms\:p_top_2xl {
    padding-top: var(--space_2xl); }
  .ms\:m_top_2xl {
    margin-top: var(--space_2xl); }
  .ms\:right_2xl {
    right: var(--space_2xl); }
  .ms\:p_right_2xl {
    padding-right: var(--space_2xl); }
  .ms\:m_right_2xl {
    margin-right: var(--space_2xl); }
  .ms\:bottom_2xl {
    bottom: var(--space_2xl); }
  .ms\:p_bottom_2xl {
    padding-bottom: var(--space_2xl); }
  .ms\:m_bottom_2xl {
    margin-bottom: var(--space_2xl); }
  .ms\:left_2xl {
    left: var(--space_2xl); }
  .ms\:p_left_2xl {
    padding-left: var(--space_2xl); }
  .ms\:m_left_2xl {
    margin-left: var(--space_2xl); }
  .ms\:p_2xl {
    padding: var(--space_2xl); }
  .ms\:p_x_2xl {
    padding-right: var(--space_2xl);
    padding-left: var(--space_2xl); }
  .ms\:p_y_2xl {
    padding-top: var(--space_2xl);
    padding-bottom: var(--space_2xl); }
  .ms\:m_2xl {
    margin: var(--space_2xl); }
  .ms\:m_x_2xl {
    margin-right: var(--space_2xl);
    margin-left: var(--space_2xl); }
  .ms\:m_y_2xl {
    margin-top: var(--space_2xl);
    margin-bottom: var(--space_2xl); }
  .ms\:g_2xl {
    grid-gap: var(--space_2xl); }
  .ms\:g_x_2xl {
    column-gap: var(--space_2xl); }
  .ms\:g_y_2xl {
    row-gap: var(--space_2xl); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_ma {
    top: var(--space_ma); }
  .ms\:p_top_ma {
    padding-top: var(--space_ma); }
  .ms\:m_top_ma {
    margin-top: var(--space_ma); }
  .ms\:right_ma {
    right: var(--space_ma); }
  .ms\:p_right_ma {
    padding-right: var(--space_ma); }
  .ms\:m_right_ma {
    margin-right: var(--space_ma); }
  .ms\:bottom_ma {
    bottom: var(--space_ma); }
  .ms\:p_bottom_ma {
    padding-bottom: var(--space_ma); }
  .ms\:m_bottom_ma {
    margin-bottom: var(--space_ma); }
  .ms\:left_ma {
    left: var(--space_ma); }
  .ms\:p_left_ma {
    padding-left: var(--space_ma); }
  .ms\:m_left_ma {
    margin-left: var(--space_ma); }
  .ms\:p_ma {
    padding: var(--space_ma); }
  .ms\:p_x_ma {
    padding-right: var(--space_ma);
    padding-left: var(--space_ma); }
  .ms\:p_y_ma {
    padding-top: var(--space_ma);
    padding-bottom: var(--space_ma); }
  .ms\:m_ma {
    margin: var(--space_ma); }
  .ms\:m_x_ma {
    margin-right: var(--space_ma);
    margin-left: var(--space_ma); }
  .ms\:m_y_ma {
    margin-top: var(--space_ma);
    margin-bottom: var(--space_ma); }
  .ms\:g_ma {
    grid-gap: var(--space_ma); }
  .ms\:g_x_ma {
    column-gap: var(--space_ma); }
  .ms\:g_y_ma {
    row-gap: var(--space_ma); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_2ma {
    top: var(--space_2ma); }
  .ms\:p_top_2ma {
    padding-top: var(--space_2ma); }
  .ms\:m_top_2ma {
    margin-top: var(--space_2ma); }
  .ms\:right_2ma {
    right: var(--space_2ma); }
  .ms\:p_right_2ma {
    padding-right: var(--space_2ma); }
  .ms\:m_right_2ma {
    margin-right: var(--space_2ma); }
  .ms\:bottom_2ma {
    bottom: var(--space_2ma); }
  .ms\:p_bottom_2ma {
    padding-bottom: var(--space_2ma); }
  .ms\:m_bottom_2ma {
    margin-bottom: var(--space_2ma); }
  .ms\:left_2ma {
    left: var(--space_2ma); }
  .ms\:p_left_2ma {
    padding-left: var(--space_2ma); }
  .ms\:m_left_2ma {
    margin-left: var(--space_2ma); }
  .ms\:p_2ma {
    padding: var(--space_2ma); }
  .ms\:p_x_2ma {
    padding-right: var(--space_2ma);
    padding-left: var(--space_2ma); }
  .ms\:p_y_2ma {
    padding-top: var(--space_2ma);
    padding-bottom: var(--space_2ma); }
  .ms\:m_2ma {
    margin: var(--space_2ma); }
  .ms\:m_x_2ma {
    margin-right: var(--space_2ma);
    margin-left: var(--space_2ma); }
  .ms\:m_y_2ma {
    margin-top: var(--space_2ma);
    margin-bottom: var(--space_2ma); }
  .ms\:g_2ma {
    grid-gap: var(--space_2ma); }
  .ms\:g_x_2ma {
    column-gap: var(--space_2ma); }
  .ms\:g_y_2ma {
    row-gap: var(--space_2ma); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_ss_indent {
    top: var(--space_ss_indent); }
  .ms\:p_top_ss_indent {
    padding-top: var(--space_ss_indent); }
  .ms\:m_top_ss_indent {
    margin-top: var(--space_ss_indent); }
  .ms\:right_ss_indent {
    right: var(--space_ss_indent); }
  .ms\:p_right_ss_indent {
    padding-right: var(--space_ss_indent); }
  .ms\:m_right_ss_indent {
    margin-right: var(--space_ss_indent); }
  .ms\:bottom_ss_indent {
    bottom: var(--space_ss_indent); }
  .ms\:p_bottom_ss_indent {
    padding-bottom: var(--space_ss_indent); }
  .ms\:m_bottom_ss_indent {
    margin-bottom: var(--space_ss_indent); }
  .ms\:left_ss_indent {
    left: var(--space_ss_indent); }
  .ms\:p_left_ss_indent {
    padding-left: var(--space_ss_indent); }
  .ms\:m_left_ss_indent {
    margin-left: var(--space_ss_indent); }
  .ms\:p_ss_indent {
    padding: var(--space_ss_indent); }
  .ms\:p_x_ss_indent {
    padding-right: var(--space_ss_indent);
    padding-left: var(--space_ss_indent); }
  .ms\:p_y_ss_indent {
    padding-top: var(--space_ss_indent);
    padding-bottom: var(--space_ss_indent); }
  .ms\:m_ss_indent {
    margin: var(--space_ss_indent); }
  .ms\:m_x_ss_indent {
    margin-right: var(--space_ss_indent);
    margin-left: var(--space_ss_indent); }
  .ms\:m_y_ss_indent {
    margin-top: var(--space_ss_indent);
    margin-bottom: var(--space_ss_indent); }
  .ms\:g_ss_indent {
    grid-gap: var(--space_ss_indent); }
  .ms\:g_x_ss_indent {
    column-gap: var(--space_ss_indent); }
  .ms\:g_y_ss_indent {
    row-gap: var(--space_ss_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_sm_indent {
    top: var(--space_sm_indent); }
  .ms\:p_top_sm_indent {
    padding-top: var(--space_sm_indent); }
  .ms\:m_top_sm_indent {
    margin-top: var(--space_sm_indent); }
  .ms\:right_sm_indent {
    right: var(--space_sm_indent); }
  .ms\:p_right_sm_indent {
    padding-right: var(--space_sm_indent); }
  .ms\:m_right_sm_indent {
    margin-right: var(--space_sm_indent); }
  .ms\:bottom_sm_indent {
    bottom: var(--space_sm_indent); }
  .ms\:p_bottom_sm_indent {
    padding-bottom: var(--space_sm_indent); }
  .ms\:m_bottom_sm_indent {
    margin-bottom: var(--space_sm_indent); }
  .ms\:left_sm_indent {
    left: var(--space_sm_indent); }
  .ms\:p_left_sm_indent {
    padding-left: var(--space_sm_indent); }
  .ms\:m_left_sm_indent {
    margin-left: var(--space_sm_indent); }
  .ms\:p_sm_indent {
    padding: var(--space_sm_indent); }
  .ms\:p_x_sm_indent {
    padding-right: var(--space_sm_indent);
    padding-left: var(--space_sm_indent); }
  .ms\:p_y_sm_indent {
    padding-top: var(--space_sm_indent);
    padding-bottom: var(--space_sm_indent); }
  .ms\:m_sm_indent {
    margin: var(--space_sm_indent); }
  .ms\:m_x_sm_indent {
    margin-right: var(--space_sm_indent);
    margin-left: var(--space_sm_indent); }
  .ms\:m_y_sm_indent {
    margin-top: var(--space_sm_indent);
    margin-bottom: var(--space_sm_indent); }
  .ms\:g_sm_indent {
    grid-gap: var(--space_sm_indent); }
  .ms\:g_x_sm_indent {
    column-gap: var(--space_sm_indent); }
  .ms\:g_y_sm_indent {
    row-gap: var(--space_sm_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_base_indent {
    top: var(--space_base_indent); }
  .ms\:p_top_base_indent {
    padding-top: var(--space_base_indent); }
  .ms\:m_top_base_indent {
    margin-top: var(--space_base_indent); }
  .ms\:right_base_indent {
    right: var(--space_base_indent); }
  .ms\:p_right_base_indent {
    padding-right: var(--space_base_indent); }
  .ms\:m_right_base_indent {
    margin-right: var(--space_base_indent); }
  .ms\:bottom_base_indent {
    bottom: var(--space_base_indent); }
  .ms\:p_bottom_base_indent {
    padding-bottom: var(--space_base_indent); }
  .ms\:m_bottom_base_indent {
    margin-bottom: var(--space_base_indent); }
  .ms\:left_base_indent {
    left: var(--space_base_indent); }
  .ms\:p_left_base_indent {
    padding-left: var(--space_base_indent); }
  .ms\:m_left_base_indent {
    margin-left: var(--space_base_indent); }
  .ms\:p_base_indent {
    padding: var(--space_base_indent); }
  .ms\:p_x_base_indent {
    padding-right: var(--space_base_indent);
    padding-left: var(--space_base_indent); }
  .ms\:p_y_base_indent {
    padding-top: var(--space_base_indent);
    padding-bottom: var(--space_base_indent); }
  .ms\:m_base_indent {
    margin: var(--space_base_indent); }
  .ms\:m_x_base_indent {
    margin-right: var(--space_base_indent);
    margin-left: var(--space_base_indent); }
  .ms\:m_y_base_indent {
    margin-top: var(--space_base_indent);
    margin-bottom: var(--space_base_indent); }
  .ms\:g_base_indent {
    grid-gap: var(--space_base_indent); }
  .ms\:g_x_base_indent {
    column-gap: var(--space_base_indent); }
  .ms\:g_y_base_indent {
    row-gap: var(--space_base_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_me_indent {
    top: var(--space_me_indent); }
  .ms\:p_top_me_indent {
    padding-top: var(--space_me_indent); }
  .ms\:m_top_me_indent {
    margin-top: var(--space_me_indent); }
  .ms\:right_me_indent {
    right: var(--space_me_indent); }
  .ms\:p_right_me_indent {
    padding-right: var(--space_me_indent); }
  .ms\:m_right_me_indent {
    margin-right: var(--space_me_indent); }
  .ms\:bottom_me_indent {
    bottom: var(--space_me_indent); }
  .ms\:p_bottom_me_indent {
    padding-bottom: var(--space_me_indent); }
  .ms\:m_bottom_me_indent {
    margin-bottom: var(--space_me_indent); }
  .ms\:left_me_indent {
    left: var(--space_me_indent); }
  .ms\:p_left_me_indent {
    padding-left: var(--space_me_indent); }
  .ms\:m_left_me_indent {
    margin-left: var(--space_me_indent); }
  .ms\:p_me_indent {
    padding: var(--space_me_indent); }
  .ms\:p_x_me_indent {
    padding-right: var(--space_me_indent);
    padding-left: var(--space_me_indent); }
  .ms\:p_y_me_indent {
    padding-top: var(--space_me_indent);
    padding-bottom: var(--space_me_indent); }
  .ms\:m_me_indent {
    margin: var(--space_me_indent); }
  .ms\:m_x_me_indent {
    margin-right: var(--space_me_indent);
    margin-left: var(--space_me_indent); }
  .ms\:m_y_me_indent {
    margin-top: var(--space_me_indent);
    margin-bottom: var(--space_me_indent); }
  .ms\:g_me_indent {
    grid-gap: var(--space_me_indent); }
  .ms\:g_x_me_indent {
    column-gap: var(--space_me_indent); }
  .ms\:g_y_me_indent {
    row-gap: var(--space_me_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_la_indent {
    top: var(--space_la_indent); }
  .ms\:p_top_la_indent {
    padding-top: var(--space_la_indent); }
  .ms\:m_top_la_indent {
    margin-top: var(--space_la_indent); }
  .ms\:right_la_indent {
    right: var(--space_la_indent); }
  .ms\:p_right_la_indent {
    padding-right: var(--space_la_indent); }
  .ms\:m_right_la_indent {
    margin-right: var(--space_la_indent); }
  .ms\:bottom_la_indent {
    bottom: var(--space_la_indent); }
  .ms\:p_bottom_la_indent {
    padding-bottom: var(--space_la_indent); }
  .ms\:m_bottom_la_indent {
    margin-bottom: var(--space_la_indent); }
  .ms\:left_la_indent {
    left: var(--space_la_indent); }
  .ms\:p_left_la_indent {
    padding-left: var(--space_la_indent); }
  .ms\:m_left_la_indent {
    margin-left: var(--space_la_indent); }
  .ms\:p_la_indent {
    padding: var(--space_la_indent); }
  .ms\:p_x_la_indent {
    padding-right: var(--space_la_indent);
    padding-left: var(--space_la_indent); }
  .ms\:p_y_la_indent {
    padding-top: var(--space_la_indent);
    padding-bottom: var(--space_la_indent); }
  .ms\:m_la_indent {
    margin: var(--space_la_indent); }
  .ms\:m_x_la_indent {
    margin-right: var(--space_la_indent);
    margin-left: var(--space_la_indent); }
  .ms\:m_y_la_indent {
    margin-top: var(--space_la_indent);
    margin-bottom: var(--space_la_indent); }
  .ms\:g_la_indent {
    grid-gap: var(--space_la_indent); }
  .ms\:g_x_la_indent {
    column-gap: var(--space_la_indent); }
  .ms\:g_y_la_indent {
    row-gap: var(--space_la_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_2la_indent {
    top: var(--space_2la_indent); }
  .ms\:p_top_2la_indent {
    padding-top: var(--space_2la_indent); }
  .ms\:m_top_2la_indent {
    margin-top: var(--space_2la_indent); }
  .ms\:right_2la_indent {
    right: var(--space_2la_indent); }
  .ms\:p_right_2la_indent {
    padding-right: var(--space_2la_indent); }
  .ms\:m_right_2la_indent {
    margin-right: var(--space_2la_indent); }
  .ms\:bottom_2la_indent {
    bottom: var(--space_2la_indent); }
  .ms\:p_bottom_2la_indent {
    padding-bottom: var(--space_2la_indent); }
  .ms\:m_bottom_2la_indent {
    margin-bottom: var(--space_2la_indent); }
  .ms\:left_2la_indent {
    left: var(--space_2la_indent); }
  .ms\:p_left_2la_indent {
    padding-left: var(--space_2la_indent); }
  .ms\:m_left_2la_indent {
    margin-left: var(--space_2la_indent); }
  .ms\:p_2la_indent {
    padding: var(--space_2la_indent); }
  .ms\:p_x_2la_indent {
    padding-right: var(--space_2la_indent);
    padding-left: var(--space_2la_indent); }
  .ms\:p_y_2la_indent {
    padding-top: var(--space_2la_indent);
    padding-bottom: var(--space_2la_indent); }
  .ms\:m_2la_indent {
    margin: var(--space_2la_indent); }
  .ms\:m_x_2la_indent {
    margin-right: var(--space_2la_indent);
    margin-left: var(--space_2la_indent); }
  .ms\:m_y_2la_indent {
    margin-top: var(--space_2la_indent);
    margin-bottom: var(--space_2la_indent); }
  .ms\:g_2la_indent {
    grid-gap: var(--space_2la_indent); }
  .ms\:g_x_2la_indent {
    column-gap: var(--space_2la_indent); }
  .ms\:g_y_2la_indent {
    row-gap: var(--space_2la_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_xl_indent {
    top: var(--space_xl_indent); }
  .ms\:p_top_xl_indent {
    padding-top: var(--space_xl_indent); }
  .ms\:m_top_xl_indent {
    margin-top: var(--space_xl_indent); }
  .ms\:right_xl_indent {
    right: var(--space_xl_indent); }
  .ms\:p_right_xl_indent {
    padding-right: var(--space_xl_indent); }
  .ms\:m_right_xl_indent {
    margin-right: var(--space_xl_indent); }
  .ms\:bottom_xl_indent {
    bottom: var(--space_xl_indent); }
  .ms\:p_bottom_xl_indent {
    padding-bottom: var(--space_xl_indent); }
  .ms\:m_bottom_xl_indent {
    margin-bottom: var(--space_xl_indent); }
  .ms\:left_xl_indent {
    left: var(--space_xl_indent); }
  .ms\:p_left_xl_indent {
    padding-left: var(--space_xl_indent); }
  .ms\:m_left_xl_indent {
    margin-left: var(--space_xl_indent); }
  .ms\:p_xl_indent {
    padding: var(--space_xl_indent); }
  .ms\:p_x_xl_indent {
    padding-right: var(--space_xl_indent);
    padding-left: var(--space_xl_indent); }
  .ms\:p_y_xl_indent {
    padding-top: var(--space_xl_indent);
    padding-bottom: var(--space_xl_indent); }
  .ms\:m_xl_indent {
    margin: var(--space_xl_indent); }
  .ms\:m_x_xl_indent {
    margin-right: var(--space_xl_indent);
    margin-left: var(--space_xl_indent); }
  .ms\:m_y_xl_indent {
    margin-top: var(--space_xl_indent);
    margin-bottom: var(--space_xl_indent); }
  .ms\:g_xl_indent {
    grid-gap: var(--space_xl_indent); }
  .ms\:g_x_xl_indent {
    column-gap: var(--space_xl_indent); }
  .ms\:g_y_xl_indent {
    row-gap: var(--space_xl_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_2xl_indent {
    top: var(--space_2xl_indent); }
  .ms\:p_top_2xl_indent {
    padding-top: var(--space_2xl_indent); }
  .ms\:m_top_2xl_indent {
    margin-top: var(--space_2xl_indent); }
  .ms\:right_2xl_indent {
    right: var(--space_2xl_indent); }
  .ms\:p_right_2xl_indent {
    padding-right: var(--space_2xl_indent); }
  .ms\:m_right_2xl_indent {
    margin-right: var(--space_2xl_indent); }
  .ms\:bottom_2xl_indent {
    bottom: var(--space_2xl_indent); }
  .ms\:p_bottom_2xl_indent {
    padding-bottom: var(--space_2xl_indent); }
  .ms\:m_bottom_2xl_indent {
    margin-bottom: var(--space_2xl_indent); }
  .ms\:left_2xl_indent {
    left: var(--space_2xl_indent); }
  .ms\:p_left_2xl_indent {
    padding-left: var(--space_2xl_indent); }
  .ms\:m_left_2xl_indent {
    margin-left: var(--space_2xl_indent); }
  .ms\:p_2xl_indent {
    padding: var(--space_2xl_indent); }
  .ms\:p_x_2xl_indent {
    padding-right: var(--space_2xl_indent);
    padding-left: var(--space_2xl_indent); }
  .ms\:p_y_2xl_indent {
    padding-top: var(--space_2xl_indent);
    padding-bottom: var(--space_2xl_indent); }
  .ms\:m_2xl_indent {
    margin: var(--space_2xl_indent); }
  .ms\:m_x_2xl_indent {
    margin-right: var(--space_2xl_indent);
    margin-left: var(--space_2xl_indent); }
  .ms\:m_y_2xl_indent {
    margin-top: var(--space_2xl_indent);
    margin-bottom: var(--space_2xl_indent); }
  .ms\:g_2xl_indent {
    grid-gap: var(--space_2xl_indent); }
  .ms\:g_x_2xl_indent {
    column-gap: var(--space_2xl_indent); }
  .ms\:g_y_2xl_indent {
    row-gap: var(--space_2xl_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_ma_indent {
    top: var(--space_ma_indent); }
  .ms\:p_top_ma_indent {
    padding-top: var(--space_ma_indent); }
  .ms\:m_top_ma_indent {
    margin-top: var(--space_ma_indent); }
  .ms\:right_ma_indent {
    right: var(--space_ma_indent); }
  .ms\:p_right_ma_indent {
    padding-right: var(--space_ma_indent); }
  .ms\:m_right_ma_indent {
    margin-right: var(--space_ma_indent); }
  .ms\:bottom_ma_indent {
    bottom: var(--space_ma_indent); }
  .ms\:p_bottom_ma_indent {
    padding-bottom: var(--space_ma_indent); }
  .ms\:m_bottom_ma_indent {
    margin-bottom: var(--space_ma_indent); }
  .ms\:left_ma_indent {
    left: var(--space_ma_indent); }
  .ms\:p_left_ma_indent {
    padding-left: var(--space_ma_indent); }
  .ms\:m_left_ma_indent {
    margin-left: var(--space_ma_indent); }
  .ms\:p_ma_indent {
    padding: var(--space_ma_indent); }
  .ms\:p_x_ma_indent {
    padding-right: var(--space_ma_indent);
    padding-left: var(--space_ma_indent); }
  .ms\:p_y_ma_indent {
    padding-top: var(--space_ma_indent);
    padding-bottom: var(--space_ma_indent); }
  .ms\:m_ma_indent {
    margin: var(--space_ma_indent); }
  .ms\:m_x_ma_indent {
    margin-right: var(--space_ma_indent);
    margin-left: var(--space_ma_indent); }
  .ms\:m_y_ma_indent {
    margin-top: var(--space_ma_indent);
    margin-bottom: var(--space_ma_indent); }
  .ms\:g_ma_indent {
    grid-gap: var(--space_ma_indent); }
  .ms\:g_x_ma_indent {
    column-gap: var(--space_ma_indent); }
  .ms\:g_y_ma_indent {
    row-gap: var(--space_ma_indent); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_0 {
    top: var(--space_0); }
  .ms\:p_top_0 {
    padding-top: var(--space_0); }
  .ms\:m_top_0 {
    margin-top: var(--space_0); }
  .ms\:right_0 {
    right: var(--space_0); }
  .ms\:p_right_0 {
    padding-right: var(--space_0); }
  .ms\:m_right_0 {
    margin-right: var(--space_0); }
  .ms\:bottom_0 {
    bottom: var(--space_0); }
  .ms\:p_bottom_0 {
    padding-bottom: var(--space_0); }
  .ms\:m_bottom_0 {
    margin-bottom: var(--space_0); }
  .ms\:left_0 {
    left: var(--space_0); }
  .ms\:p_left_0 {
    padding-left: var(--space_0); }
  .ms\:m_left_0 {
    margin-left: var(--space_0); }
  .ms\:p_0 {
    padding: var(--space_0); }
  .ms\:p_x_0 {
    padding-right: var(--space_0);
    padding-left: var(--space_0); }
  .ms\:p_y_0 {
    padding-top: var(--space_0);
    padding-bottom: var(--space_0); }
  .ms\:m_0 {
    margin: var(--space_0); }
  .ms\:m_x_0 {
    margin-right: var(--space_0);
    margin-left: var(--space_0); }
  .ms\:m_y_0 {
    margin-top: var(--space_0);
    margin-bottom: var(--space_0); }
  .ms\:g_0 {
    grid-gap: var(--space_0); }
  .ms\:g_x_0 {
    column-gap: var(--space_0); }
  .ms\:g_y_0 {
    row-gap: var(--space_0); } }

@media all and (min-width: 768px) {
  .ms\:top_initial {
    top: initial; }
  .ms\:right_initial {
    right: initial; }
  .ms\:bottom_initial {
    bottom: initial; }
  .ms\:left_initial {
    left: initial; }
  .ms\:top_10 {
    top: 10%; }
  .ms\:right_10 {
    right: 10%; }
  .ms\:bottom_10 {
    bottom: 10%; }
  .ms\:left_10 {
    left: 10%; }
  .ms\:top_20 {
    top: 20%; }
  .ms\:right_20 {
    right: 20%; }
  .ms\:bottom_20 {
    bottom: 20%; }
  .ms\:left_20 {
    left: 20%; }
  .ms\:top_30 {
    top: 30%; }
  .ms\:right_30 {
    right: 30%; }
  .ms\:bottom_30 {
    bottom: 30%; }
  .ms\:left_30 {
    left: 30%; }
  .ms\:top_40 {
    top: 40%; }
  .ms\:right_40 {
    right: 40%; }
  .ms\:bottom_40 {
    bottom: 40%; }
  .ms\:left_40 {
    left: 40%; }
  .ms\:top_50 {
    top: 50%; }
  .ms\:right_50 {
    right: 50%; }
  .ms\:bottom_50 {
    bottom: 50%; }
  .ms\:left_50 {
    left: 50%; }
  .ms\:top_60 {
    top: 60%; }
  .ms\:right_60 {
    right: 60%; }
  .ms\:bottom_60 {
    bottom: 60%; }
  .ms\:left_60 {
    left: 60%; }
  .ms\:top_70 {
    top: 70%; }
  .ms\:right_70 {
    right: 70%; }
  .ms\:bottom_70 {
    bottom: 70%; }
  .ms\:left_70 {
    left: 70%; }
  .ms\:top_80 {
    top: 80%; }
  .ms\:right_80 {
    right: 80%; }
  .ms\:bottom_80 {
    bottom: 80%; }
  .ms\:left_80 {
    left: 80%; }
  .ms\:top_90 {
    top: 90%; }
  .ms\:right_90 {
    right: 90%; }
  .ms\:bottom_90 {
    bottom: 90%; }
  .ms\:left_90 {
    left: 90%; }
  .ms\:top_100 {
    top: 100%; }
  .ms\:right_100 {
    right: 100%; }
  .ms\:bottom_100 {
    bottom: 100%; }
  .ms\:left_100 {
    left: 100%; }
  .ms\:top_auto {
    top: var(--space_auto); }
  .ms\:p_top_auto {
    padding-top: var(--space_auto); }
  .ms\:m_top_auto {
    margin-top: var(--space_auto); }
  .ms\:right_auto {
    right: var(--space_auto); }
  .ms\:p_right_auto {
    padding-right: var(--space_auto); }
  .ms\:m_right_auto {
    margin-right: var(--space_auto); }
  .ms\:bottom_auto {
    bottom: var(--space_auto); }
  .ms\:p_bottom_auto {
    padding-bottom: var(--space_auto); }
  .ms\:m_bottom_auto {
    margin-bottom: var(--space_auto); }
  .ms\:left_auto {
    left: var(--space_auto); }
  .ms\:p_left_auto {
    padding-left: var(--space_auto); }
  .ms\:m_left_auto {
    margin-left: var(--space_auto); }
  .ms\:p_auto {
    padding: var(--space_auto); }
  .ms\:p_x_auto {
    padding-right: var(--space_auto);
    padding-left: var(--space_auto); }
  .ms\:p_y_auto {
    padding-top: var(--space_auto);
    padding-bottom: var(--space_auto); }
  .ms\:m_auto {
    margin: var(--space_auto); }
  .ms\:m_x_auto {
    margin-right: var(--space_auto);
    margin-left: var(--space_auto); }
  .ms\:m_y_auto {
    margin-top: var(--space_auto);
    margin-bottom: var(--space_auto); }
  .ms\:g_auto {
    grid-gap: var(--space_auto); }
  .ms\:g_x_auto {
    column-gap: var(--space_auto); }
  .ms\:g_y_auto {
    row-gap: var(--space_auto); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_ss {
    top: var(--space_ss); }
  .m\:p_top_ss {
    padding-top: var(--space_ss); }
  .m\:m_top_ss {
    margin-top: var(--space_ss); }
  .m\:right_ss {
    right: var(--space_ss); }
  .m\:p_right_ss {
    padding-right: var(--space_ss); }
  .m\:m_right_ss {
    margin-right: var(--space_ss); }
  .m\:bottom_ss {
    bottom: var(--space_ss); }
  .m\:p_bottom_ss {
    padding-bottom: var(--space_ss); }
  .m\:m_bottom_ss {
    margin-bottom: var(--space_ss); }
  .m\:left_ss {
    left: var(--space_ss); }
  .m\:p_left_ss {
    padding-left: var(--space_ss); }
  .m\:m_left_ss {
    margin-left: var(--space_ss); }
  .m\:p_ss {
    padding: var(--space_ss); }
  .m\:p_x_ss {
    padding-right: var(--space_ss);
    padding-left: var(--space_ss); }
  .m\:p_y_ss {
    padding-top: var(--space_ss);
    padding-bottom: var(--space_ss); }
  .m\:m_ss {
    margin: var(--space_ss); }
  .m\:m_x_ss {
    margin-right: var(--space_ss);
    margin-left: var(--space_ss); }
  .m\:m_y_ss {
    margin-top: var(--space_ss);
    margin-bottom: var(--space_ss); }
  .m\:g_ss {
    grid-gap: var(--space_ss); }
  .m\:g_x_ss {
    column-gap: var(--space_ss); }
  .m\:g_y_ss {
    row-gap: var(--space_ss); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_sm {
    top: var(--space_sm); }
  .m\:p_top_sm {
    padding-top: var(--space_sm); }
  .m\:m_top_sm {
    margin-top: var(--space_sm); }
  .m\:right_sm {
    right: var(--space_sm); }
  .m\:p_right_sm {
    padding-right: var(--space_sm); }
  .m\:m_right_sm {
    margin-right: var(--space_sm); }
  .m\:bottom_sm {
    bottom: var(--space_sm); }
  .m\:p_bottom_sm {
    padding-bottom: var(--space_sm); }
  .m\:m_bottom_sm {
    margin-bottom: var(--space_sm); }
  .m\:left_sm {
    left: var(--space_sm); }
  .m\:p_left_sm {
    padding-left: var(--space_sm); }
  .m\:m_left_sm {
    margin-left: var(--space_sm); }
  .m\:p_sm {
    padding: var(--space_sm); }
  .m\:p_x_sm {
    padding-right: var(--space_sm);
    padding-left: var(--space_sm); }
  .m\:p_y_sm {
    padding-top: var(--space_sm);
    padding-bottom: var(--space_sm); }
  .m\:m_sm {
    margin: var(--space_sm); }
  .m\:m_x_sm {
    margin-right: var(--space_sm);
    margin-left: var(--space_sm); }
  .m\:m_y_sm {
    margin-top: var(--space_sm);
    margin-bottom: var(--space_sm); }
  .m\:g_sm {
    grid-gap: var(--space_sm); }
  .m\:g_x_sm {
    column-gap: var(--space_sm); }
  .m\:g_y_sm {
    row-gap: var(--space_sm); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_base {
    top: var(--space_base); }
  .m\:p_top_base {
    padding-top: var(--space_base); }
  .m\:m_top_base {
    margin-top: var(--space_base); }
  .m\:right_base {
    right: var(--space_base); }
  .m\:p_right_base {
    padding-right: var(--space_base); }
  .m\:m_right_base {
    margin-right: var(--space_base); }
  .m\:bottom_base {
    bottom: var(--space_base); }
  .m\:p_bottom_base {
    padding-bottom: var(--space_base); }
  .m\:m_bottom_base {
    margin-bottom: var(--space_base); }
  .m\:left_base {
    left: var(--space_base); }
  .m\:p_left_base {
    padding-left: var(--space_base); }
  .m\:m_left_base {
    margin-left: var(--space_base); }
  .m\:p_base {
    padding: var(--space_base); }
  .m\:p_x_base {
    padding-right: var(--space_base);
    padding-left: var(--space_base); }
  .m\:p_y_base {
    padding-top: var(--space_base);
    padding-bottom: var(--space_base); }
  .m\:m_base {
    margin: var(--space_base); }
  .m\:m_x_base {
    margin-right: var(--space_base);
    margin-left: var(--space_base); }
  .m\:m_y_base {
    margin-top: var(--space_base);
    margin-bottom: var(--space_base); }
  .m\:g_base {
    grid-gap: var(--space_base); }
  .m\:g_x_base {
    column-gap: var(--space_base); }
  .m\:g_y_base {
    row-gap: var(--space_base); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_me {
    top: var(--space_me); }
  .m\:p_top_me {
    padding-top: var(--space_me); }
  .m\:m_top_me {
    margin-top: var(--space_me); }
  .m\:right_me {
    right: var(--space_me); }
  .m\:p_right_me {
    padding-right: var(--space_me); }
  .m\:m_right_me {
    margin-right: var(--space_me); }
  .m\:bottom_me {
    bottom: var(--space_me); }
  .m\:p_bottom_me {
    padding-bottom: var(--space_me); }
  .m\:m_bottom_me {
    margin-bottom: var(--space_me); }
  .m\:left_me {
    left: var(--space_me); }
  .m\:p_left_me {
    padding-left: var(--space_me); }
  .m\:m_left_me {
    margin-left: var(--space_me); }
  .m\:p_me {
    padding: var(--space_me); }
  .m\:p_x_me {
    padding-right: var(--space_me);
    padding-left: var(--space_me); }
  .m\:p_y_me {
    padding-top: var(--space_me);
    padding-bottom: var(--space_me); }
  .m\:m_me {
    margin: var(--space_me); }
  .m\:m_x_me {
    margin-right: var(--space_me);
    margin-left: var(--space_me); }
  .m\:m_y_me {
    margin-top: var(--space_me);
    margin-bottom: var(--space_me); }
  .m\:g_me {
    grid-gap: var(--space_me); }
  .m\:g_x_me {
    column-gap: var(--space_me); }
  .m\:g_y_me {
    row-gap: var(--space_me); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_la {
    top: var(--space_la); }
  .m\:p_top_la {
    padding-top: var(--space_la); }
  .m\:m_top_la {
    margin-top: var(--space_la); }
  .m\:right_la {
    right: var(--space_la); }
  .m\:p_right_la {
    padding-right: var(--space_la); }
  .m\:m_right_la {
    margin-right: var(--space_la); }
  .m\:bottom_la {
    bottom: var(--space_la); }
  .m\:p_bottom_la {
    padding-bottom: var(--space_la); }
  .m\:m_bottom_la {
    margin-bottom: var(--space_la); }
  .m\:left_la {
    left: var(--space_la); }
  .m\:p_left_la {
    padding-left: var(--space_la); }
  .m\:m_left_la {
    margin-left: var(--space_la); }
  .m\:p_la {
    padding: var(--space_la); }
  .m\:p_x_la {
    padding-right: var(--space_la);
    padding-left: var(--space_la); }
  .m\:p_y_la {
    padding-top: var(--space_la);
    padding-bottom: var(--space_la); }
  .m\:m_la {
    margin: var(--space_la); }
  .m\:m_x_la {
    margin-right: var(--space_la);
    margin-left: var(--space_la); }
  .m\:m_y_la {
    margin-top: var(--space_la);
    margin-bottom: var(--space_la); }
  .m\:g_la {
    grid-gap: var(--space_la); }
  .m\:g_x_la {
    column-gap: var(--space_la); }
  .m\:g_y_la {
    row-gap: var(--space_la); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_2la {
    top: var(--space_2la); }
  .m\:p_top_2la {
    padding-top: var(--space_2la); }
  .m\:m_top_2la {
    margin-top: var(--space_2la); }
  .m\:right_2la {
    right: var(--space_2la); }
  .m\:p_right_2la {
    padding-right: var(--space_2la); }
  .m\:m_right_2la {
    margin-right: var(--space_2la); }
  .m\:bottom_2la {
    bottom: var(--space_2la); }
  .m\:p_bottom_2la {
    padding-bottom: var(--space_2la); }
  .m\:m_bottom_2la {
    margin-bottom: var(--space_2la); }
  .m\:left_2la {
    left: var(--space_2la); }
  .m\:p_left_2la {
    padding-left: var(--space_2la); }
  .m\:m_left_2la {
    margin-left: var(--space_2la); }
  .m\:p_2la {
    padding: var(--space_2la); }
  .m\:p_x_2la {
    padding-right: var(--space_2la);
    padding-left: var(--space_2la); }
  .m\:p_y_2la {
    padding-top: var(--space_2la);
    padding-bottom: var(--space_2la); }
  .m\:m_2la {
    margin: var(--space_2la); }
  .m\:m_x_2la {
    margin-right: var(--space_2la);
    margin-left: var(--space_2la); }
  .m\:m_y_2la {
    margin-top: var(--space_2la);
    margin-bottom: var(--space_2la); }
  .m\:g_2la {
    grid-gap: var(--space_2la); }
  .m\:g_x_2la {
    column-gap: var(--space_2la); }
  .m\:g_y_2la {
    row-gap: var(--space_2la); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_xl {
    top: var(--space_xl); }
  .m\:p_top_xl {
    padding-top: var(--space_xl); }
  .m\:m_top_xl {
    margin-top: var(--space_xl); }
  .m\:right_xl {
    right: var(--space_xl); }
  .m\:p_right_xl {
    padding-right: var(--space_xl); }
  .m\:m_right_xl {
    margin-right: var(--space_xl); }
  .m\:bottom_xl {
    bottom: var(--space_xl); }
  .m\:p_bottom_xl {
    padding-bottom: var(--space_xl); }
  .m\:m_bottom_xl {
    margin-bottom: var(--space_xl); }
  .m\:left_xl {
    left: var(--space_xl); }
  .m\:p_left_xl {
    padding-left: var(--space_xl); }
  .m\:m_left_xl {
    margin-left: var(--space_xl); }
  .m\:p_xl {
    padding: var(--space_xl); }
  .m\:p_x_xl {
    padding-right: var(--space_xl);
    padding-left: var(--space_xl); }
  .m\:p_y_xl {
    padding-top: var(--space_xl);
    padding-bottom: var(--space_xl); }
  .m\:m_xl {
    margin: var(--space_xl); }
  .m\:m_x_xl {
    margin-right: var(--space_xl);
    margin-left: var(--space_xl); }
  .m\:m_y_xl {
    margin-top: var(--space_xl);
    margin-bottom: var(--space_xl); }
  .m\:g_xl {
    grid-gap: var(--space_xl); }
  .m\:g_x_xl {
    column-gap: var(--space_xl); }
  .m\:g_y_xl {
    row-gap: var(--space_xl); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_2xl {
    top: var(--space_2xl); }
  .m\:p_top_2xl {
    padding-top: var(--space_2xl); }
  .m\:m_top_2xl {
    margin-top: var(--space_2xl); }
  .m\:right_2xl {
    right: var(--space_2xl); }
  .m\:p_right_2xl {
    padding-right: var(--space_2xl); }
  .m\:m_right_2xl {
    margin-right: var(--space_2xl); }
  .m\:bottom_2xl {
    bottom: var(--space_2xl); }
  .m\:p_bottom_2xl {
    padding-bottom: var(--space_2xl); }
  .m\:m_bottom_2xl {
    margin-bottom: var(--space_2xl); }
  .m\:left_2xl {
    left: var(--space_2xl); }
  .m\:p_left_2xl {
    padding-left: var(--space_2xl); }
  .m\:m_left_2xl {
    margin-left: var(--space_2xl); }
  .m\:p_2xl {
    padding: var(--space_2xl); }
  .m\:p_x_2xl {
    padding-right: var(--space_2xl);
    padding-left: var(--space_2xl); }
  .m\:p_y_2xl {
    padding-top: var(--space_2xl);
    padding-bottom: var(--space_2xl); }
  .m\:m_2xl {
    margin: var(--space_2xl); }
  .m\:m_x_2xl {
    margin-right: var(--space_2xl);
    margin-left: var(--space_2xl); }
  .m\:m_y_2xl {
    margin-top: var(--space_2xl);
    margin-bottom: var(--space_2xl); }
  .m\:g_2xl {
    grid-gap: var(--space_2xl); }
  .m\:g_x_2xl {
    column-gap: var(--space_2xl); }
  .m\:g_y_2xl {
    row-gap: var(--space_2xl); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_ma {
    top: var(--space_ma); }
  .m\:p_top_ma {
    padding-top: var(--space_ma); }
  .m\:m_top_ma {
    margin-top: var(--space_ma); }
  .m\:right_ma {
    right: var(--space_ma); }
  .m\:p_right_ma {
    padding-right: var(--space_ma); }
  .m\:m_right_ma {
    margin-right: var(--space_ma); }
  .m\:bottom_ma {
    bottom: var(--space_ma); }
  .m\:p_bottom_ma {
    padding-bottom: var(--space_ma); }
  .m\:m_bottom_ma {
    margin-bottom: var(--space_ma); }
  .m\:left_ma {
    left: var(--space_ma); }
  .m\:p_left_ma {
    padding-left: var(--space_ma); }
  .m\:m_left_ma {
    margin-left: var(--space_ma); }
  .m\:p_ma {
    padding: var(--space_ma); }
  .m\:p_x_ma {
    padding-right: var(--space_ma);
    padding-left: var(--space_ma); }
  .m\:p_y_ma {
    padding-top: var(--space_ma);
    padding-bottom: var(--space_ma); }
  .m\:m_ma {
    margin: var(--space_ma); }
  .m\:m_x_ma {
    margin-right: var(--space_ma);
    margin-left: var(--space_ma); }
  .m\:m_y_ma {
    margin-top: var(--space_ma);
    margin-bottom: var(--space_ma); }
  .m\:g_ma {
    grid-gap: var(--space_ma); }
  .m\:g_x_ma {
    column-gap: var(--space_ma); }
  .m\:g_y_ma {
    row-gap: var(--space_ma); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_2ma {
    top: var(--space_2ma); }
  .m\:p_top_2ma {
    padding-top: var(--space_2ma); }
  .m\:m_top_2ma {
    margin-top: var(--space_2ma); }
  .m\:right_2ma {
    right: var(--space_2ma); }
  .m\:p_right_2ma {
    padding-right: var(--space_2ma); }
  .m\:m_right_2ma {
    margin-right: var(--space_2ma); }
  .m\:bottom_2ma {
    bottom: var(--space_2ma); }
  .m\:p_bottom_2ma {
    padding-bottom: var(--space_2ma); }
  .m\:m_bottom_2ma {
    margin-bottom: var(--space_2ma); }
  .m\:left_2ma {
    left: var(--space_2ma); }
  .m\:p_left_2ma {
    padding-left: var(--space_2ma); }
  .m\:m_left_2ma {
    margin-left: var(--space_2ma); }
  .m\:p_2ma {
    padding: var(--space_2ma); }
  .m\:p_x_2ma {
    padding-right: var(--space_2ma);
    padding-left: var(--space_2ma); }
  .m\:p_y_2ma {
    padding-top: var(--space_2ma);
    padding-bottom: var(--space_2ma); }
  .m\:m_2ma {
    margin: var(--space_2ma); }
  .m\:m_x_2ma {
    margin-right: var(--space_2ma);
    margin-left: var(--space_2ma); }
  .m\:m_y_2ma {
    margin-top: var(--space_2ma);
    margin-bottom: var(--space_2ma); }
  .m\:g_2ma {
    grid-gap: var(--space_2ma); }
  .m\:g_x_2ma {
    column-gap: var(--space_2ma); }
  .m\:g_y_2ma {
    row-gap: var(--space_2ma); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_ss_indent {
    top: var(--space_ss_indent); }
  .m\:p_top_ss_indent {
    padding-top: var(--space_ss_indent); }
  .m\:m_top_ss_indent {
    margin-top: var(--space_ss_indent); }
  .m\:right_ss_indent {
    right: var(--space_ss_indent); }
  .m\:p_right_ss_indent {
    padding-right: var(--space_ss_indent); }
  .m\:m_right_ss_indent {
    margin-right: var(--space_ss_indent); }
  .m\:bottom_ss_indent {
    bottom: var(--space_ss_indent); }
  .m\:p_bottom_ss_indent {
    padding-bottom: var(--space_ss_indent); }
  .m\:m_bottom_ss_indent {
    margin-bottom: var(--space_ss_indent); }
  .m\:left_ss_indent {
    left: var(--space_ss_indent); }
  .m\:p_left_ss_indent {
    padding-left: var(--space_ss_indent); }
  .m\:m_left_ss_indent {
    margin-left: var(--space_ss_indent); }
  .m\:p_ss_indent {
    padding: var(--space_ss_indent); }
  .m\:p_x_ss_indent {
    padding-right: var(--space_ss_indent);
    padding-left: var(--space_ss_indent); }
  .m\:p_y_ss_indent {
    padding-top: var(--space_ss_indent);
    padding-bottom: var(--space_ss_indent); }
  .m\:m_ss_indent {
    margin: var(--space_ss_indent); }
  .m\:m_x_ss_indent {
    margin-right: var(--space_ss_indent);
    margin-left: var(--space_ss_indent); }
  .m\:m_y_ss_indent {
    margin-top: var(--space_ss_indent);
    margin-bottom: var(--space_ss_indent); }
  .m\:g_ss_indent {
    grid-gap: var(--space_ss_indent); }
  .m\:g_x_ss_indent {
    column-gap: var(--space_ss_indent); }
  .m\:g_y_ss_indent {
    row-gap: var(--space_ss_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_sm_indent {
    top: var(--space_sm_indent); }
  .m\:p_top_sm_indent {
    padding-top: var(--space_sm_indent); }
  .m\:m_top_sm_indent {
    margin-top: var(--space_sm_indent); }
  .m\:right_sm_indent {
    right: var(--space_sm_indent); }
  .m\:p_right_sm_indent {
    padding-right: var(--space_sm_indent); }
  .m\:m_right_sm_indent {
    margin-right: var(--space_sm_indent); }
  .m\:bottom_sm_indent {
    bottom: var(--space_sm_indent); }
  .m\:p_bottom_sm_indent {
    padding-bottom: var(--space_sm_indent); }
  .m\:m_bottom_sm_indent {
    margin-bottom: var(--space_sm_indent); }
  .m\:left_sm_indent {
    left: var(--space_sm_indent); }
  .m\:p_left_sm_indent {
    padding-left: var(--space_sm_indent); }
  .m\:m_left_sm_indent {
    margin-left: var(--space_sm_indent); }
  .m\:p_sm_indent {
    padding: var(--space_sm_indent); }
  .m\:p_x_sm_indent {
    padding-right: var(--space_sm_indent);
    padding-left: var(--space_sm_indent); }
  .m\:p_y_sm_indent {
    padding-top: var(--space_sm_indent);
    padding-bottom: var(--space_sm_indent); }
  .m\:m_sm_indent {
    margin: var(--space_sm_indent); }
  .m\:m_x_sm_indent {
    margin-right: var(--space_sm_indent);
    margin-left: var(--space_sm_indent); }
  .m\:m_y_sm_indent {
    margin-top: var(--space_sm_indent);
    margin-bottom: var(--space_sm_indent); }
  .m\:g_sm_indent {
    grid-gap: var(--space_sm_indent); }
  .m\:g_x_sm_indent {
    column-gap: var(--space_sm_indent); }
  .m\:g_y_sm_indent {
    row-gap: var(--space_sm_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_base_indent {
    top: var(--space_base_indent); }
  .m\:p_top_base_indent {
    padding-top: var(--space_base_indent); }
  .m\:m_top_base_indent {
    margin-top: var(--space_base_indent); }
  .m\:right_base_indent {
    right: var(--space_base_indent); }
  .m\:p_right_base_indent {
    padding-right: var(--space_base_indent); }
  .m\:m_right_base_indent {
    margin-right: var(--space_base_indent); }
  .m\:bottom_base_indent {
    bottom: var(--space_base_indent); }
  .m\:p_bottom_base_indent {
    padding-bottom: var(--space_base_indent); }
  .m\:m_bottom_base_indent {
    margin-bottom: var(--space_base_indent); }
  .m\:left_base_indent {
    left: var(--space_base_indent); }
  .m\:p_left_base_indent {
    padding-left: var(--space_base_indent); }
  .m\:m_left_base_indent {
    margin-left: var(--space_base_indent); }
  .m\:p_base_indent {
    padding: var(--space_base_indent); }
  .m\:p_x_base_indent {
    padding-right: var(--space_base_indent);
    padding-left: var(--space_base_indent); }
  .m\:p_y_base_indent {
    padding-top: var(--space_base_indent);
    padding-bottom: var(--space_base_indent); }
  .m\:m_base_indent {
    margin: var(--space_base_indent); }
  .m\:m_x_base_indent {
    margin-right: var(--space_base_indent);
    margin-left: var(--space_base_indent); }
  .m\:m_y_base_indent {
    margin-top: var(--space_base_indent);
    margin-bottom: var(--space_base_indent); }
  .m\:g_base_indent {
    grid-gap: var(--space_base_indent); }
  .m\:g_x_base_indent {
    column-gap: var(--space_base_indent); }
  .m\:g_y_base_indent {
    row-gap: var(--space_base_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_me_indent {
    top: var(--space_me_indent); }
  .m\:p_top_me_indent {
    padding-top: var(--space_me_indent); }
  .m\:m_top_me_indent {
    margin-top: var(--space_me_indent); }
  .m\:right_me_indent {
    right: var(--space_me_indent); }
  .m\:p_right_me_indent {
    padding-right: var(--space_me_indent); }
  .m\:m_right_me_indent {
    margin-right: var(--space_me_indent); }
  .m\:bottom_me_indent {
    bottom: var(--space_me_indent); }
  .m\:p_bottom_me_indent {
    padding-bottom: var(--space_me_indent); }
  .m\:m_bottom_me_indent {
    margin-bottom: var(--space_me_indent); }
  .m\:left_me_indent {
    left: var(--space_me_indent); }
  .m\:p_left_me_indent {
    padding-left: var(--space_me_indent); }
  .m\:m_left_me_indent {
    margin-left: var(--space_me_indent); }
  .m\:p_me_indent {
    padding: var(--space_me_indent); }
  .m\:p_x_me_indent {
    padding-right: var(--space_me_indent);
    padding-left: var(--space_me_indent); }
  .m\:p_y_me_indent {
    padding-top: var(--space_me_indent);
    padding-bottom: var(--space_me_indent); }
  .m\:m_me_indent {
    margin: var(--space_me_indent); }
  .m\:m_x_me_indent {
    margin-right: var(--space_me_indent);
    margin-left: var(--space_me_indent); }
  .m\:m_y_me_indent {
    margin-top: var(--space_me_indent);
    margin-bottom: var(--space_me_indent); }
  .m\:g_me_indent {
    grid-gap: var(--space_me_indent); }
  .m\:g_x_me_indent {
    column-gap: var(--space_me_indent); }
  .m\:g_y_me_indent {
    row-gap: var(--space_me_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_la_indent {
    top: var(--space_la_indent); }
  .m\:p_top_la_indent {
    padding-top: var(--space_la_indent); }
  .m\:m_top_la_indent {
    margin-top: var(--space_la_indent); }
  .m\:right_la_indent {
    right: var(--space_la_indent); }
  .m\:p_right_la_indent {
    padding-right: var(--space_la_indent); }
  .m\:m_right_la_indent {
    margin-right: var(--space_la_indent); }
  .m\:bottom_la_indent {
    bottom: var(--space_la_indent); }
  .m\:p_bottom_la_indent {
    padding-bottom: var(--space_la_indent); }
  .m\:m_bottom_la_indent {
    margin-bottom: var(--space_la_indent); }
  .m\:left_la_indent {
    left: var(--space_la_indent); }
  .m\:p_left_la_indent {
    padding-left: var(--space_la_indent); }
  .m\:m_left_la_indent {
    margin-left: var(--space_la_indent); }
  .m\:p_la_indent {
    padding: var(--space_la_indent); }
  .m\:p_x_la_indent {
    padding-right: var(--space_la_indent);
    padding-left: var(--space_la_indent); }
  .m\:p_y_la_indent {
    padding-top: var(--space_la_indent);
    padding-bottom: var(--space_la_indent); }
  .m\:m_la_indent {
    margin: var(--space_la_indent); }
  .m\:m_x_la_indent {
    margin-right: var(--space_la_indent);
    margin-left: var(--space_la_indent); }
  .m\:m_y_la_indent {
    margin-top: var(--space_la_indent);
    margin-bottom: var(--space_la_indent); }
  .m\:g_la_indent {
    grid-gap: var(--space_la_indent); }
  .m\:g_x_la_indent {
    column-gap: var(--space_la_indent); }
  .m\:g_y_la_indent {
    row-gap: var(--space_la_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_2la_indent {
    top: var(--space_2la_indent); }
  .m\:p_top_2la_indent {
    padding-top: var(--space_2la_indent); }
  .m\:m_top_2la_indent {
    margin-top: var(--space_2la_indent); }
  .m\:right_2la_indent {
    right: var(--space_2la_indent); }
  .m\:p_right_2la_indent {
    padding-right: var(--space_2la_indent); }
  .m\:m_right_2la_indent {
    margin-right: var(--space_2la_indent); }
  .m\:bottom_2la_indent {
    bottom: var(--space_2la_indent); }
  .m\:p_bottom_2la_indent {
    padding-bottom: var(--space_2la_indent); }
  .m\:m_bottom_2la_indent {
    margin-bottom: var(--space_2la_indent); }
  .m\:left_2la_indent {
    left: var(--space_2la_indent); }
  .m\:p_left_2la_indent {
    padding-left: var(--space_2la_indent); }
  .m\:m_left_2la_indent {
    margin-left: var(--space_2la_indent); }
  .m\:p_2la_indent {
    padding: var(--space_2la_indent); }
  .m\:p_x_2la_indent {
    padding-right: var(--space_2la_indent);
    padding-left: var(--space_2la_indent); }
  .m\:p_y_2la_indent {
    padding-top: var(--space_2la_indent);
    padding-bottom: var(--space_2la_indent); }
  .m\:m_2la_indent {
    margin: var(--space_2la_indent); }
  .m\:m_x_2la_indent {
    margin-right: var(--space_2la_indent);
    margin-left: var(--space_2la_indent); }
  .m\:m_y_2la_indent {
    margin-top: var(--space_2la_indent);
    margin-bottom: var(--space_2la_indent); }
  .m\:g_2la_indent {
    grid-gap: var(--space_2la_indent); }
  .m\:g_x_2la_indent {
    column-gap: var(--space_2la_indent); }
  .m\:g_y_2la_indent {
    row-gap: var(--space_2la_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_xl_indent {
    top: var(--space_xl_indent); }
  .m\:p_top_xl_indent {
    padding-top: var(--space_xl_indent); }
  .m\:m_top_xl_indent {
    margin-top: var(--space_xl_indent); }
  .m\:right_xl_indent {
    right: var(--space_xl_indent); }
  .m\:p_right_xl_indent {
    padding-right: var(--space_xl_indent); }
  .m\:m_right_xl_indent {
    margin-right: var(--space_xl_indent); }
  .m\:bottom_xl_indent {
    bottom: var(--space_xl_indent); }
  .m\:p_bottom_xl_indent {
    padding-bottom: var(--space_xl_indent); }
  .m\:m_bottom_xl_indent {
    margin-bottom: var(--space_xl_indent); }
  .m\:left_xl_indent {
    left: var(--space_xl_indent); }
  .m\:p_left_xl_indent {
    padding-left: var(--space_xl_indent); }
  .m\:m_left_xl_indent {
    margin-left: var(--space_xl_indent); }
  .m\:p_xl_indent {
    padding: var(--space_xl_indent); }
  .m\:p_x_xl_indent {
    padding-right: var(--space_xl_indent);
    padding-left: var(--space_xl_indent); }
  .m\:p_y_xl_indent {
    padding-top: var(--space_xl_indent);
    padding-bottom: var(--space_xl_indent); }
  .m\:m_xl_indent {
    margin: var(--space_xl_indent); }
  .m\:m_x_xl_indent {
    margin-right: var(--space_xl_indent);
    margin-left: var(--space_xl_indent); }
  .m\:m_y_xl_indent {
    margin-top: var(--space_xl_indent);
    margin-bottom: var(--space_xl_indent); }
  .m\:g_xl_indent {
    grid-gap: var(--space_xl_indent); }
  .m\:g_x_xl_indent {
    column-gap: var(--space_xl_indent); }
  .m\:g_y_xl_indent {
    row-gap: var(--space_xl_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_2xl_indent {
    top: var(--space_2xl_indent); }
  .m\:p_top_2xl_indent {
    padding-top: var(--space_2xl_indent); }
  .m\:m_top_2xl_indent {
    margin-top: var(--space_2xl_indent); }
  .m\:right_2xl_indent {
    right: var(--space_2xl_indent); }
  .m\:p_right_2xl_indent {
    padding-right: var(--space_2xl_indent); }
  .m\:m_right_2xl_indent {
    margin-right: var(--space_2xl_indent); }
  .m\:bottom_2xl_indent {
    bottom: var(--space_2xl_indent); }
  .m\:p_bottom_2xl_indent {
    padding-bottom: var(--space_2xl_indent); }
  .m\:m_bottom_2xl_indent {
    margin-bottom: var(--space_2xl_indent); }
  .m\:left_2xl_indent {
    left: var(--space_2xl_indent); }
  .m\:p_left_2xl_indent {
    padding-left: var(--space_2xl_indent); }
  .m\:m_left_2xl_indent {
    margin-left: var(--space_2xl_indent); }
  .m\:p_2xl_indent {
    padding: var(--space_2xl_indent); }
  .m\:p_x_2xl_indent {
    padding-right: var(--space_2xl_indent);
    padding-left: var(--space_2xl_indent); }
  .m\:p_y_2xl_indent {
    padding-top: var(--space_2xl_indent);
    padding-bottom: var(--space_2xl_indent); }
  .m\:m_2xl_indent {
    margin: var(--space_2xl_indent); }
  .m\:m_x_2xl_indent {
    margin-right: var(--space_2xl_indent);
    margin-left: var(--space_2xl_indent); }
  .m\:m_y_2xl_indent {
    margin-top: var(--space_2xl_indent);
    margin-bottom: var(--space_2xl_indent); }
  .m\:g_2xl_indent {
    grid-gap: var(--space_2xl_indent); }
  .m\:g_x_2xl_indent {
    column-gap: var(--space_2xl_indent); }
  .m\:g_y_2xl_indent {
    row-gap: var(--space_2xl_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_ma_indent {
    top: var(--space_ma_indent); }
  .m\:p_top_ma_indent {
    padding-top: var(--space_ma_indent); }
  .m\:m_top_ma_indent {
    margin-top: var(--space_ma_indent); }
  .m\:right_ma_indent {
    right: var(--space_ma_indent); }
  .m\:p_right_ma_indent {
    padding-right: var(--space_ma_indent); }
  .m\:m_right_ma_indent {
    margin-right: var(--space_ma_indent); }
  .m\:bottom_ma_indent {
    bottom: var(--space_ma_indent); }
  .m\:p_bottom_ma_indent {
    padding-bottom: var(--space_ma_indent); }
  .m\:m_bottom_ma_indent {
    margin-bottom: var(--space_ma_indent); }
  .m\:left_ma_indent {
    left: var(--space_ma_indent); }
  .m\:p_left_ma_indent {
    padding-left: var(--space_ma_indent); }
  .m\:m_left_ma_indent {
    margin-left: var(--space_ma_indent); }
  .m\:p_ma_indent {
    padding: var(--space_ma_indent); }
  .m\:p_x_ma_indent {
    padding-right: var(--space_ma_indent);
    padding-left: var(--space_ma_indent); }
  .m\:p_y_ma_indent {
    padding-top: var(--space_ma_indent);
    padding-bottom: var(--space_ma_indent); }
  .m\:m_ma_indent {
    margin: var(--space_ma_indent); }
  .m\:m_x_ma_indent {
    margin-right: var(--space_ma_indent);
    margin-left: var(--space_ma_indent); }
  .m\:m_y_ma_indent {
    margin-top: var(--space_ma_indent);
    margin-bottom: var(--space_ma_indent); }
  .m\:g_ma_indent {
    grid-gap: var(--space_ma_indent); }
  .m\:g_x_ma_indent {
    column-gap: var(--space_ma_indent); }
  .m\:g_y_ma_indent {
    row-gap: var(--space_ma_indent); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_0 {
    top: var(--space_0); }
  .m\:p_top_0 {
    padding-top: var(--space_0); }
  .m\:m_top_0 {
    margin-top: var(--space_0); }
  .m\:right_0 {
    right: var(--space_0); }
  .m\:p_right_0 {
    padding-right: var(--space_0); }
  .m\:m_right_0 {
    margin-right: var(--space_0); }
  .m\:bottom_0 {
    bottom: var(--space_0); }
  .m\:p_bottom_0 {
    padding-bottom: var(--space_0); }
  .m\:m_bottom_0 {
    margin-bottom: var(--space_0); }
  .m\:left_0 {
    left: var(--space_0); }
  .m\:p_left_0 {
    padding-left: var(--space_0); }
  .m\:m_left_0 {
    margin-left: var(--space_0); }
  .m\:p_0 {
    padding: var(--space_0); }
  .m\:p_x_0 {
    padding-right: var(--space_0);
    padding-left: var(--space_0); }
  .m\:p_y_0 {
    padding-top: var(--space_0);
    padding-bottom: var(--space_0); }
  .m\:m_0 {
    margin: var(--space_0); }
  .m\:m_x_0 {
    margin-right: var(--space_0);
    margin-left: var(--space_0); }
  .m\:m_y_0 {
    margin-top: var(--space_0);
    margin-bottom: var(--space_0); }
  .m\:g_0 {
    grid-gap: var(--space_0); }
  .m\:g_x_0 {
    column-gap: var(--space_0); }
  .m\:g_y_0 {
    row-gap: var(--space_0); } }

@media all and (min-width: 960px) {
  .m\:top_initial {
    top: initial; }
  .m\:right_initial {
    right: initial; }
  .m\:bottom_initial {
    bottom: initial; }
  .m\:left_initial {
    left: initial; }
  .m\:top_10 {
    top: 10%; }
  .m\:right_10 {
    right: 10%; }
  .m\:bottom_10 {
    bottom: 10%; }
  .m\:left_10 {
    left: 10%; }
  .m\:top_20 {
    top: 20%; }
  .m\:right_20 {
    right: 20%; }
  .m\:bottom_20 {
    bottom: 20%; }
  .m\:left_20 {
    left: 20%; }
  .m\:top_30 {
    top: 30%; }
  .m\:right_30 {
    right: 30%; }
  .m\:bottom_30 {
    bottom: 30%; }
  .m\:left_30 {
    left: 30%; }
  .m\:top_40 {
    top: 40%; }
  .m\:right_40 {
    right: 40%; }
  .m\:bottom_40 {
    bottom: 40%; }
  .m\:left_40 {
    left: 40%; }
  .m\:top_50 {
    top: 50%; }
  .m\:right_50 {
    right: 50%; }
  .m\:bottom_50 {
    bottom: 50%; }
  .m\:left_50 {
    left: 50%; }
  .m\:top_60 {
    top: 60%; }
  .m\:right_60 {
    right: 60%; }
  .m\:bottom_60 {
    bottom: 60%; }
  .m\:left_60 {
    left: 60%; }
  .m\:top_70 {
    top: 70%; }
  .m\:right_70 {
    right: 70%; }
  .m\:bottom_70 {
    bottom: 70%; }
  .m\:left_70 {
    left: 70%; }
  .m\:top_80 {
    top: 80%; }
  .m\:right_80 {
    right: 80%; }
  .m\:bottom_80 {
    bottom: 80%; }
  .m\:left_80 {
    left: 80%; }
  .m\:top_90 {
    top: 90%; }
  .m\:right_90 {
    right: 90%; }
  .m\:bottom_90 {
    bottom: 90%; }
  .m\:left_90 {
    left: 90%; }
  .m\:top_100 {
    top: 100%; }
  .m\:right_100 {
    right: 100%; }
  .m\:bottom_100 {
    bottom: 100%; }
  .m\:left_100 {
    left: 100%; }
  .m\:top_auto {
    top: var(--space_auto); }
  .m\:p_top_auto {
    padding-top: var(--space_auto); }
  .m\:m_top_auto {
    margin-top: var(--space_auto); }
  .m\:right_auto {
    right: var(--space_auto); }
  .m\:p_right_auto {
    padding-right: var(--space_auto); }
  .m\:m_right_auto {
    margin-right: var(--space_auto); }
  .m\:bottom_auto {
    bottom: var(--space_auto); }
  .m\:p_bottom_auto {
    padding-bottom: var(--space_auto); }
  .m\:m_bottom_auto {
    margin-bottom: var(--space_auto); }
  .m\:left_auto {
    left: var(--space_auto); }
  .m\:p_left_auto {
    padding-left: var(--space_auto); }
  .m\:m_left_auto {
    margin-left: var(--space_auto); }
  .m\:p_auto {
    padding: var(--space_auto); }
  .m\:p_x_auto {
    padding-right: var(--space_auto);
    padding-left: var(--space_auto); }
  .m\:p_y_auto {
    padding-top: var(--space_auto);
    padding-bottom: var(--space_auto); }
  .m\:m_auto {
    margin: var(--space_auto); }
  .m\:m_x_auto {
    margin-right: var(--space_auto);
    margin-left: var(--space_auto); }
  .m\:m_y_auto {
    margin-top: var(--space_auto);
    margin-bottom: var(--space_auto); }
  .m\:g_auto {
    grid-gap: var(--space_auto); }
  .m\:g_x_auto {
    column-gap: var(--space_auto); }
  .m\:g_y_auto {
    row-gap: var(--space_auto); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_ss {
    top: var(--space_ss); }
  .xl\:p_top_ss {
    padding-top: var(--space_ss); }
  .xl\:m_top_ss {
    margin-top: var(--space_ss); }
  .xl\:right_ss {
    right: var(--space_ss); }
  .xl\:p_right_ss {
    padding-right: var(--space_ss); }
  .xl\:m_right_ss {
    margin-right: var(--space_ss); }
  .xl\:bottom_ss {
    bottom: var(--space_ss); }
  .xl\:p_bottom_ss {
    padding-bottom: var(--space_ss); }
  .xl\:m_bottom_ss {
    margin-bottom: var(--space_ss); }
  .xl\:left_ss {
    left: var(--space_ss); }
  .xl\:p_left_ss {
    padding-left: var(--space_ss); }
  .xl\:m_left_ss {
    margin-left: var(--space_ss); }
  .xl\:p_ss {
    padding: var(--space_ss); }
  .xl\:p_x_ss {
    padding-right: var(--space_ss);
    padding-left: var(--space_ss); }
  .xl\:p_y_ss {
    padding-top: var(--space_ss);
    padding-bottom: var(--space_ss); }
  .xl\:m_ss {
    margin: var(--space_ss); }
  .xl\:m_x_ss {
    margin-right: var(--space_ss);
    margin-left: var(--space_ss); }
  .xl\:m_y_ss {
    margin-top: var(--space_ss);
    margin-bottom: var(--space_ss); }
  .xl\:g_ss {
    grid-gap: var(--space_ss); }
  .xl\:g_x_ss {
    column-gap: var(--space_ss); }
  .xl\:g_y_ss {
    row-gap: var(--space_ss); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_sm {
    top: var(--space_sm); }
  .xl\:p_top_sm {
    padding-top: var(--space_sm); }
  .xl\:m_top_sm {
    margin-top: var(--space_sm); }
  .xl\:right_sm {
    right: var(--space_sm); }
  .xl\:p_right_sm {
    padding-right: var(--space_sm); }
  .xl\:m_right_sm {
    margin-right: var(--space_sm); }
  .xl\:bottom_sm {
    bottom: var(--space_sm); }
  .xl\:p_bottom_sm {
    padding-bottom: var(--space_sm); }
  .xl\:m_bottom_sm {
    margin-bottom: var(--space_sm); }
  .xl\:left_sm {
    left: var(--space_sm); }
  .xl\:p_left_sm {
    padding-left: var(--space_sm); }
  .xl\:m_left_sm {
    margin-left: var(--space_sm); }
  .xl\:p_sm {
    padding: var(--space_sm); }
  .xl\:p_x_sm {
    padding-right: var(--space_sm);
    padding-left: var(--space_sm); }
  .xl\:p_y_sm {
    padding-top: var(--space_sm);
    padding-bottom: var(--space_sm); }
  .xl\:m_sm {
    margin: var(--space_sm); }
  .xl\:m_x_sm {
    margin-right: var(--space_sm);
    margin-left: var(--space_sm); }
  .xl\:m_y_sm {
    margin-top: var(--space_sm);
    margin-bottom: var(--space_sm); }
  .xl\:g_sm {
    grid-gap: var(--space_sm); }
  .xl\:g_x_sm {
    column-gap: var(--space_sm); }
  .xl\:g_y_sm {
    row-gap: var(--space_sm); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_base {
    top: var(--space_base); }
  .xl\:p_top_base {
    padding-top: var(--space_base); }
  .xl\:m_top_base {
    margin-top: var(--space_base); }
  .xl\:right_base {
    right: var(--space_base); }
  .xl\:p_right_base {
    padding-right: var(--space_base); }
  .xl\:m_right_base {
    margin-right: var(--space_base); }
  .xl\:bottom_base {
    bottom: var(--space_base); }
  .xl\:p_bottom_base {
    padding-bottom: var(--space_base); }
  .xl\:m_bottom_base {
    margin-bottom: var(--space_base); }
  .xl\:left_base {
    left: var(--space_base); }
  .xl\:p_left_base {
    padding-left: var(--space_base); }
  .xl\:m_left_base {
    margin-left: var(--space_base); }
  .xl\:p_base {
    padding: var(--space_base); }
  .xl\:p_x_base {
    padding-right: var(--space_base);
    padding-left: var(--space_base); }
  .xl\:p_y_base {
    padding-top: var(--space_base);
    padding-bottom: var(--space_base); }
  .xl\:m_base {
    margin: var(--space_base); }
  .xl\:m_x_base {
    margin-right: var(--space_base);
    margin-left: var(--space_base); }
  .xl\:m_y_base {
    margin-top: var(--space_base);
    margin-bottom: var(--space_base); }
  .xl\:g_base {
    grid-gap: var(--space_base); }
  .xl\:g_x_base {
    column-gap: var(--space_base); }
  .xl\:g_y_base {
    row-gap: var(--space_base); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_me {
    top: var(--space_me); }
  .xl\:p_top_me {
    padding-top: var(--space_me); }
  .xl\:m_top_me {
    margin-top: var(--space_me); }
  .xl\:right_me {
    right: var(--space_me); }
  .xl\:p_right_me {
    padding-right: var(--space_me); }
  .xl\:m_right_me {
    margin-right: var(--space_me); }
  .xl\:bottom_me {
    bottom: var(--space_me); }
  .xl\:p_bottom_me {
    padding-bottom: var(--space_me); }
  .xl\:m_bottom_me {
    margin-bottom: var(--space_me); }
  .xl\:left_me {
    left: var(--space_me); }
  .xl\:p_left_me {
    padding-left: var(--space_me); }
  .xl\:m_left_me {
    margin-left: var(--space_me); }
  .xl\:p_me {
    padding: var(--space_me); }
  .xl\:p_x_me {
    padding-right: var(--space_me);
    padding-left: var(--space_me); }
  .xl\:p_y_me {
    padding-top: var(--space_me);
    padding-bottom: var(--space_me); }
  .xl\:m_me {
    margin: var(--space_me); }
  .xl\:m_x_me {
    margin-right: var(--space_me);
    margin-left: var(--space_me); }
  .xl\:m_y_me {
    margin-top: var(--space_me);
    margin-bottom: var(--space_me); }
  .xl\:g_me {
    grid-gap: var(--space_me); }
  .xl\:g_x_me {
    column-gap: var(--space_me); }
  .xl\:g_y_me {
    row-gap: var(--space_me); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_la {
    top: var(--space_la); }
  .xl\:p_top_la {
    padding-top: var(--space_la); }
  .xl\:m_top_la {
    margin-top: var(--space_la); }
  .xl\:right_la {
    right: var(--space_la); }
  .xl\:p_right_la {
    padding-right: var(--space_la); }
  .xl\:m_right_la {
    margin-right: var(--space_la); }
  .xl\:bottom_la {
    bottom: var(--space_la); }
  .xl\:p_bottom_la {
    padding-bottom: var(--space_la); }
  .xl\:m_bottom_la {
    margin-bottom: var(--space_la); }
  .xl\:left_la {
    left: var(--space_la); }
  .xl\:p_left_la {
    padding-left: var(--space_la); }
  .xl\:m_left_la {
    margin-left: var(--space_la); }
  .xl\:p_la {
    padding: var(--space_la); }
  .xl\:p_x_la {
    padding-right: var(--space_la);
    padding-left: var(--space_la); }
  .xl\:p_y_la {
    padding-top: var(--space_la);
    padding-bottom: var(--space_la); }
  .xl\:m_la {
    margin: var(--space_la); }
  .xl\:m_x_la {
    margin-right: var(--space_la);
    margin-left: var(--space_la); }
  .xl\:m_y_la {
    margin-top: var(--space_la);
    margin-bottom: var(--space_la); }
  .xl\:g_la {
    grid-gap: var(--space_la); }
  .xl\:g_x_la {
    column-gap: var(--space_la); }
  .xl\:g_y_la {
    row-gap: var(--space_la); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_2la {
    top: var(--space_2la); }
  .xl\:p_top_2la {
    padding-top: var(--space_2la); }
  .xl\:m_top_2la {
    margin-top: var(--space_2la); }
  .xl\:right_2la {
    right: var(--space_2la); }
  .xl\:p_right_2la {
    padding-right: var(--space_2la); }
  .xl\:m_right_2la {
    margin-right: var(--space_2la); }
  .xl\:bottom_2la {
    bottom: var(--space_2la); }
  .xl\:p_bottom_2la {
    padding-bottom: var(--space_2la); }
  .xl\:m_bottom_2la {
    margin-bottom: var(--space_2la); }
  .xl\:left_2la {
    left: var(--space_2la); }
  .xl\:p_left_2la {
    padding-left: var(--space_2la); }
  .xl\:m_left_2la {
    margin-left: var(--space_2la); }
  .xl\:p_2la {
    padding: var(--space_2la); }
  .xl\:p_x_2la {
    padding-right: var(--space_2la);
    padding-left: var(--space_2la); }
  .xl\:p_y_2la {
    padding-top: var(--space_2la);
    padding-bottom: var(--space_2la); }
  .xl\:m_2la {
    margin: var(--space_2la); }
  .xl\:m_x_2la {
    margin-right: var(--space_2la);
    margin-left: var(--space_2la); }
  .xl\:m_y_2la {
    margin-top: var(--space_2la);
    margin-bottom: var(--space_2la); }
  .xl\:g_2la {
    grid-gap: var(--space_2la); }
  .xl\:g_x_2la {
    column-gap: var(--space_2la); }
  .xl\:g_y_2la {
    row-gap: var(--space_2la); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_xl {
    top: var(--space_xl); }
  .xl\:p_top_xl {
    padding-top: var(--space_xl); }
  .xl\:m_top_xl {
    margin-top: var(--space_xl); }
  .xl\:right_xl {
    right: var(--space_xl); }
  .xl\:p_right_xl {
    padding-right: var(--space_xl); }
  .xl\:m_right_xl {
    margin-right: var(--space_xl); }
  .xl\:bottom_xl {
    bottom: var(--space_xl); }
  .xl\:p_bottom_xl {
    padding-bottom: var(--space_xl); }
  .xl\:m_bottom_xl {
    margin-bottom: var(--space_xl); }
  .xl\:left_xl {
    left: var(--space_xl); }
  .xl\:p_left_xl {
    padding-left: var(--space_xl); }
  .xl\:m_left_xl {
    margin-left: var(--space_xl); }
  .xl\:p_xl {
    padding: var(--space_xl); }
  .xl\:p_x_xl {
    padding-right: var(--space_xl);
    padding-left: var(--space_xl); }
  .xl\:p_y_xl {
    padding-top: var(--space_xl);
    padding-bottom: var(--space_xl); }
  .xl\:m_xl {
    margin: var(--space_xl); }
  .xl\:m_x_xl {
    margin-right: var(--space_xl);
    margin-left: var(--space_xl); }
  .xl\:m_y_xl {
    margin-top: var(--space_xl);
    margin-bottom: var(--space_xl); }
  .xl\:g_xl {
    grid-gap: var(--space_xl); }
  .xl\:g_x_xl {
    column-gap: var(--space_xl); }
  .xl\:g_y_xl {
    row-gap: var(--space_xl); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_2xl {
    top: var(--space_2xl); }
  .xl\:p_top_2xl {
    padding-top: var(--space_2xl); }
  .xl\:m_top_2xl {
    margin-top: var(--space_2xl); }
  .xl\:right_2xl {
    right: var(--space_2xl); }
  .xl\:p_right_2xl {
    padding-right: var(--space_2xl); }
  .xl\:m_right_2xl {
    margin-right: var(--space_2xl); }
  .xl\:bottom_2xl {
    bottom: var(--space_2xl); }
  .xl\:p_bottom_2xl {
    padding-bottom: var(--space_2xl); }
  .xl\:m_bottom_2xl {
    margin-bottom: var(--space_2xl); }
  .xl\:left_2xl {
    left: var(--space_2xl); }
  .xl\:p_left_2xl {
    padding-left: var(--space_2xl); }
  .xl\:m_left_2xl {
    margin-left: var(--space_2xl); }
  .xl\:p_2xl {
    padding: var(--space_2xl); }
  .xl\:p_x_2xl {
    padding-right: var(--space_2xl);
    padding-left: var(--space_2xl); }
  .xl\:p_y_2xl {
    padding-top: var(--space_2xl);
    padding-bottom: var(--space_2xl); }
  .xl\:m_2xl {
    margin: var(--space_2xl); }
  .xl\:m_x_2xl {
    margin-right: var(--space_2xl);
    margin-left: var(--space_2xl); }
  .xl\:m_y_2xl {
    margin-top: var(--space_2xl);
    margin-bottom: var(--space_2xl); }
  .xl\:g_2xl {
    grid-gap: var(--space_2xl); }
  .xl\:g_x_2xl {
    column-gap: var(--space_2xl); }
  .xl\:g_y_2xl {
    row-gap: var(--space_2xl); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_ma {
    top: var(--space_ma); }
  .xl\:p_top_ma {
    padding-top: var(--space_ma); }
  .xl\:m_top_ma {
    margin-top: var(--space_ma); }
  .xl\:right_ma {
    right: var(--space_ma); }
  .xl\:p_right_ma {
    padding-right: var(--space_ma); }
  .xl\:m_right_ma {
    margin-right: var(--space_ma); }
  .xl\:bottom_ma {
    bottom: var(--space_ma); }
  .xl\:p_bottom_ma {
    padding-bottom: var(--space_ma); }
  .xl\:m_bottom_ma {
    margin-bottom: var(--space_ma); }
  .xl\:left_ma {
    left: var(--space_ma); }
  .xl\:p_left_ma {
    padding-left: var(--space_ma); }
  .xl\:m_left_ma {
    margin-left: var(--space_ma); }
  .xl\:p_ma {
    padding: var(--space_ma); }
  .xl\:p_x_ma {
    padding-right: var(--space_ma);
    padding-left: var(--space_ma); }
  .xl\:p_y_ma {
    padding-top: var(--space_ma);
    padding-bottom: var(--space_ma); }
  .xl\:m_ma {
    margin: var(--space_ma); }
  .xl\:m_x_ma {
    margin-right: var(--space_ma);
    margin-left: var(--space_ma); }
  .xl\:m_y_ma {
    margin-top: var(--space_ma);
    margin-bottom: var(--space_ma); }
  .xl\:g_ma {
    grid-gap: var(--space_ma); }
  .xl\:g_x_ma {
    column-gap: var(--space_ma); }
  .xl\:g_y_ma {
    row-gap: var(--space_ma); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_2ma {
    top: var(--space_2ma); }
  .xl\:p_top_2ma {
    padding-top: var(--space_2ma); }
  .xl\:m_top_2ma {
    margin-top: var(--space_2ma); }
  .xl\:right_2ma {
    right: var(--space_2ma); }
  .xl\:p_right_2ma {
    padding-right: var(--space_2ma); }
  .xl\:m_right_2ma {
    margin-right: var(--space_2ma); }
  .xl\:bottom_2ma {
    bottom: var(--space_2ma); }
  .xl\:p_bottom_2ma {
    padding-bottom: var(--space_2ma); }
  .xl\:m_bottom_2ma {
    margin-bottom: var(--space_2ma); }
  .xl\:left_2ma {
    left: var(--space_2ma); }
  .xl\:p_left_2ma {
    padding-left: var(--space_2ma); }
  .xl\:m_left_2ma {
    margin-left: var(--space_2ma); }
  .xl\:p_2ma {
    padding: var(--space_2ma); }
  .xl\:p_x_2ma {
    padding-right: var(--space_2ma);
    padding-left: var(--space_2ma); }
  .xl\:p_y_2ma {
    padding-top: var(--space_2ma);
    padding-bottom: var(--space_2ma); }
  .xl\:m_2ma {
    margin: var(--space_2ma); }
  .xl\:m_x_2ma {
    margin-right: var(--space_2ma);
    margin-left: var(--space_2ma); }
  .xl\:m_y_2ma {
    margin-top: var(--space_2ma);
    margin-bottom: var(--space_2ma); }
  .xl\:g_2ma {
    grid-gap: var(--space_2ma); }
  .xl\:g_x_2ma {
    column-gap: var(--space_2ma); }
  .xl\:g_y_2ma {
    row-gap: var(--space_2ma); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_ss_indent {
    top: var(--space_ss_indent); }
  .xl\:p_top_ss_indent {
    padding-top: var(--space_ss_indent); }
  .xl\:m_top_ss_indent {
    margin-top: var(--space_ss_indent); }
  .xl\:right_ss_indent {
    right: var(--space_ss_indent); }
  .xl\:p_right_ss_indent {
    padding-right: var(--space_ss_indent); }
  .xl\:m_right_ss_indent {
    margin-right: var(--space_ss_indent); }
  .xl\:bottom_ss_indent {
    bottom: var(--space_ss_indent); }
  .xl\:p_bottom_ss_indent {
    padding-bottom: var(--space_ss_indent); }
  .xl\:m_bottom_ss_indent {
    margin-bottom: var(--space_ss_indent); }
  .xl\:left_ss_indent {
    left: var(--space_ss_indent); }
  .xl\:p_left_ss_indent {
    padding-left: var(--space_ss_indent); }
  .xl\:m_left_ss_indent {
    margin-left: var(--space_ss_indent); }
  .xl\:p_ss_indent {
    padding: var(--space_ss_indent); }
  .xl\:p_x_ss_indent {
    padding-right: var(--space_ss_indent);
    padding-left: var(--space_ss_indent); }
  .xl\:p_y_ss_indent {
    padding-top: var(--space_ss_indent);
    padding-bottom: var(--space_ss_indent); }
  .xl\:m_ss_indent {
    margin: var(--space_ss_indent); }
  .xl\:m_x_ss_indent {
    margin-right: var(--space_ss_indent);
    margin-left: var(--space_ss_indent); }
  .xl\:m_y_ss_indent {
    margin-top: var(--space_ss_indent);
    margin-bottom: var(--space_ss_indent); }
  .xl\:g_ss_indent {
    grid-gap: var(--space_ss_indent); }
  .xl\:g_x_ss_indent {
    column-gap: var(--space_ss_indent); }
  .xl\:g_y_ss_indent {
    row-gap: var(--space_ss_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_sm_indent {
    top: var(--space_sm_indent); }
  .xl\:p_top_sm_indent {
    padding-top: var(--space_sm_indent); }
  .xl\:m_top_sm_indent {
    margin-top: var(--space_sm_indent); }
  .xl\:right_sm_indent {
    right: var(--space_sm_indent); }
  .xl\:p_right_sm_indent {
    padding-right: var(--space_sm_indent); }
  .xl\:m_right_sm_indent {
    margin-right: var(--space_sm_indent); }
  .xl\:bottom_sm_indent {
    bottom: var(--space_sm_indent); }
  .xl\:p_bottom_sm_indent {
    padding-bottom: var(--space_sm_indent); }
  .xl\:m_bottom_sm_indent {
    margin-bottom: var(--space_sm_indent); }
  .xl\:left_sm_indent {
    left: var(--space_sm_indent); }
  .xl\:p_left_sm_indent {
    padding-left: var(--space_sm_indent); }
  .xl\:m_left_sm_indent {
    margin-left: var(--space_sm_indent); }
  .xl\:p_sm_indent {
    padding: var(--space_sm_indent); }
  .xl\:p_x_sm_indent {
    padding-right: var(--space_sm_indent);
    padding-left: var(--space_sm_indent); }
  .xl\:p_y_sm_indent {
    padding-top: var(--space_sm_indent);
    padding-bottom: var(--space_sm_indent); }
  .xl\:m_sm_indent {
    margin: var(--space_sm_indent); }
  .xl\:m_x_sm_indent {
    margin-right: var(--space_sm_indent);
    margin-left: var(--space_sm_indent); }
  .xl\:m_y_sm_indent {
    margin-top: var(--space_sm_indent);
    margin-bottom: var(--space_sm_indent); }
  .xl\:g_sm_indent {
    grid-gap: var(--space_sm_indent); }
  .xl\:g_x_sm_indent {
    column-gap: var(--space_sm_indent); }
  .xl\:g_y_sm_indent {
    row-gap: var(--space_sm_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_base_indent {
    top: var(--space_base_indent); }
  .xl\:p_top_base_indent {
    padding-top: var(--space_base_indent); }
  .xl\:m_top_base_indent {
    margin-top: var(--space_base_indent); }
  .xl\:right_base_indent {
    right: var(--space_base_indent); }
  .xl\:p_right_base_indent {
    padding-right: var(--space_base_indent); }
  .xl\:m_right_base_indent {
    margin-right: var(--space_base_indent); }
  .xl\:bottom_base_indent {
    bottom: var(--space_base_indent); }
  .xl\:p_bottom_base_indent {
    padding-bottom: var(--space_base_indent); }
  .xl\:m_bottom_base_indent {
    margin-bottom: var(--space_base_indent); }
  .xl\:left_base_indent {
    left: var(--space_base_indent); }
  .xl\:p_left_base_indent {
    padding-left: var(--space_base_indent); }
  .xl\:m_left_base_indent {
    margin-left: var(--space_base_indent); }
  .xl\:p_base_indent {
    padding: var(--space_base_indent); }
  .xl\:p_x_base_indent {
    padding-right: var(--space_base_indent);
    padding-left: var(--space_base_indent); }
  .xl\:p_y_base_indent {
    padding-top: var(--space_base_indent);
    padding-bottom: var(--space_base_indent); }
  .xl\:m_base_indent {
    margin: var(--space_base_indent); }
  .xl\:m_x_base_indent {
    margin-right: var(--space_base_indent);
    margin-left: var(--space_base_indent); }
  .xl\:m_y_base_indent {
    margin-top: var(--space_base_indent);
    margin-bottom: var(--space_base_indent); }
  .xl\:g_base_indent {
    grid-gap: var(--space_base_indent); }
  .xl\:g_x_base_indent {
    column-gap: var(--space_base_indent); }
  .xl\:g_y_base_indent {
    row-gap: var(--space_base_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_me_indent {
    top: var(--space_me_indent); }
  .xl\:p_top_me_indent {
    padding-top: var(--space_me_indent); }
  .xl\:m_top_me_indent {
    margin-top: var(--space_me_indent); }
  .xl\:right_me_indent {
    right: var(--space_me_indent); }
  .xl\:p_right_me_indent {
    padding-right: var(--space_me_indent); }
  .xl\:m_right_me_indent {
    margin-right: var(--space_me_indent); }
  .xl\:bottom_me_indent {
    bottom: var(--space_me_indent); }
  .xl\:p_bottom_me_indent {
    padding-bottom: var(--space_me_indent); }
  .xl\:m_bottom_me_indent {
    margin-bottom: var(--space_me_indent); }
  .xl\:left_me_indent {
    left: var(--space_me_indent); }
  .xl\:p_left_me_indent {
    padding-left: var(--space_me_indent); }
  .xl\:m_left_me_indent {
    margin-left: var(--space_me_indent); }
  .xl\:p_me_indent {
    padding: var(--space_me_indent); }
  .xl\:p_x_me_indent {
    padding-right: var(--space_me_indent);
    padding-left: var(--space_me_indent); }
  .xl\:p_y_me_indent {
    padding-top: var(--space_me_indent);
    padding-bottom: var(--space_me_indent); }
  .xl\:m_me_indent {
    margin: var(--space_me_indent); }
  .xl\:m_x_me_indent {
    margin-right: var(--space_me_indent);
    margin-left: var(--space_me_indent); }
  .xl\:m_y_me_indent {
    margin-top: var(--space_me_indent);
    margin-bottom: var(--space_me_indent); }
  .xl\:g_me_indent {
    grid-gap: var(--space_me_indent); }
  .xl\:g_x_me_indent {
    column-gap: var(--space_me_indent); }
  .xl\:g_y_me_indent {
    row-gap: var(--space_me_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_la_indent {
    top: var(--space_la_indent); }
  .xl\:p_top_la_indent {
    padding-top: var(--space_la_indent); }
  .xl\:m_top_la_indent {
    margin-top: var(--space_la_indent); }
  .xl\:right_la_indent {
    right: var(--space_la_indent); }
  .xl\:p_right_la_indent {
    padding-right: var(--space_la_indent); }
  .xl\:m_right_la_indent {
    margin-right: var(--space_la_indent); }
  .xl\:bottom_la_indent {
    bottom: var(--space_la_indent); }
  .xl\:p_bottom_la_indent {
    padding-bottom: var(--space_la_indent); }
  .xl\:m_bottom_la_indent {
    margin-bottom: var(--space_la_indent); }
  .xl\:left_la_indent {
    left: var(--space_la_indent); }
  .xl\:p_left_la_indent {
    padding-left: var(--space_la_indent); }
  .xl\:m_left_la_indent {
    margin-left: var(--space_la_indent); }
  .xl\:p_la_indent {
    padding: var(--space_la_indent); }
  .xl\:p_x_la_indent {
    padding-right: var(--space_la_indent);
    padding-left: var(--space_la_indent); }
  .xl\:p_y_la_indent {
    padding-top: var(--space_la_indent);
    padding-bottom: var(--space_la_indent); }
  .xl\:m_la_indent {
    margin: var(--space_la_indent); }
  .xl\:m_x_la_indent {
    margin-right: var(--space_la_indent);
    margin-left: var(--space_la_indent); }
  .xl\:m_y_la_indent {
    margin-top: var(--space_la_indent);
    margin-bottom: var(--space_la_indent); }
  .xl\:g_la_indent {
    grid-gap: var(--space_la_indent); }
  .xl\:g_x_la_indent {
    column-gap: var(--space_la_indent); }
  .xl\:g_y_la_indent {
    row-gap: var(--space_la_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_2la_indent {
    top: var(--space_2la_indent); }
  .xl\:p_top_2la_indent {
    padding-top: var(--space_2la_indent); }
  .xl\:m_top_2la_indent {
    margin-top: var(--space_2la_indent); }
  .xl\:right_2la_indent {
    right: var(--space_2la_indent); }
  .xl\:p_right_2la_indent {
    padding-right: var(--space_2la_indent); }
  .xl\:m_right_2la_indent {
    margin-right: var(--space_2la_indent); }
  .xl\:bottom_2la_indent {
    bottom: var(--space_2la_indent); }
  .xl\:p_bottom_2la_indent {
    padding-bottom: var(--space_2la_indent); }
  .xl\:m_bottom_2la_indent {
    margin-bottom: var(--space_2la_indent); }
  .xl\:left_2la_indent {
    left: var(--space_2la_indent); }
  .xl\:p_left_2la_indent {
    padding-left: var(--space_2la_indent); }
  .xl\:m_left_2la_indent {
    margin-left: var(--space_2la_indent); }
  .xl\:p_2la_indent {
    padding: var(--space_2la_indent); }
  .xl\:p_x_2la_indent {
    padding-right: var(--space_2la_indent);
    padding-left: var(--space_2la_indent); }
  .xl\:p_y_2la_indent {
    padding-top: var(--space_2la_indent);
    padding-bottom: var(--space_2la_indent); }
  .xl\:m_2la_indent {
    margin: var(--space_2la_indent); }
  .xl\:m_x_2la_indent {
    margin-right: var(--space_2la_indent);
    margin-left: var(--space_2la_indent); }
  .xl\:m_y_2la_indent {
    margin-top: var(--space_2la_indent);
    margin-bottom: var(--space_2la_indent); }
  .xl\:g_2la_indent {
    grid-gap: var(--space_2la_indent); }
  .xl\:g_x_2la_indent {
    column-gap: var(--space_2la_indent); }
  .xl\:g_y_2la_indent {
    row-gap: var(--space_2la_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_xl_indent {
    top: var(--space_xl_indent); }
  .xl\:p_top_xl_indent {
    padding-top: var(--space_xl_indent); }
  .xl\:m_top_xl_indent {
    margin-top: var(--space_xl_indent); }
  .xl\:right_xl_indent {
    right: var(--space_xl_indent); }
  .xl\:p_right_xl_indent {
    padding-right: var(--space_xl_indent); }
  .xl\:m_right_xl_indent {
    margin-right: var(--space_xl_indent); }
  .xl\:bottom_xl_indent {
    bottom: var(--space_xl_indent); }
  .xl\:p_bottom_xl_indent {
    padding-bottom: var(--space_xl_indent); }
  .xl\:m_bottom_xl_indent {
    margin-bottom: var(--space_xl_indent); }
  .xl\:left_xl_indent {
    left: var(--space_xl_indent); }
  .xl\:p_left_xl_indent {
    padding-left: var(--space_xl_indent); }
  .xl\:m_left_xl_indent {
    margin-left: var(--space_xl_indent); }
  .xl\:p_xl_indent {
    padding: var(--space_xl_indent); }
  .xl\:p_x_xl_indent {
    padding-right: var(--space_xl_indent);
    padding-left: var(--space_xl_indent); }
  .xl\:p_y_xl_indent {
    padding-top: var(--space_xl_indent);
    padding-bottom: var(--space_xl_indent); }
  .xl\:m_xl_indent {
    margin: var(--space_xl_indent); }
  .xl\:m_x_xl_indent {
    margin-right: var(--space_xl_indent);
    margin-left: var(--space_xl_indent); }
  .xl\:m_y_xl_indent {
    margin-top: var(--space_xl_indent);
    margin-bottom: var(--space_xl_indent); }
  .xl\:g_xl_indent {
    grid-gap: var(--space_xl_indent); }
  .xl\:g_x_xl_indent {
    column-gap: var(--space_xl_indent); }
  .xl\:g_y_xl_indent {
    row-gap: var(--space_xl_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_2xl_indent {
    top: var(--space_2xl_indent); }
  .xl\:p_top_2xl_indent {
    padding-top: var(--space_2xl_indent); }
  .xl\:m_top_2xl_indent {
    margin-top: var(--space_2xl_indent); }
  .xl\:right_2xl_indent {
    right: var(--space_2xl_indent); }
  .xl\:p_right_2xl_indent {
    padding-right: var(--space_2xl_indent); }
  .xl\:m_right_2xl_indent {
    margin-right: var(--space_2xl_indent); }
  .xl\:bottom_2xl_indent {
    bottom: var(--space_2xl_indent); }
  .xl\:p_bottom_2xl_indent {
    padding-bottom: var(--space_2xl_indent); }
  .xl\:m_bottom_2xl_indent {
    margin-bottom: var(--space_2xl_indent); }
  .xl\:left_2xl_indent {
    left: var(--space_2xl_indent); }
  .xl\:p_left_2xl_indent {
    padding-left: var(--space_2xl_indent); }
  .xl\:m_left_2xl_indent {
    margin-left: var(--space_2xl_indent); }
  .xl\:p_2xl_indent {
    padding: var(--space_2xl_indent); }
  .xl\:p_x_2xl_indent {
    padding-right: var(--space_2xl_indent);
    padding-left: var(--space_2xl_indent); }
  .xl\:p_y_2xl_indent {
    padding-top: var(--space_2xl_indent);
    padding-bottom: var(--space_2xl_indent); }
  .xl\:m_2xl_indent {
    margin: var(--space_2xl_indent); }
  .xl\:m_x_2xl_indent {
    margin-right: var(--space_2xl_indent);
    margin-left: var(--space_2xl_indent); }
  .xl\:m_y_2xl_indent {
    margin-top: var(--space_2xl_indent);
    margin-bottom: var(--space_2xl_indent); }
  .xl\:g_2xl_indent {
    grid-gap: var(--space_2xl_indent); }
  .xl\:g_x_2xl_indent {
    column-gap: var(--space_2xl_indent); }
  .xl\:g_y_2xl_indent {
    row-gap: var(--space_2xl_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_ma_indent {
    top: var(--space_ma_indent); }
  .xl\:p_top_ma_indent {
    padding-top: var(--space_ma_indent); }
  .xl\:m_top_ma_indent {
    margin-top: var(--space_ma_indent); }
  .xl\:right_ma_indent {
    right: var(--space_ma_indent); }
  .xl\:p_right_ma_indent {
    padding-right: var(--space_ma_indent); }
  .xl\:m_right_ma_indent {
    margin-right: var(--space_ma_indent); }
  .xl\:bottom_ma_indent {
    bottom: var(--space_ma_indent); }
  .xl\:p_bottom_ma_indent {
    padding-bottom: var(--space_ma_indent); }
  .xl\:m_bottom_ma_indent {
    margin-bottom: var(--space_ma_indent); }
  .xl\:left_ma_indent {
    left: var(--space_ma_indent); }
  .xl\:p_left_ma_indent {
    padding-left: var(--space_ma_indent); }
  .xl\:m_left_ma_indent {
    margin-left: var(--space_ma_indent); }
  .xl\:p_ma_indent {
    padding: var(--space_ma_indent); }
  .xl\:p_x_ma_indent {
    padding-right: var(--space_ma_indent);
    padding-left: var(--space_ma_indent); }
  .xl\:p_y_ma_indent {
    padding-top: var(--space_ma_indent);
    padding-bottom: var(--space_ma_indent); }
  .xl\:m_ma_indent {
    margin: var(--space_ma_indent); }
  .xl\:m_x_ma_indent {
    margin-right: var(--space_ma_indent);
    margin-left: var(--space_ma_indent); }
  .xl\:m_y_ma_indent {
    margin-top: var(--space_ma_indent);
    margin-bottom: var(--space_ma_indent); }
  .xl\:g_ma_indent {
    grid-gap: var(--space_ma_indent); }
  .xl\:g_x_ma_indent {
    column-gap: var(--space_ma_indent); }
  .xl\:g_y_ma_indent {
    row-gap: var(--space_ma_indent); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_0 {
    top: var(--space_0); }
  .xl\:p_top_0 {
    padding-top: var(--space_0); }
  .xl\:m_top_0 {
    margin-top: var(--space_0); }
  .xl\:right_0 {
    right: var(--space_0); }
  .xl\:p_right_0 {
    padding-right: var(--space_0); }
  .xl\:m_right_0 {
    margin-right: var(--space_0); }
  .xl\:bottom_0 {
    bottom: var(--space_0); }
  .xl\:p_bottom_0 {
    padding-bottom: var(--space_0); }
  .xl\:m_bottom_0 {
    margin-bottom: var(--space_0); }
  .xl\:left_0 {
    left: var(--space_0); }
  .xl\:p_left_0 {
    padding-left: var(--space_0); }
  .xl\:m_left_0 {
    margin-left: var(--space_0); }
  .xl\:p_0 {
    padding: var(--space_0); }
  .xl\:p_x_0 {
    padding-right: var(--space_0);
    padding-left: var(--space_0); }
  .xl\:p_y_0 {
    padding-top: var(--space_0);
    padding-bottom: var(--space_0); }
  .xl\:m_0 {
    margin: var(--space_0); }
  .xl\:m_x_0 {
    margin-right: var(--space_0);
    margin-left: var(--space_0); }
  .xl\:m_y_0 {
    margin-top: var(--space_0);
    margin-bottom: var(--space_0); }
  .xl\:g_0 {
    grid-gap: var(--space_0); }
  .xl\:g_x_0 {
    column-gap: var(--space_0); }
  .xl\:g_y_0 {
    row-gap: var(--space_0); } }

@media all and (min-width: 1680px) {
  .xl\:top_initial {
    top: initial; }
  .xl\:right_initial {
    right: initial; }
  .xl\:bottom_initial {
    bottom: initial; }
  .xl\:left_initial {
    left: initial; }
  .xl\:top_10 {
    top: 10%; }
  .xl\:right_10 {
    right: 10%; }
  .xl\:bottom_10 {
    bottom: 10%; }
  .xl\:left_10 {
    left: 10%; }
  .xl\:top_20 {
    top: 20%; }
  .xl\:right_20 {
    right: 20%; }
  .xl\:bottom_20 {
    bottom: 20%; }
  .xl\:left_20 {
    left: 20%; }
  .xl\:top_30 {
    top: 30%; }
  .xl\:right_30 {
    right: 30%; }
  .xl\:bottom_30 {
    bottom: 30%; }
  .xl\:left_30 {
    left: 30%; }
  .xl\:top_40 {
    top: 40%; }
  .xl\:right_40 {
    right: 40%; }
  .xl\:bottom_40 {
    bottom: 40%; }
  .xl\:left_40 {
    left: 40%; }
  .xl\:top_50 {
    top: 50%; }
  .xl\:right_50 {
    right: 50%; }
  .xl\:bottom_50 {
    bottom: 50%; }
  .xl\:left_50 {
    left: 50%; }
  .xl\:top_60 {
    top: 60%; }
  .xl\:right_60 {
    right: 60%; }
  .xl\:bottom_60 {
    bottom: 60%; }
  .xl\:left_60 {
    left: 60%; }
  .xl\:top_70 {
    top: 70%; }
  .xl\:right_70 {
    right: 70%; }
  .xl\:bottom_70 {
    bottom: 70%; }
  .xl\:left_70 {
    left: 70%; }
  .xl\:top_80 {
    top: 80%; }
  .xl\:right_80 {
    right: 80%; }
  .xl\:bottom_80 {
    bottom: 80%; }
  .xl\:left_80 {
    left: 80%; }
  .xl\:top_90 {
    top: 90%; }
  .xl\:right_90 {
    right: 90%; }
  .xl\:bottom_90 {
    bottom: 90%; }
  .xl\:left_90 {
    left: 90%; }
  .xl\:top_100 {
    top: 100%; }
  .xl\:right_100 {
    right: 100%; }
  .xl\:bottom_100 {
    bottom: 100%; }
  .xl\:left_100 {
    left: 100%; }
  .xl\:top_auto {
    top: var(--space_auto); }
  .xl\:p_top_auto {
    padding-top: var(--space_auto); }
  .xl\:m_top_auto {
    margin-top: var(--space_auto); }
  .xl\:right_auto {
    right: var(--space_auto); }
  .xl\:p_right_auto {
    padding-right: var(--space_auto); }
  .xl\:m_right_auto {
    margin-right: var(--space_auto); }
  .xl\:bottom_auto {
    bottom: var(--space_auto); }
  .xl\:p_bottom_auto {
    padding-bottom: var(--space_auto); }
  .xl\:m_bottom_auto {
    margin-bottom: var(--space_auto); }
  .xl\:left_auto {
    left: var(--space_auto); }
  .xl\:p_left_auto {
    padding-left: var(--space_auto); }
  .xl\:m_left_auto {
    margin-left: var(--space_auto); }
  .xl\:p_auto {
    padding: var(--space_auto); }
  .xl\:p_x_auto {
    padding-right: var(--space_auto);
    padding-left: var(--space_auto); }
  .xl\:p_y_auto {
    padding-top: var(--space_auto);
    padding-bottom: var(--space_auto); }
  .xl\:m_auto {
    margin: var(--space_auto); }
  .xl\:m_x_auto {
    margin-right: var(--space_auto);
    margin-left: var(--space_auto); }
  .xl\:m_y_auto {
    margin-top: var(--space_auto);
    margin-bottom: var(--space_auto); }
  .xl\:g_auto {
    grid-gap: var(--space_auto); }
  .xl\:g_x_auto {
    column-gap: var(--space_auto); }
  .xl\:g_y_auto {
    row-gap: var(--space_auto); } }

.thumbnail {
  display: block;
  position: relative;
  line-height: 0;
  border: none;
  background-repeat: no-repeat;
  background-position: center; }
  .thumbnail.cover {
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
  .thumbnail.contain {
    -webkit-background-size: contain;
    -moz-background-size: contain;
    -o-background-size: contain;
    background-size: contain; }
  .thumbnail.la {
    -webkit-background-size: 150%;
    -moz-background-size: 150%;
    -o-background-size: 150%;
    background-size: 150%; }
  .thumbnail.xl {
    -webkit-background-size: 200%;
    -moz-background-size: 200%;
    -o-background-size: 200%;
    background-size: 200%; }
  .thumbnail.ratio_1_1 {
    padding-bottom: 100%; }
  .thumbnail.ratio_2_1 {
    padding-bottom: 50%; }
  .thumbnail.ratio_2_3 {
    padding-bottom: 150%; }
  .thumbnail.ratio_3_2 {
    padding-bottom: 66.66667%; }
  .thumbnail.ratio_3_4 {
    padding-bottom: 133.33333%; }
  .thumbnail.ratio_4_3 {
    padding-bottom: 75%; }
  .thumbnail.ratio_9_16 {
    padding-bottom: 177.77778%; }
  .thumbnail.ratio_16_9 {
    padding-bottom: 56.25%; }
  @media all and (min-width: 960px) {
    .thumbnail.m\:cover {
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover; }
    .thumbnail.m\:contain {
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain; }
    .thumbnail.m\:la {
      -webkit-background-size: 150%;
      -moz-background-size: 150%;
      -o-background-size: 150%;
      background-size: 150%; }
    .thumbnail.m\:xl {
      -webkit-background-size: 200%;
      -moz-background-size: 200%;
      -o-background-size: 200%;
      background-size: 200%; }
    .thumbnail.m\:ratio_1_1 {
      padding-bottom: 100%; }
    .thumbnail.m\:ratio_2_1 {
      padding-bottom: 50%; }
    .thumbnail.m\:ratio_2_3 {
      padding-bottom: 150%; }
    .thumbnail.m\:ratio_3_2 {
      padding-bottom: 66.66667%; }
    .thumbnail.m\:ratio_3_4 {
      padding-bottom: 133.33333%; }
    .thumbnail.m\:ratio_4_3 {
      padding-bottom: 75%; }
    .thumbnail.m\:ratio_9_16 {
      padding-bottom: 177.77778%; }
    .thumbnail.m\:ratio_16_9 {
      padding-bottom: 56.25%; } }
  @media all and (min-width: 1680px) {
    .thumbnail.xl\:cover {
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover; }
    .thumbnail.xl\:contain {
      -webkit-background-size: contain;
      -moz-background-size: contain;
      -o-background-size: contain;
      background-size: contain; }
    .thumbnail.xl\:la {
      -webkit-background-size: 150%;
      -moz-background-size: 150%;
      -o-background-size: 150%;
      background-size: 150%; }
    .thumbnail.xl\:xl {
      -webkit-background-size: 200%;
      -moz-background-size: 200%;
      -o-background-size: 200%;
      background-size: 200%; }
    .thumbnail.xl\:ratio_1_1 {
      padding-bottom: 100%; }
    .thumbnail.xl\:ratio_2_1 {
      padding-bottom: 50%; }
    .thumbnail.xl\:ratio_2_3 {
      padding-bottom: 150%; }
    .thumbnail.xl\:ratio_3_2 {
      padding-bottom: 66.66667%; }
    .thumbnail.xl\:ratio_3_4 {
      padding-bottom: 133.33333%; }
    .thumbnail.xl\:ratio_4_3 {
      padding-bottom: 75%; }
    .thumbnail.xl\:ratio_9_16 {
      padding-bottom: 177.77778%; }
    .thumbnail.xl\:ratio_16_9 {
      padding-bottom: 56.25%; } }

.thumbnail img, .photo img, .avator img, .logo img {
  width: 100%;
  height: auto; }

.flex_row {
  flex-flow: row wrap; }

.flex_row_reverse {
  flex-flow: row-reverse wrap; }

.flex_row_reverse_odd:nth-child(odd) {
  flex-flow: row-reverse wrap; }

.flex_row_reverse_even:nth-child(even) {
  flex-flow: row-reverse wrap; }

.flex_column {
  flex-flow: column wrap; }

.flex_column_reverse {
  flex-flow: column-reverse wrap; }

.col_1 {
  flex: 1; }

.col_2 {
  flex: 2; }

.col_3 {
  flex: 3; }

.col_4 {
  flex: 4; }

.col_5 {
  flex: 5; }

.col_6 {
  flex: 6; }

.col_7 {
  flex: 7; }

.col_8 {
  flex: 8; }

.col_9 {
  flex: 9; }

.col_10 {
  flex: 10; }

.col_11 {
  flex: 11; }

.col_12 {
  flex: 12; }

@media all and (min-width: 768px) {
  .ms\:flex_row {
    flex-flow: row wrap; }
  .ms\:flex_row_reverse {
    flex-flow: row-reverse wrap; }
  .ms\:flex_row_reverse_odd:nth-child(odd) {
    flex-flow: row-reverse wrap; }
  .ms\:flex_row_reverse_even:nth-child(even) {
    flex-flow: row-reverse wrap; }
  .ms\:flex_column {
    flex-flow: column wrap; }
  .ms\:flex_column_reverse {
    flex-flow: column-reverse wrap; }
  .ms\:col_1 {
    flex: 1; }
  .ms\:col_2 {
    flex: 2; }
  .ms\:col_3 {
    flex: 3; }
  .ms\:col_4 {
    flex: 4; }
  .ms\:col_5 {
    flex: 5; }
  .ms\:col_6 {
    flex: 6; }
  .ms\:col_7 {
    flex: 7; }
  .ms\:col_8 {
    flex: 8; }
  .ms\:col_9 {
    flex: 9; }
  .ms\:col_10 {
    flex: 10; }
  .ms\:col_11 {
    flex: 11; }
  .ms\:col_12 {
    flex: 12; } }

@media all and (min-width: 960px) {
  .m\:flex_row {
    flex-flow: row wrap; }
  .m\:flex_row_reverse {
    flex-flow: row-reverse wrap; }
  .m\:flex_reverse_odd:nth-child(odd) {
    flex-flow: row-reverse wrap; }
  .m\:flex_reverse_even:nth-child(even) {
    flex-flow: row-reverse wrap; }
  .m\:flex_column {
    flex-flow: column wrap; }
  .m\:flex_column_reverse {
    flex-flow: column-reverse wrap; }
  .m\:col_1 {
    flex: 1; }
  .m\:col_2 {
    flex: 2; }
  .m\:col_3 {
    flex: 3; }
  .m\:col_4 {
    flex: 4; }
  .m\:col_5 {
    flex: 5; }
  .m\:col_6 {
    flex: 6; }
  .m\:col_7 {
    flex: 7; }
  .m\:col_8 {
    flex: 8; }
  .m\:col_9 {
    flex: 9; }
  .m\:col_10 {
    flex: 10; }
  .m\:col_11 {
    flex: 11; }
  .m\:col_12 {
    flex: 12; } }

@media all and (min-width: 1680px) {
  .xl\:flex_row {
    flex-flow: row wrap; }
  .xl\:flex_row_reverse {
    flex-flow: row-reverse wrap; }
  .xl\:flex_reverse_odd:nth-child(odd) {
    flex-flow: row-reverse wrap; }
  .xl\:flex_reverse_even:nth-child(even) {
    flex-flow: row-reverse wrap; }
  .xl\:flex_column {
    flex-flow: column wrap; }
  .xl\:flex_column_reverse {
    flex-flow: column-reverse wrap; }
  .xl\:col_1 {
    flex: 1; }
  .xl\:col_2 {
    flex: 2; }
  .xl\:col_3 {
    flex: 3; }
  .xl\:col_4 {
    flex: 4; }
  .xl\:col_5 {
    flex: 5; }
  .xl\:col_6 {
    flex: 6; }
  .xl\:col_7 {
    flex: 7; }
  .xl\:col_8 {
    flex: 8; }
  .xl\:col_9 {
    flex: 9; }
  .xl\:col_10 {
    flex: 10; }
  .xl\:col_11 {
    flex: 11; }
  .xl\:col_12 {
    flex: 12; } }

.justify_start {
  justify-content: flex-start; }

.justify_end {
  justify-content: flex-end; }

.justify_center {
  justify-content: center; }

.justify_between {
  justify-content: space-between; }

.justify_around {
  justify-content: space-around; }

.justify_evenly {
  justify-content: space-evenly; }

.align_start {
  align-items: flex-start; }

.align_end {
  align-items: flex-end; }

.align_center {
  align-items: center; }

.align_between {
  align-items: space-between; }

.align_around {
  align-items: space-around; }

.align_evenly {
  align-items: space-evenly; }

.justify_align_center {
  justify-content: center;
  align-items: center; }

@media all and (min-width: 768px) {
  .ms\:justify_start {
    justify-content: flex-start; }
  .ms\:justify_end {
    justify-content: flex-end; }
  .ms\:justify_center {
    justify-content: center; }
  .ms\:justify_between {
    justify-content: space-between; }
  .ms\:justify_around {
    justify-content: space-around; }
  .ms\:justify_evenly {
    justify-content: space-evenly; }
  .ms\:align_start {
    align-items: flex-start; }
  .ms\:align_end {
    align-items: flex-end; }
  .ms\:align_center {
    align-items: center; }
  .ms\:align_between {
    align-items: space-between; }
  .ms\:align_around {
    align-items: space-around; }
  .ms\:align_evenly {
    align-items: space-evenly; } }

@media all and (min-width: 960px) {
  .m\:justify_start {
    justify-content: flex-start; }
  .m\:justify_end {
    justify-content: flex-end; }
  .m\:justify_center {
    justify-content: center; }
  .m\:justify_between {
    justify-content: space-between; }
  .m\:justify_around {
    justify-content: space-around; }
  .m\:justify_evenly {
    justify-content: space-evenly; }
  .m\:align_start {
    align-items: flex-start; }
  .m\:align_end {
    align-items: flex-end; }
  .m\:align_center {
    align-items: center; }
  .m\:align_between {
    align-items: space-between; }
  .m\:align_around {
    align-items: space-around; }
  .m\:align_evenly {
    align-items: space-evenly; } }

@media all and (min-width: 1680px) {
  .xl\:justify_start {
    justify-content: flex-start; }
  .xl\:justify_end {
    justify-content: flex-end; }
  .xl\:justify_center {
    justify-content: center; }
  .xl\:justify_between {
    justify-content: space-between; }
  .xl\:justify_around {
    justify-content: space-around; }
  .xl\:justify_evenly {
    justify-content: space-evenly; }
  .xl\:align_start {
    align-items: flex-start; }
  .xl\:align_end {
    align-items: flex-end; }
  .xl\:align_center {
    align-items: center; }
  .xl\:align_between {
    align-items: space-between; }
  .xl\:align_around {
    align-items: space-around; }
  .xl\:align_evenly {
    align-items: space-evenly; } }

.grid_2 {
  grid-template-columns: repeat(auto-fill, minmax(calc(50% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_2 {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_2 {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_2 {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_2 {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_2 {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_2 {
    grid-template-columns: repeat(auto-fill, minmax(calc(50% - var(--space_la)), 1fr)); } }

.grid_3 {
  grid-template-columns: repeat(auto-fill, minmax(calc(33.33333% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_3 {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_3 {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_3 {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_3 {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_3 {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_3 {
    grid-template-columns: repeat(auto-fill, minmax(calc(33.33333% - var(--space_la)), 1fr)); } }

.grid_4 {
  grid-template-columns: repeat(auto-fill, minmax(calc(25% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_4 {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_4 {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_4 {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_4 {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_4 {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_4 {
    grid-template-columns: repeat(auto-fill, minmax(calc(25% - var(--space_la)), 1fr)); } }

.grid_5 {
  grid-template-columns: repeat(auto-fill, minmax(calc(20% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_5 {
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_5 {
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_5 {
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_5 {
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_5 {
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_5 {
    grid-template-columns: repeat(auto-fill, minmax(calc(20% - var(--space_la)), 1fr)); } }

.grid_6 {
  grid-template-columns: repeat(auto-fill, minmax(calc(16.66667% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_6 {
    grid-template-columns: repeat(auto-fill, minmax(calc(16.66667% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_6 {
    grid-template-columns: repeat(auto-fill, minmax(calc(16.66667% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_6 {
    grid-template-columns: repeat(auto-fill, minmax(calc(16.66667% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_6 {
    grid-template-columns: repeat(auto-fill, minmax(calc(16.66667% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_6 {
    grid-template-columns: repeat(auto-fill, minmax(calc(16.66667% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_6 {
    grid-template-columns: repeat(auto-fill, minmax(calc(16.66667% - var(--space_la)), 1fr)); } }

.grid_7 {
  grid-template-columns: repeat(auto-fill, minmax(calc(14.28571% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_7 {
    grid-template-columns: repeat(auto-fill, minmax(calc(14.28571% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_7 {
    grid-template-columns: repeat(auto-fill, minmax(calc(14.28571% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_7 {
    grid-template-columns: repeat(auto-fill, minmax(calc(14.28571% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_7 {
    grid-template-columns: repeat(auto-fill, minmax(calc(14.28571% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_7 {
    grid-template-columns: repeat(auto-fill, minmax(calc(14.28571% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_7 {
    grid-template-columns: repeat(auto-fill, minmax(calc(14.28571% - var(--space_la)), 1fr)); } }

.grid_8 {
  grid-template-columns: repeat(auto-fill, minmax(calc(12.5% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_8 {
    grid-template-columns: repeat(auto-fill, minmax(calc(12.5% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_8 {
    grid-template-columns: repeat(auto-fill, minmax(calc(12.5% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_8 {
    grid-template-columns: repeat(auto-fill, minmax(calc(12.5% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_8 {
    grid-template-columns: repeat(auto-fill, minmax(calc(12.5% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_8 {
    grid-template-columns: repeat(auto-fill, minmax(calc(12.5% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_8 {
    grid-template-columns: repeat(auto-fill, minmax(calc(12.5% - var(--space_la)), 1fr)); } }

.grid_9 {
  grid-template-columns: repeat(auto-fill, minmax(calc(11.11111% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_9 {
    grid-template-columns: repeat(auto-fill, minmax(calc(11.11111% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_9 {
    grid-template-columns: repeat(auto-fill, minmax(calc(11.11111% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_9 {
    grid-template-columns: repeat(auto-fill, minmax(calc(11.11111% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_9 {
    grid-template-columns: repeat(auto-fill, minmax(calc(11.11111% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_9 {
    grid-template-columns: repeat(auto-fill, minmax(calc(11.11111% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_9 {
    grid-template-columns: repeat(auto-fill, minmax(calc(11.11111% - var(--space_la)), 1fr)); } }

.grid_10 {
  grid-template-columns: repeat(auto-fill, minmax(calc(10% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_10 {
    grid-template-columns: repeat(auto-fill, minmax(calc(10% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_10 {
    grid-template-columns: repeat(auto-fill, minmax(calc(10% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_10 {
    grid-template-columns: repeat(auto-fill, minmax(calc(10% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_10 {
    grid-template-columns: repeat(auto-fill, minmax(calc(10% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_10 {
    grid-template-columns: repeat(auto-fill, minmax(calc(10% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_10 {
    grid-template-columns: repeat(auto-fill, minmax(calc(10% - var(--space_la)), 1fr)); } }

.grid_11 {
  grid-template-columns: repeat(auto-fill, minmax(calc(9.09091% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_11 {
    grid-template-columns: repeat(auto-fill, minmax(calc(9.09091% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_11 {
    grid-template-columns: repeat(auto-fill, minmax(calc(9.09091% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_11 {
    grid-template-columns: repeat(auto-fill, minmax(calc(9.09091% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_11 {
    grid-template-columns: repeat(auto-fill, minmax(calc(9.09091% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_11 {
    grid-template-columns: repeat(auto-fill, minmax(calc(9.09091% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_11 {
    grid-template-columns: repeat(auto-fill, minmax(calc(9.09091% - var(--space_la)), 1fr)); } }

.grid_12 {
  grid-template-columns: repeat(auto-fill, minmax(calc(8.33333% - var(--space_la)), 1fr)); }

@media all and (min-width: 640px) {
  .s\:grid_12 {
    grid-template-columns: repeat(auto-fill, minmax(calc(8.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 768px) {
  .ms\:grid_12 {
    grid-template-columns: repeat(auto-fill, minmax(calc(8.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 960px) {
  .m\:grid_12 {
    grid-template-columns: repeat(auto-fill, minmax(calc(8.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1280px) {
  .lx\:grid_12 {
    grid-template-columns: repeat(auto-fill, minmax(calc(8.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1440px) {
  .x\:grid_12 {
    grid-template-columns: repeat(auto-fill, minmax(calc(8.33333% - var(--space_la)), 1fr)); } }

@media all and (min-width: 1680px) {
  .xl\:grid_12 {
    grid-template-columns: repeat(auto-fill, minmax(calc(8.33333% - var(--space_la)), 1fr)); } }

.op_00 {
  opacity: 0; }

.op_05 {
  opacity: .5; }

.op_07 {
  opacity: .7; }

.op_10 {
  opacity: 1; }

@media all and (min-width: 640px) {
  .s\:op_00 {
    opacity: 0; }
  .s\:op_05 {
    opacity: .5; }
  .s\:op_07 {
    opacity: .7; }
  .s\:op_10 {
    opacity: 1; } }

@media all and (min-width: 768px) {
  .ms\:op_00 {
    opacity: 0; }
  .ms\:op_05 {
    opacity: .5; }
  .ms\:op_07 {
    opacity: .7; }
  .ms\:op_10 {
    opacity: 1; } }

@media all and (min-width: 960px) {
  .m\:op_00 {
    opacity: 0; }
  .m\:op_05 {
    opacity: .5; }
  .m\:op_07 {
    opacity: .7; }
  .m\:op_10 {
    opacity: 1; } }

.lh_no {
  line-height: 0; }

.lh_sm {
  line-height: 1.3; }

.lh_base {
  line-height: 1.6; }

.lh_me {
  line-height: 1.9; }

.ta_left {
  text-align: left; }

.ta_center {
  text-align: center; }

.ta_right {
  text-align: right; }

.ta_justify {
  text-align: justify; }

@media all and (min-width: 768px) {
  .ms\:ta_left {
    text-align: left; }
  .ms\:ta_center {
    text-align: center; }
  .ms\:ta_right {
    text-align: right; } }

@media all and (min-width: 960px) {
  .m\:ta_left {
    text-align: left; }
  .m\:ta_center {
    text-align: center; }
  .m\:ta_right {
    text-align: right; } }

.uppercase {
  text-transform: uppercase; }

.fw_l {
  font-weight: var(--fw_l); }

.fw_r {
  font-weight: var(--fw_r); }

.fw_m {
  font-weight: var(--fw_m); }

.fw_b {
  font-weight: var(--fw_b); }

.fy_i {
  font-style: italic; }

.ff_jp_2 {
  font-family: var(--ff_jp_2); }

.ff_ss {
  font-family: var(--ff); }

.ff_s {
  font-family: serif; }

.fs_ss {
  font-size: calc(var(--base) - 4px); }

.fs_sm {
  font-size: calc(var(--base) - 2px); }

.fs_base {
  font-size: var(--base); }

.fs_me {
  font-size: calc(var(--base) * 1.2); }

.fs_la {
  font-size: calc(var(--base) * 1.4); }

.fs_2la {
  font-size: calc(var(--base) * 1.6); }

.fs_xl {
  font-size: calc(var(--base) * 2); }

.fs_2xl {
  font-size: calc(var(--base) * 3.6); }

.fs_3xl {
  font-size: calc(var(--base) * 4.8); }

.fs_4xl {
  font-size: calc(var(--base) * 6); }

@media all and (min-width: 768px) {
  .ms\:fs_ss {
    font-size: calc(var(--base) - 4px); }
  .ms\:fs_sm {
    font-size: calc(var(--base) - 2px); }
  .ms\:fs_base {
    font-size: var(--base); }
  .ms\:fs_me {
    font-size: calc(var(--base) * 1.2); }
  .ms\:fs_la {
    font-size: calc(var(--base) * 1.4); }
  .ms\:fs_xl {
    font-size: calc(var(--base) * 2); }
  .ms\:fs_2xl {
    font-size: calc(var(--base) * 3.6); }
  .ms\:fs_3xl {
    font-size: calc(var(--base) * 4.8); }
  .ms\:fs_4xl {
    font-size: calc(var(--base) * 6); } }

@media all and (min-width: 960px) {
  .m\:fs_ss {
    font-size: calc(var(--base) - 4px); }
  .m\:fs_sm {
    font-size: calc(var(--base) - 2px); }
  .m\:fs_base {
    font-size: var(--base); }
  .m\:fs_me {
    font-size: calc(var(--base) * 1.2); }
  .m\:fs_la {
    font-size: calc(var(--base) * 1.4); }
  .m\:fs_xl {
    font-size: calc(var(--base) * 2); }
  .m\:fs_2xl {
    font-size: calc(var(--base) * 3.6); }
  .m\:fs_3xl {
    font-size: calc(var(--base) * 4.8); }
  .m\:fs_4xl {
    font-size: calc(var(--base) * 6); } }

.ls_0 {
  letter-spacing: 0px; }

.ls_1 {
  letter-spacing: 0.5px; }

.ls_2 {
  letter-spacing: 1px; }

.ls_3 {
  letter-spacing: 1.5px; }

.ls_4 {
  letter-spacing: 2px; }

.ls_5 {
  letter-spacing: 2.5px; }

.ls_6 {
  letter-spacing: 3px; }

.ls_7 {
  letter-spacing: 3.5px; }

.ls_8 {
  letter-spacing: 4px; }

.ls_9 {
  letter-spacing: 4.5px; }

.c_pointer {
  cursor: pointer; }

.c_e_resize {
  cursor: e-resize; }

.c_s_resize {
  cursor: s-resize; }

.pe_on {
  pointer-events: all; }

.pe_off {
  pointer-events: none; }

@media all and (min-width: 768px) {
  .ms\:pe_on {
    pointer-events: all; }
  .ms\:pe_off {
    pointer-events: none; } }

@media all and (min-width: 960px) {
  .m\:pe_on {
    pointer-events: all; }
  .m\:pe_off {
    pointer-events: none; } }

.text_shadow {
  text-shadow: 0px 0px var(--space_la) var(--clr_gray_darken); }

.border_0 {
  border-width: 0px; }

.border_x_0 {
  border-left: 0px;
  border-right: 0px; }

.border_y_0 {
  border-top: 0px;
  border-bottom: 0px; }

.border_top_0 {
  border-top: 0px; }

.border_right_0 {
  border-right: 0px; }

.border_bottom_0 {
  border-bottom: 0px; }

.border_left_0 {
  border-left: 0px; }

.border_1 {
  border-width: 1px; }

.border_x_1 {
  border-left: 1px;
  border-right: 1px; }

.border_y_1 {
  border-top: 1px;
  border-bottom: 1px; }

.border_top_1 {
  border-top: 1px; }

.border_right_1 {
  border-right: 1px; }

.border_bottom_1 {
  border-bottom: 1px; }

.border_left_1 {
  border-left: 1px; }

.border_2 {
  border-width: 2px; }

.border_x_2 {
  border-left: 2px;
  border-right: 2px; }

.border_y_2 {
  border-top: 2px;
  border-bottom: 2px; }

.border_top_2 {
  border-top: 2px; }

.border_right_2 {
  border-right: 2px; }

.border_bottom_2 {
  border-bottom: 2px; }

.border_left_2 {
  border-left: 2px; }

.border_3 {
  border-width: 3px; }

.border_x_3 {
  border-left: 3px;
  border-right: 3px; }

.border_y_3 {
  border-top: 3px;
  border-bottom: 3px; }

.border_top_3 {
  border-top: 3px; }

.border_right_3 {
  border-right: 3px; }

.border_bottom_3 {
  border-bottom: 3px; }

.border_left_3 {
  border-left: 3px; }

.border_solid {
  border-style: solid; }

.border_dotted {
  border-style: dotted; }

.border_dashed {
  border-style: dashed; }

.border_none {
  border-style: none; }

.border_radius_ss {
  border-radius: 3px; }

.border_radius_sm {
  border-radius: calc(var(--base) * .5); }

.border_radius_base {
  border-radius: var(--base); }

.border_radius_me {
  border-radius: calc(var(--base) * 1.5); }

.border_radius_la {
  border-radius: calc(var(--base) * 2); }

.border_radius_circle {
  border-radius: 50%; }

.text_off {
  color: var(--clr_off); }

.border_off {
  border-color: var(--clr_off); }

.bg_off {
  background-color: var(--clr_off); }

.text_white {
  color: var(--clr_white); }

.border_white {
  border-color: var(--clr_white); }

.bg_white {
  background-color: var(--clr_white); }

.text_white_opacity {
  color: var(--clr_white_opacity); }

.border_white_opacity {
  border-color: var(--clr_white_opacity); }

.bg_white_opacity {
  background-color: var(--clr_white_opacity); }

.text_gray_lighten {
  color: var(--clr_gray_lighten); }

.border_gray_lighten {
  border-color: var(--clr_gray_lighten); }

.bg_gray_lighten {
  background-color: var(--clr_gray_lighten); }

.text_gray {
  color: var(--clr_gray); }

.border_gray {
  border-color: var(--clr_gray); }

.bg_gray {
  background-color: var(--clr_gray); }

.text_gray_darken {
  color: var(--clr_gray_darken); }

.border_gray_darken {
  border-color: var(--clr_gray_darken); }

.bg_gray_darken {
  background-color: var(--clr_gray_darken); }

.text_black {
  color: var(--clr_black); }

.border_black {
  border-color: var(--clr_black); }

.bg_black {
  background-color: var(--clr_black); }

.text_black_opacity {
  color: var(--clr_black_opacity); }

.border_black_opacity {
  border-color: var(--clr_black_opacity); }

.bg_black_opacity {
  background-color: var(--clr_black_opacity); }

.text_pri {
  color: var(--clr_pri); }

.border_pri {
  border-color: var(--clr_pri); }

.bg_pri {
  background-color: var(--clr_pri); }

.text_pri_darken {
  color: var(--clr_pri_darken); }

.border_pri_darken {
  border-color: var(--clr_pri_darken); }

.bg_pri_darken {
  background-color: var(--clr_pri_darken); }

.text_sec {
  color: var(--clr_sec); }

.border_sec {
  border-color: var(--clr_sec); }

.bg_sec {
  background-color: var(--clr_sec); }

.text_sec_darken {
  color: var(--clr_sec_darken); }

.border_sec_darken {
  border-color: var(--clr_sec_darken); }

.bg_sec_darken {
  background-color: var(--clr_sec_darken); }

.text_ter {
  color: var(--clr_ter); }

.border_ter {
  border-color: var(--clr_ter); }

.bg_ter {
  background-color: var(--clr_ter); }

.text_ter_darken {
  color: var(--clr_ter_darken); }

.border_ter_darken {
  border-color: var(--clr_ter_darken); }

.bg_ter_darken {
  background-color: var(--clr_ter_darken); }

@media all and (min-width: 768px) {
  .ms\:border_0 {
    border: 0px solid; }
  .ms\:border_top_0 {
    border-top: 0px solid; }
  .ms\:border_right_0 {
    border-right: 0px solid; }
  .ms\:border_bottom_0 {
    border-bottom: 0px solid; }
  .ms\:border_left_0 {
    border-left: 0px solid; }
  .ms\:border_1 {
    border: 1px solid; }
  .ms\:border_top_1 {
    border-top: 1px solid; }
  .ms\:border_right_1 {
    border-right: 1px solid; }
  .ms\:border_bottom_1 {
    border-bottom: 1px solid; }
  .ms\:border_left_1 {
    border-left: 1px solid; }
  .ms\:border_2 {
    border: 2px solid; }
  .ms\:border_top_2 {
    border-top: 2px solid; }
  .ms\:border_right_2 {
    border-right: 2px solid; }
  .ms\:border_bottom_2 {
    border-bottom: 2px solid; }
  .ms\:border_left_2 {
    border-left: 2px solid; }
  .ms\:border_3 {
    border: 3px solid; }
  .ms\:border_top_3 {
    border-top: 3px solid; }
  .ms\:border_right_3 {
    border-right: 3px solid; }
  .ms\:border_bottom_3 {
    border-bottom: 3px solid; }
  .ms\:border_left_3 {
    border-left: 3px solid; }
  .ms\:text_off {
    color: var(--clr_off); }
  .ms\:border_off {
    border-color: var(--clr_off); }
  .ms\:bg_off {
    background-color: var(--clr_off); }
  .ms\:text_white {
    color: var(--clr_white); }
  .ms\:border_white {
    border-color: var(--clr_white); }
  .ms\:bg_white {
    background-color: var(--clr_white); }
  .ms\:text_white_opacity {
    color: var(--clr_white_opacity); }
  .ms\:border_white_opacity {
    border-color: var(--clr_white_opacity); }
  .ms\:bg_white_opacity {
    background-color: var(--clr_white_opacity); }
  .ms\:text_gray_lighten {
    color: var(--clr_gray_lighten); }
  .ms\:border_gray_lighten {
    border-color: var(--clr_gray_lighten); }
  .ms\:bg_gray_lighten {
    background-color: var(--clr_gray_lighten); }
  .ms\:text_gray {
    color: var(--clr_gray); }
  .ms\:border_gray {
    border-color: var(--clr_gray); }
  .ms\:bg_gray {
    background-color: var(--clr_gray); }
  .ms\:text_gray_darken {
    color: var(--clr_gray_darken); }
  .ms\:border_gray_darken {
    border-color: var(--clr_gray_darken); }
  .ms\:bg_gray_darken {
    background-color: var(--clr_gray_darken); }
  .ms\:text_black {
    color: var(--clr_black); }
  .ms\:border_black {
    border-color: var(--clr_black); }
  .ms\:bg_black {
    background-color: var(--clr_black); }
  .ms\:text_black_opacity {
    color: var(--clr_black_opacity); }
  .ms\:border_black_opacity {
    border-color: var(--clr_black_opacity); }
  .ms\:bg_black_opacity {
    background-color: var(--clr_black_opacity); }
  .ms\:text_pri {
    color: var(--clr_pri); }
  .ms\:border_pri {
    border-color: var(--clr_pri); }
  .ms\:bg_pri {
    background-color: var(--clr_pri); }
  .ms\:text_pri_darken {
    color: var(--clr_pri_darken); }
  .ms\:border_pri_darken {
    border-color: var(--clr_pri_darken); }
  .ms\:bg_pri_darken {
    background-color: var(--clr_pri_darken); }
  .ms\:text_sec {
    color: var(--clr_sec); }
  .ms\:border_sec {
    border-color: var(--clr_sec); }
  .ms\:bg_sec {
    background-color: var(--clr_sec); }
  .ms\:text_sec_darken {
    color: var(--clr_sec_darken); }
  .ms\:border_sec_darken {
    border-color: var(--clr_sec_darken); }
  .ms\:bg_sec_darken {
    background-color: var(--clr_sec_darken); }
  .ms\:text_ter {
    color: var(--clr_ter); }
  .ms\:border_ter {
    border-color: var(--clr_ter); }
  .ms\:bg_ter {
    background-color: var(--clr_ter); }
  .ms\:text_ter_darken {
    color: var(--clr_ter_darken); }
  .ms\:border_ter_darken {
    border-color: var(--clr_ter_darken); }
  .ms\:bg_ter_darken {
    background-color: var(--clr_ter_darken); } }

@media all and (min-width: 960px) {
  .m\:border_0 {
    border: 0px solid; }
  .m\:border_top_0 {
    border-top: 0px solid; }
  .m\:border_right_0 {
    border-right: 0px solid; }
  .m\:border_bottom_0 {
    border-bottom: 0px solid; }
  .m\:border_left_0 {
    border-left: 0px solid; }
  .m\:border_1 {
    border: 1px solid; }
  .m\:border_top_1 {
    border-top: 1px solid; }
  .m\:border_right_1 {
    border-right: 1px solid; }
  .m\:border_bottom_1 {
    border-bottom: 1px solid; }
  .m\:border_left_1 {
    border-left: 1px solid; }
  .m\:border_2 {
    border: 2px solid; }
  .m\:border_top_2 {
    border-top: 2px solid; }
  .m\:border_right_2 {
    border-right: 2px solid; }
  .m\:border_bottom_2 {
    border-bottom: 2px solid; }
  .m\:border_left_2 {
    border-left: 2px solid; }
  .m\:border_3 {
    border: 3px solid; }
  .m\:border_top_3 {
    border-top: 3px solid; }
  .m\:border_right_3 {
    border-right: 3px solid; }
  .m\:border_bottom_3 {
    border-bottom: 3px solid; }
  .m\:border_left_3 {
    border-left: 3px solid; }
  .m\:text_off {
    color: var(--clr_off); }
  .m\:border_off {
    border-color: var(--clr_off); }
  .m\:bg_off {
    background-color: var(--clr_off); }
  .m\:text_white {
    color: var(--clr_white); }
  .m\:border_white {
    border-color: var(--clr_white); }
  .m\:bg_white {
    background-color: var(--clr_white); }
  .m\:text_white_opacity {
    color: var(--clr_white_opacity); }
  .m\:border_white_opacity {
    border-color: var(--clr_white_opacity); }
  .m\:bg_white_opacity {
    background-color: var(--clr_white_opacity); }
  .m\:text_gray_lighten {
    color: var(--clr_gray_lighten); }
  .m\:border_gray_lighten {
    border-color: var(--clr_gray_lighten); }
  .m\:bg_gray_lighten {
    background-color: var(--clr_gray_lighten); }
  .m\:text_gray {
    color: var(--clr_gray); }
  .m\:border_gray {
    border-color: var(--clr_gray); }
  .m\:bg_gray {
    background-color: var(--clr_gray); }
  .m\:text_gray_darken {
    color: var(--clr_gray_darken); }
  .m\:border_gray_darken {
    border-color: var(--clr_gray_darken); }
  .m\:bg_gray_darken {
    background-color: var(--clr_gray_darken); }
  .m\:text_black {
    color: var(--clr_black); }
  .m\:border_black {
    border-color: var(--clr_black); }
  .m\:bg_black {
    background-color: var(--clr_black); }
  .m\:text_black_opacity {
    color: var(--clr_black_opacity); }
  .m\:border_black_opacity {
    border-color: var(--clr_black_opacity); }
  .m\:bg_black_opacity {
    background-color: var(--clr_black_opacity); }
  .m\:text_pri {
    color: var(--clr_pri); }
  .m\:border_pri {
    border-color: var(--clr_pri); }
  .m\:bg_pri {
    background-color: var(--clr_pri); }
  .m\:text_pri_darken {
    color: var(--clr_pri_darken); }
  .m\:border_pri_darken {
    border-color: var(--clr_pri_darken); }
  .m\:bg_pri_darken {
    background-color: var(--clr_pri_darken); }
  .m\:text_sec {
    color: var(--clr_sec); }
  .m\:border_sec {
    border-color: var(--clr_sec); }
  .m\:bg_sec {
    background-color: var(--clr_sec); }
  .m\:text_sec_darken {
    color: var(--clr_sec_darken); }
  .m\:border_sec_darken {
    border-color: var(--clr_sec_darken); }
  .m\:bg_sec_darken {
    background-color: var(--clr_sec_darken); }
  .m\:text_ter {
    color: var(--clr_ter); }
  .m\:border_ter {
    border-color: var(--clr_ter); }
  .m\:bg_ter {
    background-color: var(--clr_ter); }
  .m\:text_ter_darken {
    color: var(--clr_ter_darken); }
  .m\:border_ter_darken {
    border-color: var(--clr_ter_darken); }
  .m\:bg_ter_darken {
    background-color: var(--clr_ter_darken); } }

.filter_invert_0 {
  filter: invert(0); }

.filter_invert_1 {
  filter: invert(1); }

.transition_none {
  transition: none; }

.transition_02_linear {
  transition: .2s linear; }

.transition_04_linear {
  transition: .4s linear; }

.transition_08_linear {
  transition: .8s linear; }

.transition_12_linear {
  transition: 1.2s linear; }

.transition_16_linear {
  transition: 1.6s linear; }

.transition_02_expo {
  transition: 0.2s var(--trt_timing); }

.transition_04_expo {
  transition: 0.4s var(--trt_timing); }

.transition_06_expo {
  transition: 0.6s var(--trt_timing); }

.transition_08_expo {
  transition: 0.8s var(--trt_timing); }

.transition_12_expo {
  transition: 1.2s var(--trt_timing); }

.transition_16_expo {
  transition: 1.6s var(--trt_timing); }

.transition_02_out {
  transition: .2s ease-out; }

.transition_04_out {
  transition: .4s ease-out; }

.transition_06_out {
  transition: .6s ease-out; }

.transition_08_out {
  transition: .8s ease-out; }

.transition_12_out {
  transition: 1.2s ease-out; }

.transition_16_out {
  transition: 1.6s ease-out; }

@media all and (min-width: 768px) {
  .ms\:transition_02_linear {
    transition: .2s linear; }
  .ms\:transition_04_linear {
    transition: .4s linear; }
  .ms\:transition_08_linear {
    transition: .8s linear; }
  .ms\:transition_12_linear {
    transition: 1.2s linear; }
  .ms\:transition_16_linear {
    transition: 1.6s linear; }
  .ms\:transition_02_expo {
    transition: 0.2s var(--trt_timing); }
  .ms\:transition_04_expo {
    transition: 0.4s var(--trt_timing); }
  .ms\:transition_06_expo {
    transition: 0.6s var(--trt_timing); }
  .ms\:transition_08_expo {
    transition: 0.8s var(--trt_timing); }
  .ms\:transition_12_expo {
    transition: 1.2s var(--trt_timing); }
  .ms\:transition_16_expo {
    transition: 1.6s var(--trt_timing); }
  .ms\:transition_02_out {
    transition: .2s ease-out; }
  .ms\:transition_04_out {
    transition: .4s ease-out; }
  .ms\:transition_06_out {
    transition: .6s ease-out; }
  .ms\:transition_08_out {
    transition: .8s ease-out; }
  .ms\:transition_12_out {
    transition: 1.2s ease-out; }
  .ms\:transition_16_out {
    transition: 1.6s ease-out; } }

.translate_x_0 {
  transform: translateX(0px); }

.translate_y_0 {
  transform: translateY(0px); }

.translate_x_0 {
  transform: translateX(0%); }

.translate_y_0 {
  transform: translateY(0%); }

.translate_x_1 {
  transform: translateX(1px); }

.translate_y_1 {
  transform: translateY(1px); }

.translate_x_10 {
  transform: translateX(10%); }

.translate_y_10 {
  transform: translateY(10%); }

.translate_x_2 {
  transform: translateX(2px); }

.translate_y_2 {
  transform: translateY(2px); }

.translate_x_20 {
  transform: translateX(20%); }

.translate_y_20 {
  transform: translateY(20%); }

.translate_x_3 {
  transform: translateX(3px); }

.translate_y_3 {
  transform: translateY(3px); }

.translate_x_30 {
  transform: translateX(30%); }

.translate_y_30 {
  transform: translateY(30%); }

.translate_x_4 {
  transform: translateX(4px); }

.translate_y_4 {
  transform: translateY(4px); }

.translate_x_40 {
  transform: translateX(40%); }

.translate_y_40 {
  transform: translateY(40%); }

.translate_x_5 {
  transform: translateX(5px); }

.translate_y_5 {
  transform: translateY(5px); }

.translate_x_50 {
  transform: translateX(50%); }

.translate_y_50 {
  transform: translateY(50%); }

.translate_x_6 {
  transform: translateX(6px); }

.translate_y_6 {
  transform: translateY(6px); }

.translate_x_60 {
  transform: translateX(60%); }

.translate_y_60 {
  transform: translateY(60%); }

.translate_x_7 {
  transform: translateX(7px); }

.translate_y_7 {
  transform: translateY(7px); }

.translate_x_70 {
  transform: translateX(70%); }

.translate_y_70 {
  transform: translateY(70%); }

.translate_x_8 {
  transform: translateX(8px); }

.translate_y_8 {
  transform: translateY(8px); }

.translate_x_80 {
  transform: translateX(80%); }

.translate_y_80 {
  transform: translateY(80%); }

.translate_x_9 {
  transform: translateX(9px); }

.translate_y_9 {
  transform: translateY(9px); }

.translate_x_90 {
  transform: translateX(90%); }

.translate_y_90 {
  transform: translateY(90%); }

.translate_x_10 {
  transform: translateX(10px); }

.translate_y_10 {
  transform: translateY(10px); }

.translate_x_100 {
  transform: translateX(100%); }

.translate_y_100 {
  transform: translateY(100%); }

.translate_x-0 {
  transform: translateX(-0px); }

.translate_y-0 {
  transform: translateY(-0px); }

.translate_x-0 {
  transform: translateX(0%); }

.translate_y-0 {
  transform: translateY(0%); }

.translate_x-1 {
  transform: translateX(-1px); }

.translate_y-1 {
  transform: translateY(-1px); }

.translate_x-10 {
  transform: translateX(-10%); }

.translate_y-10 {
  transform: translateY(-10%); }

.translate_x-2 {
  transform: translateX(-2px); }

.translate_y-2 {
  transform: translateY(-2px); }

.translate_x-20 {
  transform: translateX(-20%); }

.translate_y-20 {
  transform: translateY(-20%); }

.translate_x-3 {
  transform: translateX(-3px); }

.translate_y-3 {
  transform: translateY(-3px); }

.translate_x-30 {
  transform: translateX(-30%); }

.translate_y-30 {
  transform: translateY(-30%); }

.translate_x-4 {
  transform: translateX(-4px); }

.translate_y-4 {
  transform: translateY(-4px); }

.translate_x-40 {
  transform: translateX(-40%); }

.translate_y-40 {
  transform: translateY(-40%); }

.translate_x-5 {
  transform: translateX(-5px); }

.translate_y-5 {
  transform: translateY(-5px); }

.translate_x-50 {
  transform: translateX(-50%); }

.translate_y-50 {
  transform: translateY(-50%); }

.translate_x-6 {
  transform: translateX(-6px); }

.translate_y-6 {
  transform: translateY(-6px); }

.translate_x-60 {
  transform: translateX(-60%); }

.translate_y-60 {
  transform: translateY(-60%); }

.translate_x-7 {
  transform: translateX(-7px); }

.translate_y-7 {
  transform: translateY(-7px); }

.translate_x-70 {
  transform: translateX(-70%); }

.translate_y-70 {
  transform: translateY(-70%); }

.translate_x-8 {
  transform: translateX(-8px); }

.translate_y-8 {
  transform: translateY(-8px); }

.translate_x-80 {
  transform: translateX(-80%); }

.translate_y-80 {
  transform: translateY(-80%); }

.translate_x-9 {
  transform: translateX(-9px); }

.translate_y-9 {
  transform: translateY(-9px); }

.translate_x-90 {
  transform: translateX(-90%); }

.translate_y-90 {
  transform: translateY(-90%); }

.translate_x-10 {
  transform: translateX(-10px); }

.translate_y-10 {
  transform: translateY(-10px); }

.translate_x-100 {
  transform: translateX(-100%); }

.translate_y-100 {
  transform: translateY(-100%); }

.logo_light {
  background-image: url("../src/images/logo_light.svg");
  filter: drop-shadow(1px 1px 0 var(--clr_black)); }
  @media all and (min-width: 960px) {
    .logo_light {
      filter: drop-shadow(2px 2px 0 var(--clr_black)); } }

.logo_dark {
  background-image: url("../src/images/logo_dark.svg");
  filter: drop-shadow(1px 1px 0 var(--clr_white)); }
  @media all and (min-width: 960px) {
    .logo_dark {
      filter: drop-shadow(2px 2px 0 var(--clr_white)); } }

.logo svg {
  transform: translateY(4px); }

.text_hide {
  font-size: 0;
  color: transparent; }

.full_page_box {
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%; }
  .full_page_box .full_page_inner {
    position: absolute;
    top: 50%;
    left: 50%;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    transform: translate(-50%, -50%); }

.screen_inner {
  box-sizing: border-box;
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max_screen_inner);
  padding-left: var(--screen_inset);
  padding-right: var(--screen_inset); }
  .screen_inner .over_inner {
    margin-left: calc((50vw - var(--max_screen_inner) / 2) * -1); }
  .screen_inner .over_left {
    margin-left: calc((50vw - var(--max_screen_inner) / 2) * -1); }
  .screen_inner .over_right {
    margin-right: calc((50vw - var(--max_screen_inner) / 2) * -1); }

.content_inner {
  margin-left: auto;
  margin-right: auto;
  max-width: var(--max_content_inner);
  padding-left: var(--screen_inset);
  padding-right: var(--screen_inset); }
  .content_inner .over_inner {
    margin-left: calc((50vw - var(--max_content_inner) / 2) * -1); }
  .content_inner .over_left {
    margin-left: calc((50vw - var(--max_content_inner) / 2) * -1); }
  .content_inner .over_right {
    margin-right: calc((50vw - var(--max_content_inner) / 2) * -1); }

.screen_cover {
  width: 100%;
  height: 100vh; }

.box_overlay {
  overflow: hidden;
  position: relative; }
  .box_overlay *[class*=content_front] {
    position: absolute;
    z-index: 20; }
    .box_overlay *[class*=content_front][class*=_top] {
      top: 0;
      left: 0;
      right: 0; }
    .box_overlay *[class*=content_front][class*=_left] {
      top: 0;
      left: 0;
      bottom: 0; }
    .box_overlay *[class*=content_front][class*=_bottom] {
      left: 0;
      right: 0;
      bottom: 0; }
    .box_overlay *[class*=content_front][class*=_right] {
      top: 0;
      right: 0;
      bottom: 0; }
    .box_overlay *[class*=content_front][class*=_cover] {
      top: 0;
      left: 0;
      bottom: 0;
      right: 0; }
    .box_overlay *[class*=content_front][class*=_dark] {
      background: linear-gradient(180deg, transparent, var(--clr_black_opacity)); }
  .box_overlay *[class*=content_back] {
    position: relative;
    z-index: 10;
    height: 100%; }

.stagger_list *[class*=_item]:not(:last-child) {
  margin-bottom: var(--space_2la);
  padding-bottom: var(--space_2la);
  border-bottom: 1px solid var(--clr_gray_lighten); }

.wrap {
  width: 100%;
  position: relative; }
  .wrap .container {
    position: relative;
    z-index: 10;
    opacity: 1;
    transition: opacity 1s; }
    .wrap .container:nth-child(2) {
      z-index: 20; }
    .wrap .container.loading {
      opacity: 0; }

section {
  position: relative;
  z-index: 20;
  opacity: 0;
  filter: grayscale(1);
  transition: 1.6s var(--trt_timing); }
  section:not(:first-child) {
    transform: translateY(var(--space_xl)); }
  section.enter {
    opacity: 1;
    filter: grayscale(0);
    transform: translateY(0); }

.fadein_item {
  opacity: 0;
  transition: 1.6s var(--trt_timing); }
  .fadein_item:not(:first-child) {
    transform: translateY(var(--space_xl)); }
  .fadein_item.enter {
    opacity: 1;
    transform: translateY(0); }

.fade_hidden {
  opacity: 0;
  pointer-events: none; }

.fade_show {
  opacity: 1;
  pointer-events: all; }

@media all and (min-width: 768px) {
  .ms\:fade_show {
    opacity: 1;
    pointer-events: all; } }

@media all and (min-width: 960px) {
  .m\:fade_show {
    opacity: 1;
    pointer-events: all; } }

.box_style_dark {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid var(--clr_black);
  border-radius: var(--space_base);
  box-shadow: 3px 3px 0 0 var(--clr_black); }

.box_style_dark_s {
  background-color: rgba(255, 255, 255, 0.9);
  border: 2px solid var(--clr_black);
  border-radius: var(--space_sm);
  box-shadow: 3px 3px 0 0 var(--clr_black); }

.box_style_light {
  background-color: rgba(255, 255, 255, 0.2);
  border: 2px solid var(--clr_white);
  border-radius: var(--space_me); }

.backdrop_blur {
  backdrop-filter: blur(3px) saturate(1);
  -webkit-backdrop-filter: blur(3px) saturate(1); }

.mix_blend_difference {
  mix-blend-mode: difference; }

.hover_light {
  transition: .1s ease-out; }
  .hover_light:hover {
    opacity: .7; }

.main_box_style {
  background-color: var(--clr_white);
  border: 2px solid var(--clr_black);
  border-radius: var(--space_me);
  box-shadow: 0 2px 0 0 var(--clr_black);
  transition: .1s ease-out; }
  .main_box_style:hover {
    transform: translateY(2px);
    box-shadow: 0 0 0 0 var(--clr_black); }

@font-face {
  font-family: 'FontAwesome';
  src: url("../src/fonts/fontawesome-webfont.eot?v=4.3.1");
  src: url("../src/fonts/fontawesome-webfont.eot?#iefix?v=4.3.1") format("embedded-opentype"), url("../src/fonts/fontawesome-webfont.woff2?v=4.3.1") format("woff2"), url("../src/fonts/fontawesome-webfont.woff?v=4.3.1") format("woff"), url("../src/fonts/fontawesome-webfont.ttf?v=4.3.1") format("truetype"), url("../src/fonts/fontawesome-webfont.svg?v=4.3.1#fontawesomeregular") format("svg");
  font-weight: normal;
  font-style: normal; }

.fa {
  display: inline-block;
  font: normal normal normal 14px/.8 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0); }

.fa-play:before {
  content: "\f04b"; }

i[class*=icon] {
  display: inline-block;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat; }

.logo_gamania_cheepup_gonext {
  background-image: url(../src/images/logo_gamania_cheepup_gonext.svg); }

.ss_logo {
  background-image: url(../src/images/ss_logo_s2.svg); }

@media all and (min-width: 768px) {
  .ss_logo {
    background-image: url(../src/images/ss_logo.svg); } }

.icon_facebook {
  background-image: url(../src/images/icon/icon_facebook.svg); }

.icon_instagram {
  background-image: url(../src/images/icon/icon_instagram.svg); }

.icon_road_a {
  background-image: url(../src/images/map/icon_road_a.svg); }

.icon_road_b {
  background-image: url(../src/images/map/icon_road_b.svg); }

.icon_road_c {
  background-image: url(../src/images/map/icon_road_c.svg); }

.icon_road_d {
  background-image: url(../src/images/map/icon_road_d.svg); }

.icon_map_guide {
  background-image: url(../src/images/map/icon_map_guide2.svg); }

.icon_map_event_note {
  background-image: url(../src/images/map/icon_map_event_note2.svg); }

.icon_map_record {
  background-image: url(../src/images/map/icon_map_record2.svg); }

.icon_safari {
  background-image: url(../src/images/map/icon_safari.svg); }

.icon_arrow_left {
  background-image: url(../src/images/map/icon_arrow_left.svg); }

.icon_arrow_right {
  background-image: url(../src/images/map/icon_arrow_right.svg); }

.hint_desktop {
  background-image: url(../src/images/map/hint_desktop_more.svg); }

.hint_mobile {
  background-image: url(../src/images/map/hint_mobile_more.svg); }

.fa-facebook:before {
  content: "\f09a"; }

.fa-google:before {
  content: "\f1a0"; }

.fa-twitter:before {
  content: "\f099"; }

.fa-linkedin:before {
  content: "\f0e1"; }

.fa-youtube:before {
  content: "\f167"; }

.fa-youtube-play:before {
  content: "\f16a"; }

.fa-flickr:before {
  content: "\f16e"; }

.fa-book:before {
  content: "\f02d"; }

.fa-search:before {
  content: "\f002"; }

.fa-envelope-o:before {
  content: "\f003"; }

.fa-location-arrow:before {
  content: "\f124"; }

.fa-clock-o:before {
  content: "\f017"; }

.fa-home:before {
  content: "\f015"; }

.fa-file-pdf-o:before {
  content: "\f1c1"; }

.fa-file-text-o:before {
  content: "\f0f6"; }

.fa-download:before {
  content: "\f019"; }

.fa-user-o:before {
  content: "\f2c0"; }

.fa-user-circle-o:before {
  content: "\f2be"; }

.fa-angle-up:before {
  content: "\f106"; }

.fa-angle-right:before {
  content: "\f105"; }

.fa-angle-down:before {
  content: "\f107"; }

.fa-angle-left:before {
  content: "\f104"; }

.fa-ticket:before {
  content: "\f145"; }

.fa-play:before {
  content: "\f04b"; }

.fa-pause:before {
  content: "\f04c"; }

hr {
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid var(--clr_gray); }

select {
  display: block;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  background-color: white;
  font-family: var(--ff);
  font-size: calc(var(--base) + 2px);
  text-align: center;
  padding: var(--space_sm) 0;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid var(--clr_gray);
  background-color: transparent;
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s; }
  select:focus {
    outline: none; }

input, textarea {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -o-transition: 0.3s;
  transition: 0.3s;
  display: block;
  width: 100%;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0;
  background-color: white;
  font-family: var(--ff);
  font-size: calc(var(--base) + 2px); }
  input:focus, textarea:focus {
    outline: none; }
  input::placeholder, textarea::placeholder {
    color: var(--clr_gray); }
  input:focus.border_bottom, textarea:focus.border_bottom {
    border-bottom: 2px solid var(--clr_black); }
  input.border_bottom, textarea.border_bottom {
    padding: var(--space_sm) 0;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 2px solid var(--clr_gray);
    background-color: transparent; }

.rememberme_label {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  justify-content: center;
  margin-bottom: 0;
  line-height: 1;
  -webkit-transition: opacity 0.3s;
  -moz-transition: opacity 0.3s;
  -o-transition: opacity 0.3s;
  transition: opacity 0.3s; }
  .rememberme_label:hover {
    filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=75);
    opacity: 0.75; }
  .rememberme_label .checkbox_label {
    margin-left: .25rem;
    line-height: 1;
    font-weight: 500;
    color: var(--clr_gray_darken); }

input[type="checkbox"] {
  cursor: pointer;
  position: relative;
  margin: 0;
  width: var(--space_base);
  height: var(--space_base);
  padding: 0;
  -webkit-box-shadow: inset 0 0 0 2px var(--clr_gray);
  -moz-box-shadow: inset 0 0 0 2px var(--clr_gray);
  box-shadow: inset 0 0 0 2px var(--clr_gray);
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
  border-radius: 50%; }
  input[type="checkbox"]:after {
    content: '　';
    position: absolute;
    top: 0;
    left: 0;
    width: var(--space_base);
    height: var(--space_base);
    line-height: 1;
    font-size: var(--space_me);
    font-weight: 600;
    text-align: center;
    color: var(--clr_gray_darken);
    transform: translate(0px, -6px); }
  input[type="checkbox"]:checked:after {
    content: '✓'; }

.img_upload_box {
  position: relative;
  cursor: pointer; }
  .img_upload_box:hover .file_name {
    border: 2px solid var(--clr_black); }
  .img_upload_box input {
    position: absolute;
    z-index: 1;
    height: 100%;
    opacity: 0;
    cursor: pointer; }
  .img_upload_box .file_name {
    position: relative;
    z-index: 0;
    box-sizing: border-box;
    width: 100%;
    line-height: 1.5;
    padding: var(--space_sm);
    color: var(--clr_white);
    font-size: calc(var(--base) + 2px);
    font-weight: 600;
    text-align: center;
    letter-spacing: 2px;
    border: 2px solid var(--clr_gray);
    background-color: var(--clr_gray);
    border-radius: calc(var(--base) * .25);
    transition: .2s; }

.grid_item_group .grid_item {
  transition: .2s; }
  .grid_item_group .grid_item.in {
    background-color: black;
    color: white; }

*[class*=inner_article] {
  font-family: var(--ff);
  color: var(--clr_black); }
  *[class*=inner_article][class*=_sub] h1, *[class*=inner_article][class*=_sub] h2, *[class*=inner_article][class*=_sub] h3, *[class*=inner_article][class*=_sub] h4, *[class*=inner_article][class*=_sub] h5, *[class*=inner_article][class*=_sub] h6 {
    font-weight: var(--fw_m); }
  *[class*=inner_article][class*=_sub] p {
    font-weight: var(--fw_r); }
  *[class*=inner_article][class*=_sub] b, *[class*=inner_article][class*=_sub] strong {
    font-weight: var(--fw_m); }
  *[class*=inner_article][class*=_sub] ul li, *[class*=inner_article][class*=_sub] ol li {
    font-weight: var(--fw_r); }
  *[class*=inner_article] h1, *[class*=inner_article] h2, *[class*=inner_article] h3, *[class*=inner_article] h4, *[class*=inner_article] h5, *[class*=inner_article] h6 {
    display: block;
    margin-top: var(--fs);
    margin-bottom: var(--fs);
    width: 100%;
    line-height: var(--lh);
    font-weight: var(--fw_m);
    color: var(--clr_black); }
    *[class*=inner_article] h1:first-child, *[class*=inner_article] h2:first-child, *[class*=inner_article] h3:first-child, *[class*=inner_article] h4:first-child, *[class*=inner_article] h5:first-child, *[class*=inner_article] h6:first-child {
      margin-top: 0; }
  *[class*=inner_article] h3 {
    font-size: calc(var(--fs) * 1.4);
    letter-spacing: 1px; }
  *[class*=inner_article] h4 {
    font-size: calc(var(--fs) * 1.2);
    letter-spacing: 1px; }
  *[class*=inner_article] > *:first-child {
    margin-top: 0 !important; }
  *[class*=inner_article] p {
    display: block;
    width: 100%;
    margin-bottom: calc(var(--fs) * 1.25);
    line-height: var(--lh);
    font-size: var(--fs);
    font-weight: var(--fw_r); }
    *[class*=inner_article] p:last-child {
      margin-bottom: 0; }
  *[class*=inner_article] b, *[class*=inner_article] strong {
    font-size: var(--fs);
    font-weight: var(--fw_m);
    color: var(--clr_gray_darken); }
  *[class*=inner_article] a {
    display: inline;
    line-height: 1;
    font-weight: var(--fw_m);
    color: var(--clr_sec);
    text-decoration: none;
    border-bottom: 1px solid var(--clr_sec);
    -webkit-transition: 0.3s;
    -moz-transition: 0.3s;
    -o-transition: 0.3s;
    transition: 0.3s; }
    *[class*=inner_article] a:hover {
      filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
      opacity: 1;
      border-bottom: 1px solid transparent; }
  *[class*=inner_article] i:not(.fa) {
    font-style: italic; }
  *[class*=inner_article] em {
    font-size: 14px;
    vertical-align: top;
    line-height: 1; }
    *[class*=inner_article] em:before {
      content: "↑";
      vertical-align: top;
      display: inline-block;
      margin-right: .25rem;
      color: var(--clr_pri_opacity); }
  *[class*=inner_article] ul, *[class*=inner_article] ol {
    margin: var(--space_me) 0 var(--space_base) 0;
    padding-left: calc(var(--fs) * 1.5);
    font-size: var(--fs); }
    *[class*=inner_article] ul:last-child, *[class*=inner_article] ol:last-child {
      margin: var(--space_me) 0 0 0; }
    *[class*=inner_article] ul li, *[class*=inner_article] ol li {
      line-height: var(--lh);
      font-weight: var(--fw_r);
      list-style-type: square; }
  *[class*=inner_article] ol li {
    list-style-type: decimal; }
  *[class*=inner_article] hr {
    margin: var(--space_me) 0;
    border: 1px dotted var(--clr_gray);
    border-left: none;
    border-right: none;
    border-bottom: none; }
  *[class*=inner_article] blockquote {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    display: block;
    width: 100%;
    margin: var(--space_me) 0;
    padding: calc(var(--space_me) * .6) var(--space_me);
    line-height: 1.5;
    font-size: calc(var(--fs) * 1.1);
    font-weight: var(--fw_m);
    color: var(--clr_sec);
    background-color: var(--clr_bg); }
    *[class*=inner_article] blockquote p {
      margin-bottom: var(--space_me);
      font-weight: var(--fw_m);
      line-height: var(--lh);
      color: var(--clr_black); }
    *[class*=inner_article] blockquote strong {
      display: block;
      margin-top: var(--space_me);
      text-align: right;
      font-size: var(--fs);
      font-weight: var(--fw_m);
      color: var(--clr_black); }
      *[class*=inner_article] blockquote strong:before {
        content: "";
        display: inline-block;
        vertical-align: middle;
        width: .5rem;
        height: 1px;
        margin: 3px 8px 4px 0;
        background-color: var(--clr_black); }
  *[class*=inner_article] img {
    display: block;
    width: 100%;
    background-color: var(--clr_black);
    line-height: 1;
    text-align: center;
    color: var(--clr_black); }
  *[class*=inner_article] figcaption {
    margin-top: calc(var(--fs_s) * .5);
    line-height: 1.5;
    font-size: calc(var(--fs_s) - 2px);
    color: var(--clr_gray); }
  *[class*=inner_article] iframe {
    display: block;
    margin: var(--space_me) 0;
    width: 100%;
    height: calc(100vw / 2.04); }
  *[class*=inner_article] .photo {
    line-height: 1; }
    *[class*=inner_article] .photo img {
      margin: 0;
      border: 1px solid #e1e1e1; }
  *[class*=inner_article] .image_row {
    margin: var(--space_me) 0; }
  *[class*=inner_article] .row_area_m {
    margin: var(--space_me) 0; }
    *[class*=inner_article] .row_area_m .clr_1:first-child {
      margin-bottom: var(--space_me); }
  *[class*=inner_article] .slide_row {
    display: block;
    width: 100%; }
    *[class*=inner_article] .slide_row .slide_layout {
      display: flex; }

@media all and (min-width: 960px) {
  *[class*=inner_article] .image_row, *[class*=inner_article] .image_image_row {
    padding-right: var(--column); }
  .inner_article p {
    overflow-x: initial; }
  .inner_article .row_area_m {
    margin: var(--space_me) 0; }
    .inner_article .row_area_m .clr_1:first-child {
      margin-right: var(--space_me);
      margin-bottom: 0; } }

@media all and (min-width: 960px) {
  .sub_logo {
    filter: drop-shadow(0 2px 0 black); } }

.nav_header {
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, white 100%); }
  .nav_header.dark {
    background: transparent; }

.inner_footer {
  display: none; }
  .inner_footer.active {
    display: block; }

.hero_video.playing .play_btn {
  opacity: 0; }

.road_panel_wrap {
  pointer-events: none;
  overflow: hidden;
  opacity: 0; }

.road_panel_box {
  pointer-events: all;
  max-width: 18rem; }
  .road_panel_box .road_content {
    height: 0px;
    padding: 0 var(--space_me);
    opacity: 0; }
  .road_panel_box.active .road_content {
    height: auto;
    padding: var(--space_me);
    opacity: 1; }
  @media all and (min-width: 1680px) {
    .road_panel_box {
      max-width: 20rem; } }

.road_icon_list .list_item i {
  box-shadow: inset 0 0 0 0 transparent;
  transition: 0.4s var(--trt_timing); }
  .road_icon_list .list_item i:hover, .road_icon_list .list_item i.active {
    box-shadow: inset 0 0 0 4px var(--clr_gray); }

@media all and (min-width: 960px) {
  .road_post_wrap {
    margin: auto;
    max-width: 40rem;
    max-height: 48rem;
    bottom: -5rem; } }

.cloud_cover {
  transform: rotate(-10deg) scale(1.3); }
  .cloud_cover .cloud_item {
    background-image: url(../src/images/map/cloud_cover.png);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transform: translateX(-10%);
    animation: cloud_move_left 16s ease-in-out infinite forwards; }
  .cloud_cover .cloud_right {
    transform: rotate(180deg) translateX(-10%);
    animation: cloud_move_right 16s ease-in-out infinite forwards; }

@keyframes cloud_move_left {
  0%, 100% {
    transform: translateX(-10%); }
  50% {
    transform: translateX(20%); } }

@keyframes cloud_move_right {
  0%, 100% {
    transform: rotate(180deg) translateX(-10%); }
  50% {
    transform: rotate(170deg) translateX(20%); } }

#map {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100%; }

.marker {
  background-size: 100%;
  background-repeat: no-repeat;
  top: calc(-39px + 18px);
  width: 56px;
  height: 75px;
  transition: top 0.2s;
  transition: width 0.2s;
  transition: height 0.2s;
  transform-style: preserve-3d; }
  .marker div {
    width: 100%;
    height: 100%; }

.mapboxgl-canvas-container {
  transform-style: preserve-3d; }
  .mapboxgl-canvas-container .mapboxgl-marker {
    cursor: pointer; }
    .mapboxgl-canvas-container .mapboxgl-marker .inn-wrap {
      transition: .1s ease-out; }
      .mapboxgl-canvas-container .mapboxgl-marker .inn-wrap .icon {
        transform-origin: center bottom;
        transition: .1s ease-out; }
    .mapboxgl-canvas-container .mapboxgl-marker:hover .inn-wrap .icon {
      transform: scale(1.2) !important; }

.switch-route-bar {
  position: fixed;
  bottom: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  width: 400px;
  justify-content: space-between; }
  .switch-route-bar > div {
    cursor: pointer; }
    .switch-route-bar > div:hover {
      color: red; }

.switch-map-style-type {
  position: fixed;
  left: 4px;
  top: 4px;
  display: flex;
  background-color: #fff;
  border-radius: 11px;
  height: 20px;
  font-size: 14px;
  padding: 2px; }
  .switch-map-style-type > div {
    cursor: pointer;
    width: 80px;
    text-align: center;
    border-radius: 10px;
    margin: 0 2px 0 0;
    transition: 0.3s ease; }
    .switch-map-style-type > div:last-child {
      margin: 0; }
    .switch-map-style-type > div:hover {
      color: #fff;
      background-color: #888; }
    .switch-map-style-type > div.active {
      color: #fff;
      background-color: #333; }

a.mapboxgl-ctrl-logo {
  width: 42px;
  height: 10px;
  background-size: cover; }

.rounded-rect {
  background: white;
  box-shadow: 0 0 50px -25px black; }

.flex-center {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center; }

.flex-center.right {
  right: 0px; }

.sidebar-content {
  position: absolute;
  width: 100%;
  height: 100%;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 32px;
  color: gray; }

.sidebar-toggle.right {
  left: -1.5em; }

.sidebar-toggle:hover {
  color: #0aa1cf;
  cursor: pointer; }

.sidebar {
  transition: transform 1s;
  z-index: 1;
  width: 500px;
  height: 100%; }

.right.collapsed {
  transform: translateX(500px); }

.hillshape-wrapper {
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  pointer-events: none; }
  .hillshape-wrapper .point-wrapper {
    position: relative;
    width: 1px; }
    .hillshape-wrapper .point-wrapper .point {
      position: absolute;
      left: 0;
      width: 1px;
      height: 1px;
      border-radius: 50%;
      background-color: red; }

#hill_shape {
  pointer-events: none; }
  #hill_shape > * {
    pointer-events: none; }

.switch_group .switch_item_b {
  background-color: black;
  color: white; }

.switch_group.active .switch_item_a {
  background-color: black;
  color: white; }

.switch_group.active .switch_item_b {
  background-color: white;
  color: black; }

.or_test {
  z-index: 10000;
  width: var(--test_orientation_base);
  height: var(--test_orientation_base);
  background: #000; }

.keyvisual {
  top: 50%;
  left: 50%;
  min-width: 100%;
  min-height: 100%;
  width: 100%;
  height: calc(100vw * 0.5625);
  z-index: -100;
  transform: translate(-50%, -50%); }
  .keyvisual.footer {
    top: 0;
    left: 0;
    transform: translate(0%, 0%); }
  .keyvisual .shape_box {
    position: absolute;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 100%; }
    .keyvisual .shape_box .shape {
      position: relative;
      width: 100%;
      height: 100%;
      background-position: center bottom;
      background-repeat: no-repeat;
      background-size: contain;
      transform: translateY(100px); }
  .keyvisual *[class*=sub_] .shape {
    opacity: 0;
    transform: translateY(200px); }
  .keyvisual *[class*=nav_] .shape {
    opacity: 0;
    transform: translateY(50px); }
  .keyvisual *[class*=nav_]:hover {
    transform: scale(1.2) !important; }
  .keyvisual .sub_a_0 .shape {
    background-image: url("../src/images/keyvisual2/sub_a_0.svg"); }
  .keyvisual .sub_a_1 .shape {
    background-image: url("../src/images/keyvisual2/sub_a_1.svg"); }
  .keyvisual .sub_a_2 .shape {
    background-image: url("../src/images/keyvisual2/sub_a_2.png"); }
  .keyvisual .sub_a_3 .shape {
    background-image: url("../src/images/keyvisual2/sub_a_3.svg"); }
  .keyvisual .sub_a_4 .shape {
    background-image: url("../src/images/keyvisual2/sub_a_4.svg"); }
  .keyvisual .sub_b_1 .shape {
    background-image: url("../src/images/keyvisual2/sub_b_1.svg"); }
  .keyvisual .sub_b_2 .shape {
    background-image: url("../src/images/keyvisual2/sub_b_2.png"); }
  .keyvisual .sub_b_3 .shape {
    background-image: url("../src/images/keyvisual2/sub_b_3.svg"); }
  .keyvisual .main_a .shape {
    background-image: url("../src/images/keyvisual2/main_a.svg"); }
  .keyvisual .main_b .shape {
    background-image: url("../src/images/keyvisual2/main_b.svg"); }
  .keyvisual .main_c .shape {
    background-image: url("../src/images/keyvisual2/main_c.svg"); }
  .keyvisual .nav_about .shape {
    background-image: url("../src/images/keyvisual2/icon_home_a.svg"); }
  .keyvisual .nav_map .shape {
    background-image: url("../src/images/keyvisual2/icon_home_b.svg"); }
  .keyvisual .nav_course .shape {
    background-image: url("../src/images/keyvisual2/icon_home_c.svg"); }
  .keyvisual .sub_a_3 {
    left: -50%;
    bottom: 16%;
    width: 200%; }
    @media screen and (orientation: landscape) {
      .keyvisual .sub_a_3 {
        left: 0%;
        bottom: 0%;
        width: 100%; } }
  .keyvisual .sub_a_4 {
    display: var(--orientation_display); }
  .keyvisual .sub_b_3 {
    left: -50%;
    bottom: 19%;
    width: 200%; }
    @media screen and (orientation: landscape) {
      .keyvisual .sub_b_3 {
        left: 0%;
        bottom: 0%;
        width: 100%; } }
  .keyvisual .main_c {
    left: -50%;
    width: 200%;
    bottom: 15%; }
    .keyvisual .main_c .shape {
      background-position: bottom; }
    @media screen and (orientation: landscape) {
      .keyvisual .main_c {
        left: 0%;
        bottom: 0%;
        width: 100%; } }
  .keyvisual .sub_a_2 {
    display: var(--orientation_display); }
  .keyvisual .sub_b_2 {
    width: 200%;
    left: -30%;
    bottom: 19%; }
    @media screen and (orientation: landscape) {
      .keyvisual .sub_b_2 {
        width: 100%;
        left: 0;
        bottom: 0; } }
  .keyvisual .main_b {
    left: 0;
    bottom: 10%;
    width: 200%; }
    .keyvisual .main_b .shape {
      left: -5vw;
      background-position: left bottom; }
    @media screen and (orientation: landscape) {
      .keyvisual .main_b {
        bottom: 0;
        width: 100%; } }
  .keyvisual .main_a {
    right: 0;
    width: 200%; }
    .keyvisual .main_a .shape {
      background-position: right bottom; }
    @media screen and (orientation: landscape) {
      .keyvisual .main_a {
        width: 100%; } }
  .keyvisual .sub_b_1 {
    display: var(--orientation_display); }
    .keyvisual .sub_b_1 .shape {
      background-position: right bottom; }
  .keyvisual .sub_a_1 {
    display: var(--orientation_display); }
    .keyvisual .sub_a_1 .shape {
      background-position: right bottom; }
  .keyvisual .sub_a_0 {
    bottom: -5%;
    width: 300%; }
    .keyvisual .sub_a_0 .shape {
      background-position: left bottom; }
    @media screen and (orientation: landscape) {
      .keyvisual .sub_a_0 {
        bottom: 0;
        width: 100%; } }
  .keyvisual .nav_about {
    left: 0;
    right: 0;
    bottom: 115vw;
    width: calc(200px + var(--orientation_base));
    height: calc(40px + var(--orientation_base)); }
    @media screen and (orientation: landscape) {
      .keyvisual .nav_about {
        left: -53vw;
        bottom: 30vh;
        height: calc(60px + var(--orientation_base)); } }
    @media screen and (orientation: landscape) and (min-width: 1440px) {
      .keyvisual .nav_about .shape {
        animation: scale 2s 2s infinite forwards; } }
  .keyvisual .nav_map {
    left: 0;
    right: 0;
    bottom: 80vw;
    width: calc(200px + var(--orientation_base));
    height: calc(40px + var(--orientation_base)); }
    @media screen and (orientation: landscape) {
      .keyvisual .nav_map {
        right: 10vw;
        bottom: 60vh;
        height: calc(60px + var(--orientation_base)); } }
    @media screen and (orientation: landscape) and (min-width: 1440px) {
      .keyvisual .nav_map .shape {
        animation: scale 2s 2.1s infinite forwards; } }
  .keyvisual .nav_course {
    left: 0;
    right: 0;
    bottom: 45vw;
    width: calc(200px + var(--orientation_base));
    height: calc(40px + var(--orientation_base)); }
    @media screen and (orientation: landscape) {
      .keyvisual .nav_course {
        right: -50vw;
        bottom: 43vh;
        height: calc(60px + var(--orientation_base)); } }
    @media screen and (orientation: landscape) and (min-width: 1440px) {
      .keyvisual .nav_course .shape {
        animation: scale 2s 2.2s infinite forwards; } }

@keyframes scale {
  from, to {
    transform: scale(1) translateY(5%); }
  50% {
    transform: scale(1.02) translateY(-10%); } }

.page_nav_box {
  transform: translateY(300%) scale(0.9); }
  .page_nav_box.active {
    transform: translateY(0) scale(1); }
  .page_nav_box .page_nav_list {
    box-shadow: inset 0 0 0 calc(var(--base) * .25) var(--clr_ter); }

.item_active_hint {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center bottom; }
  .item_active_hint.active {
    opacity: 1;
    transform: scaleY(1); }
  .item_active_hint.color_pri svg path {
    fill: var(--clr_pri); }
  .item_active_hint.color_sec svg path {
    fill: var(--clr_sec); }
  .item_active_hint.color_ter svg path {
    fill: var(--clr_ter); }

.notification_item {
  opacity: 0;
  pointer-events: none;
  transform: translateY(50%); }
  .notification_item.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0%); }
    .notification_item.active:hover {
      opacity: .7; }

.nav_hint_item {
  opacity: 0;
  pointer-events: none;
  transform: translateY(50%) scale(0.9); }
  .nav_hint_item.active {
    opacity: 1;
    pointer-events: all;
    transform: translateY(0%) scale(1); }

.video_row .video-js .vjs-big-play-button {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 3rem;
  height: 3rem;
  border-radius: 50%;
  border: 2px solid white; }

.page_title {
  letter-spacing: 2px;
  transform: translateY(0) skewX(-10.5deg); }

/*! Flickity v2.2.2
https://flickity.metafizzy.co
---------------------------------------------- */
.flickity-enabled {
  position: relative; }

.flickity-enabled:focus {
  outline: none; }

.flickity-viewport {
  overflow: hidden;
  position: relative;
  height: 100%; }

.flickity-slider {
  position: absolute;
  width: 100%;
  height: 100%; }

/* draggable */
.flickity-enabled.is-draggable {
  -webkit-tap-highlight-color: transparent;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none; }

.flickity-enabled.is-draggable .flickity-viewport {
  cursor: move;
  cursor: -webkit-grab;
  cursor: grab; }

.flickity-enabled.is-draggable .flickity-viewport.is-pointer-down {
  cursor: -webkit-grabbing;
  cursor: grabbing; }

/* ---- flickity-button ---- */
.flickity-button {
  position: absolute;
  background: rgba(255, 255, 255, 0.75);
  border: none;
  color: #333; }

.flickity-button:hover {
  background: white;
  cursor: pointer; }

.flickity-button:focus {
  outline: none;
  box-shadow: 0 0 0 5px #19F; }

.flickity-button:active {
  opacity: 0.6; }

.flickity-button:disabled {
  opacity: 0.3;
  cursor: auto;
  /* prevent disabled button from capturing pointer up event. #716 */
  pointer-events: none; }

.flickity-button-icon {
  fill: currentColor; }

/* ---- previous/next buttons ---- */
.flickity-prev-next-button {
  top: 50%;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  /* vertically center */
  transform: translateY(-50%); }

.flickity-prev-next-button.previous {
  left: 10px; }

.flickity-prev-next-button.next {
  right: 10px; }

/* right to left */
.flickity-rtl .flickity-prev-next-button.previous {
  left: auto;
  right: 10px; }

.flickity-rtl .flickity-prev-next-button.next {
  right: auto;
  left: 10px; }

.flickity-prev-next-button .flickity-button-icon {
  position: absolute;
  left: 20%;
  top: 20%;
  width: 60%;
  height: 60%; }

/* ---- page dots ---- */
.flickity-page-dots {
  position: absolute;
  width: 100%;
  bottom: -25px;
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
  line-height: 1; }

.flickity-rtl .flickity-page-dots {
  direction: rtl; }

.flickity-page-dots .dot {
  display: inline-block;
  width: 10px;
  height: 10px;
  margin: 0 8px;
  background: #333;
  border-radius: 50%;
  opacity: 0.25;
  cursor: pointer; }

.flickity-page-dots .dot.is-selected {
  opacity: 1; }
